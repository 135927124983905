/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
// TODO:  revisar para sacar las lineas de eslint
import _ from 'lodash';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useSettings from 'modules/core/contexts/SettingsContext/useSettings';
import useSnakbar from 'modules/core/contexts/SnakbarContext/useSnakbar';
import { useCallback, useMemo, useState } from 'react';
import useFetchHttp from 'use-http';
import useNavigate from '../useNavigate';
import useSession from '../useSession';
// import useSession from './useSession'
// import useSnackbar from './useSnackbar'

// TODO: revisar este componente que se ve que faltan cosas tras tratar de adaptarlo
/**
 * Hook para llamados al server
 * @param {*} param0
 * @returns
 */
function useFetch({
  host = process.env.REACT_APP_API_URI,
  useToken = true,
  processResponse = true,
  options,
  config: _config = {}
}) {
  // const {error:errorNotification,success:successNotification} = useSnackbar();

  const { logout, session } = useSession();
  const { translate } = useDecorator();
  const { success, error: errorSnackbar } = useSnakbar();
  // const session = useSelector(store => _.get(store, 'session'))
  // const [data, setData] = useState(null)
  const optionsConfig = useMemo(() => {
    const token = session?.token;
    // token ='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2YWx1ZSI6IntcInV1aWRcIjpcIjk3OGIxNWMwLWJjNmItMTFlYy1iZjQ4LTUzMjk1NWUxZjBkY1wifSIsImlhdCI6MTY0OTk5MzA4MywiZXhwIjoxNjUwMDM2MjgzLCJpc3MiOiJtb3pvYXBwLmNvbSIsInN1YiI6InVzZXJJbmZvIn0.QyTzgYj4p0ZFaXqdCeT9p2JstOe7VgCdXI5jy9MVFvE';
    const defaultOptions = {
      cachePolicy: 'no-cache',
      interceptors: {
        request: ({ options }) => {
          if (useToken) {
            if (!token) {
              abort();
              // errorNotification('Redireccionar por no tener token y necesitarlo')
              return options;
            }
            options.headers = {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            };
          } else {
            options.headers = {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            };
          }
          options.responseHeader = 'application/json';

          return options;
        }
      }
    };
    return { ...defaultOptions, ...options };
    // }, [JSON.stringify(options), JSON.stringify(session)])
  }, [JSON.stringify(options)]);

  const {
    get: getHttp,
    post: postHttp,
    put: putHttp,
    delete: deleteHttp,
    response,
    // data,
    abort,
    loading,
    error
  } = useFetchHttp(host, {
    ...optionsConfig
  });
  const [data, setData] = useState(null);
  const [errorCatch, setErrorCatch] = useState(null);
  const { requestGetDataFunction, requestGetErrorFunction } = useSettings();
  const { go } = useNavigate();
  const successNotification = useCallback(
    (config) => {
      if (_.get(config, 'showSuccessMessage')) {
        success(_.get(config, 'successMessage'));
      }
    },
    [success]
  );
  const errorNotification = useCallback(
    (config, message) => {
      if (_.get(config, 'showErrorMessage')) {
        errorSnackbar(message || _.get(config, 'errorMessage'));
      }
    },
    [errorSnackbar]
  );
  /**
   * Procesa el error y si hay devuelve el error, sino null
   * @param {*} result
   * @returns
   */
  const proccessError = useCallback(
    (result, config) => {
      let error = null;
      if (requestGetErrorFunction) {
        error = requestGetErrorFunction(result, config);
      } else if (result.error) {
        error = result.error;
      }
      if (error) {
        setErrorCatch(error);
        errorNotification(config, error);
      }
      return error;
    },
    [requestGetErrorFunction]
  );
  /**
   * Procesa los datos succcess y si esta todo ok devuelve el resultado, sino null
   * @param {*} result
   * @returns
   */
  const proccessSuccess = useCallback(
    (result, config) => {
      let data = null;
      if (requestGetDataFunction) {
        data = requestGetDataFunction(result, config);
      } else if (result.data) {
        data = result.data;
      }
      if (data) {
        setData(data);
        successNotification(config);
      }
      return data;
    },
    [requestGetDataFunction]
  );
  /**
   * Procesa el response
   * @param {*} result
   * @param {*} config
   * @returns
   */
  const returnProcessResponse = useCallback(
    (result, localConfig = {}) => {
      const config = { ..._config, ...localConfig };
      if (response.ok) {
        if (processResponse) {
          const error = proccessError(result, config);
          if (error) return error;
          const data = proccessSuccess(result, config);
          if (data) return data;
          // Este caso no deberia de pasar, signigica que no cumple con el patron
          // TODO: Ver como unificar y dar criterio a esto
          errorSnackbar('No cumple con el patro de response');
          throw new Error('No cumple con el patro de response');
        }
        setData(result);
        return result;
      }
      /**
       * funcion para manejar el error
       * @param {*} result
       * @param {*} config
       */
      const managerError = (result, config) => {
        const error = proccessError(result, config);
        if (!error) {
          // TODO: Ver como unificar y dar criterio a esto
          errorSnackbar('Error en la petición');
          throw new Error('Error en la petición');
        }
        throw error;
      };
      switch (response.status) {
        case 401:
        case 403:
          logout();
          if (config.redirect !== false) {
            go('/');
            break;
          }
          return managerError(result, config);

        default: {
          return managerError(result, config);
        }
      }
      return null;
    },
    [proccessError, proccessSuccess]
  );
  /**
   * TODO: COMENTAR
   */
  const post = async (path, body, config = {}) => {
    const result = await postHttp(path, body);
    const defaultConfig = {
      showSuccessMessage: false,
      successMessage: translate(
        'CORE.notification_message_default_success_post'
      ),
      showErrorMessage: true,
      errorMessage: translate('CORE.notification_message_default_error_post')
    };

    return returnProcessResponse(result, { ...defaultConfig, ...config });
  };
  /**
   * TODO: COMENTAR
   */
  const get = async (path) => {
    const result = await getHttp(path);
    return returnProcessResponse(result);
  };
  /**
   * TODO: COMENTAR
   */
  const put = async (path, body) => {
    const result = await putHttp(path, body);
    return returnProcessResponse(result);
  };
  /**
   * TODO: COMENTAR
   */
  const del = async (path) => {
    const result = await deleteHttp(path);
    return returnProcessResponse(result);
  };

  return { get, post, put, del, data, error: error || errorCatch, loading };
}
export default useFetch;
