/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from 'prop-types';
import useSecurity from 'modules/core/hooks/useSecurity';
import React, { useMemo } from 'react';

/**
 * Componente para mostrar el contenido si tiene acceso al mismo
 * @param {*} param0
 * @returns
 */
function Access({ children, access }) {
  const { hasAccess } = useSecurity();
  const showByAccess = useMemo(() => {
    if (!access) return true;
    return hasAccess(access);
  }, [hasAccess, access]);
  return <>{showByAccess && children}</>;
}

Access.propTypes = {
  access: PropTypes.any,
  children: PropTypes.any
};
export default Access;
