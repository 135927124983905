import PropTypes from 'prop-types';
import React, {
  useMemo,
  useRef,
  useImperativeHandle,
  useCallback,
  useState
} from 'react';
import ModalTemplate from 'modules/core/components/Templates/ModalTemplate';
import Modal from '../Modal';
import AlertTitle from '../../Alerts/AlertTitle';

let resolve = null;
// import classesModule from './Alert.module.scss';
/**
 * menu de alerta utilizando el modal
 * @param {*} param0
 * @returns
 */
function Alert({
  onClose,
  innerRef,
  fullWidth = true,
  children,
  maxWidth = 'xs',
  templateProps,
  template: Template = ModalTemplate,
  variant: _variant = null,
  title: _title = 'title',
  btnText: _btnText = 'cerrar',
  ...props
}) {
  /** Contenido a mostrarse dentro del alert */
  const [content, setContent] = useState(children);
  /** Variante del tipo de componente a mostrar */
  const [variant, setVariant] = useState(_variant);
  const [title, setTitle] = useState(_title);
  const [btnText, setBtnText] = useState(_btnText);
  /** Referencia al componente DOM del modal */
  const modalRef = useRef();

  /**   Funcion que se usa para abrir el modal, pero retorna una promesa */
  const handleOpen = useCallback(
    (message, _config = {}) =>
      new Promise((_resolve) => {
        setContent(message);
        setVariant(_config?.variant);
        if (_config) {
          if (_config.title) {
            setTitle(_config.title);
          } else {
            setTitle(_title);
          }
          if (_config.btnText) {
            setBtnText(_config.btnText);
          } else {
            setBtnText(_btnText);
          }
        }
        modalRef.current.open();
        resolve = _resolve;
      }),
    [modalRef]
  );
  /**  Funcion que se ejecuta en el boton de cerrar, o se publica para cerrar el modal */
  const handleCloseButton = useCallback(() => {
    modalRef.current.close();
  }, [modalRef]);
  /** Funcion que se ejecuta despues de cerrar el modal, para devolver la promesa con un valor  */
  const handleClose = useCallback(() => {
    resolve(1);
    if (onClose) {
      onClose();
    }
  }, []);

  /** Configuraicon de botones que se van a usar en el Modal */
  const actionbuttons = useMemo(
    () => [
      {
        onClick: handleCloseButton,
        children: btnText
      }
    ],
    [btnText]
  );
  /** Estados y eventos que se publican */
  useImperativeHandle(
    innerRef,
    () => ({
      isOpen: modalRef.current.open,
      open: handleOpen,
      close: handleCloseButton
    }),
    [handleOpen, handleCloseButton]
  );

  /**  Se genera un nuevo objeto depende de la configarion para motrar dentro del modal */
  const childrenObj = useMemo(() => {
    if (variant) {
      return <AlertTitle severity={variant}>{content}</AlertTitle>;
    }
    return content;
  }, [variant, content]);

  return (
    <Modal
      innerRef={modalRef}
      templateProps={{ ...templateProps, actionbuttons, title }}
      template={Template}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={handleClose}
      {...props}
    >
      {childrenObj}
    </Modal>
  );
}

Alert.propTypes = {
  btnText: PropTypes.string,
  buttons: PropTypes.object,
  children: PropTypes.node,
  defaultConfig: PropTypes.object,
  fullWidth: PropTypes.bool,
  innerRef: PropTypes.any,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'md']),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  template: PropTypes.element,
  templateProps: PropTypes.any,
  title: PropTypes.string,
  variant: PropTypes.string
};
export default Alert;
