/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import Icon from 'modules/core/components/Icons/Icon';
import classes from './ButtonIcon.module.scss';
import Tooltip from '../../Helpers/Tooltip';
/**
 * componente de boton de tipo icono
 * @param {*} param0
 * @returns
 */
function ButtonIcon({
  icon,
  size = 'large',
  color = 'primary',
  variant = 'contained',
  tooltip,
  onClick,
  disabled = false,
  type = 'button',
  loading,
  ...props
}) {
  return (
    <Tooltip title={tooltip}>
      <IconButton
        {...props}
        color={color}
        size={size}
        className={`${classes[variant]} ${classes[color]}`}
        onClick={onClick}
        disabled={disabled}
        type={type}
      >
        <Icon icon={icon} />
      </IconButton>
    </Tooltip>
  );
}

ButtonIcon.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'default']),
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  loading: PropTypes.any,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  tooltip: PropTypes.string,
  type: PropTypes.any,
  variant: PropTypes.oneOf([
    'contained',
    'outlined',
    'text',
    'containedSecundary'
  ])
};

ButtonIcon.defaultProps = {
  size: 'large',
  variant: process.env.REACT_APP_STYLED_DEFAULT_BUTTON_VARIANT || 'contained',
  color: 'primary',
  tooltip: null,
  onClick: null,
  disabled: false
};
export default ButtonIcon;
