import Wizard from 'modules/core/components/Wizard';
import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { assign } from 'xstate';
import LoadingStep from 'modules/core/components/Wizard/components/LoadingStep';
import useNavigate from 'modules/core/hooks/useNavigate';
import machine from './AuthScoringWizardMachine';
import ResultScoringStep from './Steps/ResultScoringStep';
import SelectPerson from './Steps/SelectPersonStep/SelectPersonStep';
import useGetScoringPersona from '../../services/useScoringUserServices/useGetScoringPersona';
import ErrorDniMessageStep from './Steps/ErrorDniMessageStep';
/**
 * Wizard para ver o borrar scoring de usuarios
 * @returns
 */
function AuthScoringWizard() {
  const { getScoringPersona } = useGetScoringPersona();
  const { go } = useNavigate();
  const steps = useMemo(
    () => ({
      select: {
        render: SelectPerson,
        stepperPosition: 0
      },
      getScoringPersona: {
        render: LoadingStep,
        stepperPosition: 0
      },
      ShowErrorDni: {
        render: ErrorDniMessageStep,
        stepperPosition: 2
      },
      result: {
        render: ResultScoringStep,
        stepperPosition: 1
      }
    }),
    []
  );
  const guards = {
    isDNIError: (context) => {
      try {
        const dni = parseInt(_.get(context, 'documento', 0), 10);
        return dni === 0;
      } catch (e) {
        return false;
      }
    }
  };
  const services = {
    getScoringPersona: async (context, event) => {
      const values = _.get(event, 'payload.values');
      return await getScoringPersona({ ...values });
    }
  };
  const actions = {
    setContextAffterStep: assign((context, event) => {
      const values = _.get(event, 'payload.values');
      return { ...context, ...values };
    })
  };
  const handleStopMachine = useCallback(() => {
    go('/');
  }, [go]);
  return (
    <Wizard
      guards={guards}
      machine={machine}
      steps={steps}
      services={services}
      actions={actions}
      onStopMachine={handleStopMachine}
    />
  );
}

export default AuthScoringWizard;
