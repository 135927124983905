import React, { useCallback } from 'react';
import { useSnackbar as useSnakbarNotiStack } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SnakbarContext from '.';
/**
 * Hook para el manejo de snackbars
 * @returns
 */
function useSnakbar() {
  const { setMaxSnack, setAnchorOrigin } = React.useContext(SnakbarContext);
  const { enqueueSnackbar, closeSnackbar } = useSnakbarNotiStack();
  /**
   * Close action
   */
  const action = useCallback(
    (snackbarKey) => (
      <IconButton
        component="span"
        onClick={() => closeSnackbar(snackbarKey)}
        sx={{
          color: '#FFF'
        }}
      >
        <CloseIcon />
      </IconButton>
    ),
    [closeSnackbar]
  );
  const defaultAutoHideDuration = 10000;
  const success = useCallback(
    (message, config) => {
      const defaultConfig = {
        action,
        variant: 'success',
        preventDuplicate: true,
        autoHideDuration: defaultAutoHideDuration
      };
      enqueueSnackbar(message, { ...defaultConfig, ...config });
    },
    [enqueueSnackbar]
  );
  const warning = useCallback(
    (message, config) => {
      const defaultConfig = {
        action,
        variant: 'warning',
        preventDuplicate: true,
        autoHideDuration: defaultAutoHideDuration
      };
      enqueueSnackbar(message, { ...defaultConfig, ...config });
    },
    [enqueueSnackbar]
  );
  const info = useCallback(
    (message, config) => {
      const defaultConfig = {
        action,
        variant: 'info',
        preventDuplicate: true
      };
      enqueueSnackbar(message, { ...defaultConfig, ...config });
    },
    [enqueueSnackbar]
  );
  const error = useCallback(
    (message, config) => {
      const defaultConfig = {
        action,
        variant: 'error',
        preventDuplicate: true,
        autoHideDuration: defaultAutoHideDuration
      };
      enqueueSnackbar(message, { ...defaultConfig, ...config });
    },
    [enqueueSnackbar]
  );
  return {
    enqueueSnackbar,
    success,
    error,
    info,
    warning,
    setMaxSnack,
    setAnchorOrigin
  };
}

export default useSnakbar;
