import useFetch from 'modules/core/hooks/useFetch/useFetch';
import { useCallback } from 'react';
import _ from 'lodash';

/**
 * Hook para llamadas al server para el modulo de public
 * @param {*} param0
 * @returns
 */
function usePublicServices() {
  const { post, loading } = useFetch({
    useToken: false,
    config: { redirect: false }
  });

  /**
   * Llado al server para hacer login
   */
  const getScoring = useCallback(
    async ({ tipoDocumento, documento, sexo, recaptchaToken }) => {
      // const result = await get(
      //   `/api/scoring?tipoDocumento=${tipoDocumento}&documento=${documento}&sexo=${sexo}&recaptchaToken=${recaptchaToken}`
      // );
      const result = await post(`/api/scoring`, {
        tipoDocumento,
        documento,
        sexo,
        recaptchaToken
      });

      const scoring = _.map(result.persona.scorings, (s) => ({
        id: _.get(s, 'id'),
        fechaSentencia: _.get(s, 'fechaSentencia'),
        provincia: _.get(s, 'juzgado.provincia'),
        localidad: _.get(s, 'juzgado.localidad'),
        juzgado: _.get(s, 'juzgado.nombre'),
        expediente: _.get(s, 'expediente'),
        motivo: _.get(s, 'motivo.nombre'),
        puntos: _.get(s, 'puntos')
      }));
      result.persona.scorings = scoring;
      return result;
    },
    [post]
  );

  return {
    getScoring,
    loading
  };
}

export default usePublicServices;
