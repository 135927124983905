import React, { useCallback } from 'react';
import { useChangePassword } from 'modules/auth/services/useAcount/index';
import useLogger from 'modules/core/contexts/LoggerContext/useLogger';
import useNavigate from 'modules/core/hooks/useNavigate';
import classesModule from './PasswordResetView.module.scss';
import ChangePasswordForm from '../../forms/ChangePasswordForm';

/**
 * Página de Login
 * @param {*} param0
 * @returns
 */
function ChangePasswordView() {
  const { changePassword } = useChangePassword();
  const { go } = useNavigate();
  const { error: errorLogger } = useLogger();
  const handleSubmit = useCallback(
    async (values) => {
      try {
        await changePassword({
          oldPassword: values.oldPassword,
          newPassword: values.newPassword
        });
        go('/');
      } catch (e) {
        errorLogger(e);
      }
    },
    [changePassword, errorLogger, go]
  );
  return (
    <div className={`${classesModule.main}`}>
      <ChangePasswordForm onSubmit={handleSubmit} />
    </div>
  );
}
export default ChangePasswordView;
