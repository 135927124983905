import { useGetMyUser } from 'modules/auth/services/useAcount/index';
import React, { useCallback, useEffect, useState } from 'react';
// import classesModule from './MyUserView.module.scss';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import _ from 'lodash';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import Divider from '@mui/material/Divider';
import { Stack } from '@mui/material';
import Typography from '../../../core/components/Typographies/Typography';

/**
 *
 * vista de los datos del usuario
 * @returns
 */
function MyUserView() {
  const { translate } = useDecorator();
  const { getMyUsuario } = useGetMyUser();
  const [user, setUser] = useState(null);
  const getServerData = useCallback(async () => {
    const userServer = await getMyUsuario();
    setUser(userServer);
  }, [getMyUsuario]);
  useEffect(() => {
    if (getServerData) getServerData();
  }, [getServerData]);
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Stack direction="column">
            <Typography variant="subtitle1">
              {translate('AUTH.MY_USUARIO_NAME_LASTNAME')}
            </Typography>

            <Typography>
              {_.get(user, 'nombre', '')} {_.get(user, 'apellido', '')}
            </Typography>
          </Stack>
        </Grid>
        <Divider
          orientation="horizontal"
          flexItem
          sx={{ width: '100%', mt: 1 }}
        />

        <Grid item xs={6}>
          <Stack direction="column">
            <Typography variant="subtitle1">
              {translate('AUTH.MY_USER_EMAIL')}
            </Typography>
            <Typography>{_.get(user, 'email', '')}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="column">
            <Typography variant="subtitle1">
              {translate('AUTH.MY_USUARIO_JUZGADO')}
            </Typography>
            <Typography>{_.get(user, 'juzgado.nombre', '')}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
export default MyUserView;
