import { FormControl } from '@mui/material';
import TextInput from 'modules/core/components/Forms/Inputs/TextInput';
import React from 'react';
import PropTypes from 'prop-types';
import FormHelperError from '../../Form/components/FormHelperError';

/**
 * Compontente que hace los TextInputMultople
 * @param {*} param0
 * @returns
 */
function TextInputMultiple({
  children,
  errorMessage,
  variant = 'outlined',
  size,
  label,
  value,
  rows = 4,
  helperText = null,
  ...props
}) {
  return (
    <FormControl sx={{ width: '100%' }} error={!!errorMessage}>
      <TextInput
        variant={variant}
        label={label}
        size={size}
        multiline
        value={value}
        rows={rows}
        {...props}
        inputProps={{
          sx: {
            p: 2
          }
        }}
      />
      <FormHelperError errorMessage={errorMessage} helperText={helperText} />
    </FormControl>
  );
}
TextInputMultiple.propTypes = {
  children: PropTypes.node, //* Variant style component
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium', 'string']),
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard'])
};

TextInputMultiple.defaultProps = {
  children: null,
  variant: process.env.REACT_APP_STYLED_DEFAULT_INPUT_VARIANT || 'outlined',
  size: process.env.REACT_APP_STYLED_DEFAULT_INPUT_SIZE || 'small',
  errorMessage: null,
  label: null,
  helperText: null
};
export default TextInputMultiple;
