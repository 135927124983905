import { Grid, Stack } from '@mui/material';
import _ from 'lodash';
import MaterialIcon, {
  TYPES
} from 'modules/core/components/Icons/MaterialIcon';
import Typography from 'modules/core/components/Typographies/Typography';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import PropTypes from 'prop-types';
import React from 'react';
import classModule from './DocumentScoring.module.scss';

/**
 * Compoennte para mostrar el scoring
 * @param {*} param0
 * @returns
 */
function DocumentScoring({ scoring }) {
  const { translate, transfomNumber } = useDecorator();
  return (
    <div className={classModule.Document}>
      <div className={classModule.Header}>
        <Typography variant="h3">
          {translate('PUBLIC.SCORING_DOCUMENT_TITLE')}
        </Typography>
      </div>
      <div className={classModule.Content}>
        <Grid container>
          <Grid item xs={4} className={classModule.Photo}>
            <MaterialIcon icon="person" size="500%" type={TYPES.FILLED} />
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack>
                  <Typography variant="subtitle1">
                    {translate('PUBLIC.SCORING_DOCUMENT_NAME_AND_LASTNAME')}
                  </Typography>
                  <Typography variant="h1" className={classModule.Name}>
                    {_.get(scoring, 'nombre')} {_.get(scoring, 'apellido')}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={8}>
                <Stack>
                  <Typography variant="subtitle2">
                    {translate('PUBLIC.SCORING_DOCUMENT_TYPE_DOCUMENT')}
                  </Typography>
                  <Typography>
                    {translate(
                      `SISCO.TYPE_DOCUMENT_LABEL_${_.get(
                        scoring,
                        'tipoDocumento'
                      )}`
                    )}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack>
                  <Typography variant="subtitle2">
                    {translate('PUBLIC.SCORING_DOCUMENT_DOCUMENT_NUMBER')}
                  </Typography>
                  <Typography>
                    {transfomNumber(_.get(scoring, 'documento'))}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack>
                  <Typography variant="subtitle2">
                    {translate('PUBLIC.SCORING_DOCUMENT_POINTS')}
                  </Typography>
                  <Typography>
                    {_.get(
                      scoring,
                      'puntos',
                      process.env.REACT_APP_DEFAULT_SCORING
                    )}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

DocumentScoring.propTypes = {
  scoring: PropTypes.any
};
export default DocumentScoring;
