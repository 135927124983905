import useNavigate from 'modules/core/hooks/useNavigate';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
/**
 * Componente To redirect pages
 */
function Redirect({ path, to = '/' }) {
  const location = useLocation();
  const { go, generateUrl, params } = useNavigate();
  useEffect(() => {
    if (generateUrl) {
      if (location.pathname === generateUrl(path, params)) {
        if (to) go(to);
      }
    }
  }, [to, go, location, path, generateUrl, params]);
  return null;
}

Redirect.propTypes = {
  to: PropTypes.string,
  path: PropTypes.string
};

export default Redirect;
