import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classesModule from './ErrorView.module.scss';

/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function ErrorView() {
  const [leftTime, setLeftTime] = useState(
    _.get(window, 'env.loggerConfig.timeToRedirectFromErrorToHomeInSeconds', 0)
  );
  useEffect(() => {
    if (
      _.get(
        window,
        'env.loggerConfig.timeToRedirectFromErrorToHomeInSeconds',
        false
      )
    ) {
      setTimeout(() => {
        window.location = '/';
      }, _.get(window, 'env.loggerConfig.timeToRedirectFromErrorToHomeInSeconds') * 1000);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (leftTime > 0) {
        setLeftTime(leftTime - 1);
      }
    }, 1000);
  }, [leftTime]);
  return (
    <div className={`${classesModule.ErrorView}`}>
      {/* <img src="" /> */}
      <div className={classesModule.Title}>Error 2</div>
      <div className={classesModule.SubTitle}>Error 3</div>
    </div>
  );
}

export default ErrorView;
