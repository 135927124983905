const Alert = {
  sx: {
    mt: 2
  }
};
const Buttons = {
  sx: {
    mr: 2
  }
};
const Botonera = {
  sx: {
    mt: 2
  }
};
export default {
  Buttons,
  Alert,
  Botonera
};
