import PropTypes from 'prop-types';
import React from 'react';
import ScrollbarBox from 'modules/core/components/Scrollbars/ScrollbarBox';
import Meta from 'modules/core/components/Meta';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import Title from 'modules/core/components/Typographies/Title';
import Typography from 'modules/core/components/Typographies/Typography';
import classesModule from './PublicLayout.module.scss';
import Header from './components/Header';
import Footer from './components/Footer';
/**
 * Layout para las paginas publicas
 * @param {*} param0
 * @returns
 */
function PublicLayout({ children, title, introText, header = {} }) {
  const { translate } = useDecorator();
  return (
    <div className={classesModule.PublicLayout}>
      <Meta {...header} />
      <Header />
      <ScrollbarBox className={classesModule.ScrollbarBox}>
        <div className={classesModule.container}>
          {title && <Title>{translate(title)}</Title>}
          {introText && <Typography>{translate(introText)}</Typography>}
          {children}
        </div>
        <Footer />
      </ScrollbarBox>
    </div>
  );
}

PublicLayout.propTypes = {
  children: PropTypes.any,
  header: PropTypes.object,
  introText: PropTypes.any,
  title: PropTypes.any
};
export default PublicLayout;
