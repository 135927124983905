/* eslint-disable react/jsx-no-constructed-context-values */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SettingsContext from '.';

/**
 * Provider donde se guardara cierca configuraciones del proyecto
 * @param {*} param0
 * @returns
 */
function SettingsProvider({
  children,
  paths: _paths,
  requestGetErrorFunction,
  requestGetDataFunction,
  hasAccess,
  iconsPrefix: _iconsPrefix
}) {
  const [paths] = useState(_paths);
  const [iconsPrefix] = useState(_iconsPrefix);
  return (
    <SettingsContext.Provider
      value={{
        paths,
        requestGetDataFunction,
        requestGetErrorFunction,
        hasAccess,
        iconsPrefix
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.any,
  paths: PropTypes.any,
  requestGetDataFunction: PropTypes.func,
  requestGetErrorFunction: PropTypes.func,
  hasAccess: PropTypes.func,
  iconsPrefix: PropTypes.any
};

export default SettingsProvider;
