import PropTypes from 'prop-types';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '../../Helpers/Tooltip';
import Avatar from '../../Avatar';
// import classesModule from './ButtonAvatar.module.scss';
/** Componente boton con avatar */
function ButtonAvatar({
  title,
  name,
  avatar,
  color,
  variant = 'small',
  onClick,
  ...props
}) {
  return (
    <Tooltip title={title}>
      <IconButton onClick={onClick} {...props}>
        <Avatar name={name} variant={variant} avatar={avatar} color={color} />
      </IconButton>
    </Tooltip>
  );
}

ButtonAvatar.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  name: PropTypes.string,
  avatar: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['small', 'normal', 'xxl', 'xxxl'])
};
export default ButtonAvatar;
