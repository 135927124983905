import { gql } from '@apollo/client';

const getScoringPersona = gql`
  query getScoringPersona(
    $id: Int
    $tipoDocumento: TipDocumento
    $documento: String
    $sexo: Sexo
  ) {
    getScoringPersona(
      id: $id
      tipoDocumento: $tipoDocumento
      documento: $documento
      sexo: $sexo
    ) {
      puntos
      estado
      persona {
        id
        nombre
        apellido
        documento
        tipoDocumento
        puntos
        scorings {
          id
          juzgado {
            id
            nombre
            provincia
            localidad
          }
          motivo {
            id
            nombre
          }
          fechaInfraccion
          fechaSentencia
          expediente
          puntos
        }
      }
    }
  }
`;
export default {
  GET_SCORING_TO_TABLE: getScoringPersona
};
