import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider as ProvicerRedux } from 'react-redux';
import store from 'config/store';
import { LicenseInfo } from '@mui/x-license-pro';
import App from './App';
import reportWebVitals from './reportWebVitals';

LicenseInfo.setLicenseKey(
  'cdf164dc503f7b279203f2574e30a19eT1JERVI6NDMwMzAsRVhQSVJZPTE2ODMzOTY2OTMwMDAsS0VZVkVSU0lPTj0x'
);

ReactDOM.render(
  <React.StrictMode>
    <ProvicerRedux store={store}>
      <App />
    </ProvicerRedux>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
