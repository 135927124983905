import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
/** Definicion de acciones */
export const ACTIONS = {
  SET_LOADING_PAGE: 'SET_LOADING_PAGE',
  SET_TOKEN: 'SESSION_SET_TOKEN',
  SET_ME_DATA: 'SET_ME_DATA',
  LOGOUT: 'LOGOUT',
  SET_ACCESS: 'SET_ACCESS'
};
/** Definicion de fucniones de accion */

/** Funcion para seteo del loading de pagina */
const setLoadingAction = (payload) => ({
  type: ACTIONS.SET_LOADING_PAGE,
  payload
});
/** Funcion para seteo del token */
const setTokenAction = (payload) => ({
  type: ACTIONS.SET_TOKEN,
  payload
});
/** Funcion para seteo de data del usuario */
const setMeDataAction = (payload) => ({ type: ACTIONS.SET_ME_DATA, payload });
/** Funcion para borrar los datos de la session */
const logoutAction = (payload) => ({ type: ACTIONS.LOGOUT, payload });
/** Funcion para seteo de accessos del usuario */
const setAccessAction = (payload) => ({ type: ACTIONS.SET_ACCESS, payload });

/** Hook para el uso de las acciones */
export default function useSessionStore() {
  const dispatch = useDispatch();

  const setLoading = useCallback(
    (loading) => {
      dispatch(setLoadingAction({ loading }));
    },
    [dispatch]
  );
  const setToken = useCallback(
    (token) => {
      dispatch(setTokenAction({ token }));
    },
    [dispatch]
  );
  const setMeData = useCallback(
    (data) => {
      dispatch(setMeDataAction(data));
    },
    [dispatch]
  );
  const setAccess = useCallback(
    (data) => {
      dispatch(setAccessAction(data));
    },
    [dispatch]
  );
  const logout = useCallback(
    (data) => {
      dispatch(logoutAction(data));
    },
    [dispatch]
  );
  return { setLoading, setToken, setMeData, logout, setAccess };
}
