import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import AlertTitle from 'modules/core/components/Alerts/AlertTitle';
import Button from 'modules/core/components/Buttons/Button';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useMachine from 'modules/core/contexts/MachineContext/useMachine';
import React, { useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';
import useModal from 'modules/core/contexts/ModalContext/useModal';
import classModule from './SuccessMessageStep.module.scss';
import Styles from './SuccessMessageStep.style';
import DocumentScoring from '../../../../../sisco/components/DocumentScoring/DocumentScoring';
/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function SuccessMessageStep({ type }) {
  const { translate } = useDecorator();
  const { next, prev, context } = useMachine();
  const { alert } = useModal();
  // TODO: Ver si esta funcion se puede unir con la que es igual en el breadcrum
  const params = useMemo(() => {
    if (type === 'penalize') {
      return { penalize: true };
    }
    if (type === 'recover') {
      return { recover: true };
    }
    return null;
  }, [type]);
  const showAlert = useCallback(async () => {
    // Advertencia, se estan haciendo cambios imporatnes
    const message = (
      <AlertTitle severity="warning" {...Styles.Alert}>
        {translate(
          type === 'penalize'
            ? 'ADMIN.SCORING_RESULT_ADD_BLOCK'
            : 'ADMIN.SCORING_RESULT_ADD_UNBLOCK'
        )}
      </AlertTitle>
    );
    await alert.current.open(message, {
      title: translate('ADMIN.NOTIFICATION_MESSAGE_TITLE')
    });
  }, [alert, translate, type]);

  useEffect(() => {
    if (
      showAlert &&
      context &&
      _.get(context, 'getScoringPersonaDos.persona.puntos', 0) <= 0
    ) {
      showAlert();
    }
  }, [context, showAlert]);

  const goToHome = useCallback(() => {
    next();
  }, [next]);
  const goToRestart = useCallback(() => {
    prev();
  }, [prev]);
  return (
    <div className={classModule.SuccessMessageStep}>
      <DocumentScoring
        scoring={_.get(context, 'getScoringPersonaDos.persona')}
      />
      <AlertTitle severity="success" {...Styles.Alert}>
        {translate('ADMIN.SCORING_RESULT_ADD_OK', params)}
      </AlertTitle>

      <Stack
        spacing={2}
        direction="row"
        justifyContent="flex-end"
        {...Styles.Botonera}
      >
        <Button color="secondary" onClick={goToRestart} {...Styles.Buttons}>
          {translate('ADMIN.SCORING_ADD_NEW', params)}
        </Button>
        <Button onClick={goToHome} {...Styles.Buttons}>
          {translate('ADMIN.SCORING_GO_DASHBOARD')}
        </Button>
      </Stack>
    </div>
  );
}

SuccessMessageStep.propTypes = {
  type: PropTypes.string
};

export default SuccessMessageStep;
