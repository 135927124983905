/* eslint-disable react/no-array-index-key */
import useGetMotivosList from 'modules/admin/services/useMotivosServices/useGetMotivosList';
import { AutocompleteInput } from 'modules/core/components/Forms/Inputs';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import ContentMotivosAutoComplete from './ContentMotivosAutoComplete';

/**
 * Componente que busca y trae los motivos
 * @param {*} param0
 * @returns
 */
function MotivoAutocompleteInput({
  children,
  label,
  helperText = null,
  filter,
  onChange,
  person,
  ...props
}) {
  const { translate } = useDecorator();
  const defaultLabel = useMemo(
    () => (translate ? translate('ADMIN.LABEL_MOTIVO') : ''),
    [translate]
  );
  const { getMotivosList } = useGetMotivosList({ filter, person });
  const handleOnChange = useCallback(
    (id, obj) => {
      if (onChange) onChange(id, obj);
    },
    [onChange]
  );
  return (
    <AutocompleteInput
      label={label || defaultLabel}
      {...props}
      service={getMotivosList}
      helperText={helperText}
      onChange={handleOnChange}
      searchEmpty
      searchOnlyTime
      autocompleProps={{
        filterOptions: (options, { inputValue }) =>
          _.filter(
            options,
            (op) =>
              _.get(op, 'value.descripcion', '')
                .toLowerCase()
                .includes(inputValue.toLowerCase()) ||
              _.get(op, 'value.nombre', '')
                .toLowerCase()
                .includes(inputValue.toLowerCase()) ||
              _.get(op, 'value.idExterno', '')
                .toLowerCase()
                .includes(inputValue.toLowerCase())
          ),
        // filterOptions: (x) => {
        //   console.log(x);
        //   return x;
        // },
        renderOption: (propsRender, option, { inputValue }) => (
          <ContentMotivosAutoComplete
            key={`${_.get(option, 'value.id')}-${_.get(option, 'value.name')}`}
            propsRender={propsRender}
            option={option}
            inputValue={inputValue}
          />
        )
      }}
    />
  );
}

MotivoAutocompleteInput.propTypes = {
  children: PropTypes.node,
  filter: PropTypes.any,
  helperText: PropTypes.string,
  label: PropTypes.any,
  onChange: PropTypes.any,
  person: PropTypes.any
};
export default MotivoAutocompleteInput;
