import PropTypes from 'prop-types';
import React, {
  useMemo,
  useRef,
  useEffect,
  useImperativeHandle,
  useCallback,
  useState
} from 'react';
import ModalTemplate from 'modules/core/components/Templates/ModalTemplate';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import Modal from '../Modal';

let resolve = null;
// import classesModule from './Alert.module.scss';
/**
 * menu de alerta utilizando el modal
 * @param {*} param0
 * @returns
 */
function Confirm({
  onClose,
  innerRef,
  fullWidth = true,
  children,
  maxWidth = 'xs',
  templateProps,
  template: Template = ModalTemplate,
  title: _title = 'title',
  btnTextYes: _btnTextYes,
  btnTextNo: _btnTextNo,
  ...props
}) {
  /** Contenido a mostrarse dentro del alert */
  const { translate } = useDecorator();
  const [content, setContent] = useState(children);
  const [title, setTitle] = useState(_title);
  const [btnTextYes, setBtnTextYes] = useState(_btnTextYes);
  const [btnTextNo, setBtnTextNo] = useState(_btnTextNo);
  const [stateButton, setStateButton] = useState(null);
  /** Referencia al componente DOM del modal */
  const modalRef = useRef();
  /** si existe config verificamos qu tengan estas propiedades */
  const argumetentConfig = useCallback(
    (_config) => {
      if (_config) {
        if (_config.title) {
          setTitle(_config.title);
        } else {
          setTitle(_title);
        }
        if (_config.btnTextYes) {
          setBtnTextYes(_config.btnTextYes);
        } else {
          setBtnTextYes(
            _btnTextYes || translate('CORE.CONFIRM_BUTTON_TEXT_YES')
          );
        }
        if (_config.btnTextNo) {
          setBtnTextNo(_config.btnTextNo);
        } else {
          setBtnTextNo(_btnTextNo || translate('CORE.CONFIRM_BUTTON_TEXT_NO'));
        }
      }
    },
    [_btnTextNo, _btnTextYes, _title, translate]
  );
  /**   Funcion que se usa para abrir el modal, pero retorna una promesa */
  const handleOpen = useCallback(
    (message, _config = {}) =>
      new Promise((_resolve) => {
        setContent(message);
        setStateButton(null);
        argumetentConfig(_config);
        modalRef.current.open();
        resolve = _resolve;
      }),
    [modalRef, argumetentConfig]
  );

  /**  Funcion que se ejecuta en el boton de cerrar, o se publica para cerrar el modal */
  const handleCloseButton = useCallback(() => {
    modalRef.current.close();
  }, [modalRef]);
  /** Funcion que se ejecuta despues de cerrar el modal, para devolver la promesa con un valor  */
  const handleClose = useCallback(() => {
    resolve(stateButton);
    if (onClose) {
      onClose();
    }
  }, [onClose, stateButton]);
  const handelStateButton = useCallback(
    (state) => {
      resolve(state);
      setStateButton(state);
    },
    [setStateButton]
  );

  /** Configuraicon de botones que se van a usar en el Modal */
  const actionbuttons = useMemo(
    () => [
      {
        onClick: () => handelStateButton(false),
        color: 'secondary',
        children: btnTextNo
      },
      {
        onClick: () => handelStateButton(true),
        children: btnTextYes
      }
    ],
    [btnTextYes, btnTextNo, handelStateButton]
  );
  /** Estados y eventos que se publican */
  useImperativeHandle(
    innerRef,
    () => ({
      isOpen: modalRef.current.open,
      open: handleOpen,
      close: handleCloseButton
    }),
    [handleOpen, handleCloseButton]
  );
  useEffect(() => {
    if (stateButton !== null) {
      handleCloseButton();
    }
  }, [stateButton, handleCloseButton]);
  return (
    <Modal
      innerRef={modalRef}
      templateProps={{ ...templateProps, actionbuttons, title }}
      template={Template}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={handleClose}
      {...props}
    >
      {content}
    </Modal>
  );
}

Confirm.propTypes = {
  btnText: PropTypes.string,
  btnTextNo: PropTypes.string,
  btnTextYes: PropTypes.string,
  buttons: PropTypes.object,
  children: PropTypes.node,
  defaultConfig: PropTypes.object,
  fullWidth: PropTypes.bool,
  innerRef: PropTypes.object,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'md']),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  template: PropTypes.element,
  templateProps: PropTypes.object,
  title: PropTypes.string,
  variant: PropTypes.string
};
export default Confirm;
