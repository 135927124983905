import AuthScoringWizard from 'modules/auth/wizard/AuthScoringWizard';
import React from 'react';
import classesModule from './ConsultAuthScoringView.module.scss';
/**
 * Página de Login
 * @param {*} param0
 * @returns
 */
function ConsultAuthScoringView() {
  return (
    <div className={`${classesModule.main}`}>
      <AuthScoringWizard />
    </div>
  );
}
export default ConsultAuthScoringView;
