import _ from 'lodash';
import useLazyQuery from 'modules/core/hooks/useLazyQuery/useLazyQuery';
import { useCallback } from 'react';
import queries from './gql/getJuzgados';
/**
 * Hook creado para el llamado a Graphql de obtener un juzgado por ud
 * @returns
 */
function useGetJuzgadoById() {
  const [queryGetJuzgado] = useLazyQuery({
    query: queries.GET_JUZGADO_BY_ID,
    options: { fetchPolicy: 'network-only' }
  });
  const getJuzgado = useCallback(
    async (values) => {
      if (queryGetJuzgado) {
        const result = await queryGetJuzgado({ variables: values });
        return _.get(result, 'data.getJuzgado', null);
      }
      return null;
    },
    [queryGetJuzgado]
  );
  return {
    getJuzgado
  };
}

export default useGetJuzgadoById;
