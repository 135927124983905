// import useMachine from 'modules/core/contexts/MachineContext/useMachine';
import Button from 'modules/core/components/Buttons/Button';
import Typography from 'modules/core/components/Typographies/Typography';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useMachine from 'modules/core/contexts/MachineContext/useMachine';
import React, { useState, useCallback } from 'react';
import ConsultPublicScoringUser from 'modules/public/ConsultPublicScoringUser';
import { Stack } from '@mui/material';
import _ from 'lodash';
import HelpMessage from 'modules/core/components/HelpMessage';
import DocumentScoring from '../../../../../sisco/components/DocumentScoring/DocumentScoring';
import classModule from './ResultScoringView.module.scss';

/**
 * Step para mostrar el resultado public de scoring
 * @param {*} param0
 * @returns
 */
function ResultScoringStep() {
  const { translate } = useDecorator();
  const { context, prev } = useMachine();
  const [open, setOpen] = useState(false);
  const handlePrevStep = useCallback(() => {
    prev();
  }, [prev]);

  const goToComoRecuperarPuntos = useCallback(() => {
    window
      .open(translate('PUBLIC.BUTTON_OPEN_URL_RECUPERAR_PUNTOS'), '_blank')
      .focus();
  }, []);

  return (
    <div className={classModule.ResultScoringStep}>
      <Typography>{translate('PUBLIC.INTROTEXT_RESULT_SCORING')}</Typography>
      <Stack direction="column">
        <DocumentScoring scoring={{ ...context, ...context.persona }} />
        <div className={classModule.Alert}>
          <HelpMessage icon="info" title={translate('PUBLIC.ALERT_INFO')}>
            {translate('PUBLIC.ALERT_INFO_MESSAGE')}
          </HelpMessage>
        </div>
        {open && (
          <ConsultPublicScoringUser
            list={_.get(context, 'persona.scorings', [])}
          />
        )}
        <Stack direction="row" spacing={2} className={classModule.botonera}>
          <Button onClick={() => setOpen(!open)}>
            {!open
              ? translate('PUBLIC.SCORING_OPEN_DATAILS')
              : translate('PUBLIC.SCORING_CLOSE_DATAILS')}
          </Button>
          <Button onClick={goToComoRecuperarPuntos}>
            {translate('ADMIN.LINK_RECUPERAR_INFOMARCION')}
          </Button>
          <Button onClick={handlePrevStep}>
            {translate('PUBLIC.BUTTON_PUBLIC_SCOIRNG_OTHER')}
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}
export default ResultScoringStep;
