import { gql } from '@apollo/client';

const getUsersToTable = gql`
  query {
    getUsuarios {
      id
      nombre
      apellido
      email
      juzgadoId
      juzgado {
        nombre
      }
    }
  }
`;
const getPersonsToTable = gql`
  query getReportScoring($filters: [FilterInput]) {
    getReportScoring(filters: $filters) {
      id
      apellido
      nombre
      dni
      motivo
      fecha
      provinciA_ID
      localidaD_ID
      juzgadO_ID
      provinciA_NOMBRE
      localidaD_NOMBRE
      juzgadO_NOMBRE
      expediente
      puntos
      inhabilitado
      type
    }
  }
`;
const getFiltersScoring = gql`
  query {
    filters: getFilters {
      prov {
        value: id
        label: name
      }
      loc {
        value: id
        label: name
      }
      juzg {
        value: id
        label: name
      }
    }
  }
`;
const getUserById = gql`
  query getUsuario($id: Int!) {
    getUsuario(id: $id) {
      id
      nombre
      apellido
      email
      juzgadoId
    }
  }
`;
export default {
  GET_USERS_TO_TABLE: getUsersToTable,
  GET_USER_BY_ID: getUserById,
  GET_PERSONS_TO_TABLE: getPersonsToTable,
  GET_FILTERS_SCORING: getFiltersScoring
};
