import PlatformLayout from 'modules/sisco/layouts/PlatformLayout';
import { ROLES } from 'modules/sisco/constants';
import paths from './paths';
import JuzgadosView from './JuzgadosView';
import MotivosView from './MotivosView';
import UserView from './UserView';
import PersonListView from './PersonListView';
import UserScoringWizard from '../wizards/UserScoringWizard';

const moduleRouter = {
  scoring: {
    path: paths.scoring,
    content: UserScoringWizard,
    Template: PlatformLayout,
    // las props del template el children es lo que dice alado de
    templateProps: {
      breadcrums: [
        { href: '/admin/dashboard', children: 'Panel de control' },
        {
          children: 'ADMIN.BREADCRUMS_SCORING',
          paramsTransform: (params) => {
            if (params?.type === 'penalize') {
              return { penalize: true };
            }
            if (params?.type === 'recover') {
              return { recover: true };
            }
            return null;
          }
        }
      ],

      titleObjet: {
        title: 'ADMIN.BREADCRUMS_SCORING',
        paramsTransform: (params) => {
          if (params?.type === 'penalize') {
            return { penalize: true };
          }
          if (params?.type === 'recover') {
            return { recover: true };
          }
          return null;
        }
      },

      header: {
        title: 'ADMIN.META_TITLE'
      },
      expanded: false
    },
    access: {
      session: true,
      roles: [ROLES.DEFAULT, ROLES.ADMIN]
    }
  },
  juzgados: {
    path: paths.juzgados,
    content: JuzgadosView,
    Template: PlatformLayout,
    templateProps: {
      breadcrums: [
        { href: '/admin/dashboard', children: 'Panel de control' },
        { children: 'ADMIN.TITLE_JUZGADOS_VIEW' }
      ],
      title: 'ADMIN.TITLE_JUZGADOS_LIST_VIEW',
      header: {
        title: 'ADMIN.META_TITLE'
      }
    },
    access: {
      session: true,
      roles: [ROLES.ADMIN]
    }
  },

  motivos: {
    path: paths.motivos,
    content: MotivosView,
    Template: PlatformLayout,
    templateProps: {
      breadcrums: [
        { href: '/admin/dashboard', children: 'Panel de control' },
        { children: 'ADMIN.TITLE_MOTIVOS_VIEW' }
      ],
      title: 'ADMIN.TITLE_MOTIVOS_LIST_VIEW',
      header: {
        title: 'ADMIN.META_TITLE'
      }
    },
    access: {
      session: true,
      roles: [ROLES.ADMIN]
    }
  },
  user: {
    path: paths.user,
    content: UserView,
    Template: PlatformLayout,
    templateProps: {
      breadcrums: [
        { href: '/admin/dashboard', children: 'Panel de control' },
        { children: 'ADMIN.TITLE_USERS_LIST_PAGE' }
      ],
      title: 'ADMIN.TITLE_USERS_LIST_VIEW',
      header: {
        title: 'ADMIN.META_TITLE'
      }
    },
    access: {
      session: true,
      roles: [ROLES.ADMIN]
    }
  },
  personList: {
    path: paths.personList,
    content: PersonListView,
    Template: PlatformLayout,
    templateProps: {
      breadcrums: [
        { href: '/admin/dashboard', children: 'Panel de control' },
        { children: 'ADMIN.INTROTEXT_PERSONLIST_WITH_FILTERS' }
      ],
      title: 'ADMIN.INTROTEXT_PERSONLIST_WITH_FILTERS',
      header: {
        title: 'ADMIN.META_TITLE'
      }
    },
    access: {
      session: true,
      roles: [ROLES.ADMIN]
    }
  }
};
export default moduleRouter;
