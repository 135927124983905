import { gql } from '@apollo/client';

export const ADD_SCORING = gql`
  mutation addScoring(
    $motivoId: Int!
    $fechaInfraccion: String!
    $personaId: Int!
    $expediente: String!
    $descripcion: String!
    $puntos: Int!
  ) {
    addScoring(
      personaId: $personaId
      motivoId: $motivoId
      fechaInfraccion: $fechaInfraccion
      expediente: $expediente
      descripcion: $descripcion
      puntos: $puntos
    ) {
      id
    }
  }
`;
const editScoring = gql`
  mutation editScoring($id: Int!, $nombre: String!) {
    editScoring(id: $id, nombre: $nombre) {
      id
      nombre
    }
  }
`;
const deleteScoring = gql`
  mutation deleteScoring($ids: [Int]!) {
    deleteScoring(ids: $ids)
  }
`;
export default {
  DELETE_SCORING: deleteScoring,
  EDIT_SCORING: editScoring
};
