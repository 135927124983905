import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function ControllerInput({
  name,
  onChange: onChangeProp,
  render,
  template: Template = Grid,
  templateProps = { xs: 12 },
  innerRef,
  defaultValue = '',
  disabled,
  show = true,
  ...props
}) {
  const { control, formState } = useFormContext();

  const { isSubmitting } = formState;
  if (!show) {
    return null;
  }
  return (
    <Template item {...templateProps}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field, fieldState }) =>
          // TODO: REvisar si tienen que quedar el innerREf y el fiedState
          React.createElement(render, {
            ...field,
            onChange: (e, obj) => {
              if (onChangeProp) onChangeProp(e, obj);
              return field.onChange(e);
            },
            ...props,
            errorMessage: _.get(fieldState, `error.message`, null),
            disabled: disabled || isSubmitting,
            ref: null
          })
        }
      />
    </Template>
  );
}

ControllerInput.propTypes = {
  defaultValue: PropTypes.string,
  disabled: PropTypes.any,
  innerRef: PropTypes.any,
  name: PropTypes.any,
  onChange: PropTypes.func,
  render: PropTypes.any,
  show: PropTypes.bool,
  template: PropTypes.any,
  templateProps: PropTypes.object
};

export default ControllerInput;
