import MaterialButtonIcon from 'modules/core/components/Buttons/MaterialButtonIcon';
import MaterialMenuButtonIcon from 'modules/core/components/MaterialMenuButtonIcon/MaterialMenuButtonIcon';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

/**
 * Botonera creada para la pantalla de Listado de usuarios
 * @param {*} param0
 * @returns
 */
function Botonera({ onRefresh }) {
  const { translate } = useDecorator();
  /**
   * Función para ejecutar cuando se quiere refrescar la tabla
   */
  const handleRefresh = useCallback(() => {
    if (onRefresh) onRefresh();
  }, [onRefresh]);
  return (
    <MaterialMenuButtonIcon tooltip="Acciones">
      <MaterialButtonIcon
        icon="refresh"
        onClick={handleRefresh}
        tooltip={translate('ADMIN.TOOLTIP_BUTTON_REFRESH_SCORINGS')}
      />
    </MaterialMenuButtonIcon>
  );
}

Botonera.propTypes = {
  onRefresh: PropTypes.func
};
export default Botonera;
