import { createMachine } from 'xstate';

const machine =
  /** @xstate-layout N4IgpgJg5mDOIC5QDED2AnAtgUQB4ENMAHAGzADo0sA5QsAQQDsIAZfWAF1szAGJrsADQAqiUEVSwAlhympGYkLkQAmFQBZyARgDMOgJxajOlQA4tKgKwAaEAE9EAdi3lT+gAzut6gGzrT6jqOlgC+IbZUOATEZJQYOJj4UiS8AAoAStgAaooS0rLyisoIOlqO2lqmKo5eKjqWhiq2DghG+q4enu5+7jo+pqah4SCReISkFKOJyfxCokggeTJyCgvFpeW6BiqGgZU+NvaIWj7lA-r6pj4+JjqeKmER8WMxk-EAmmD46GmZOQtLAqrUDFNSaLaGYxmCyHFoqLSWVxufSOFQ+DR1BGmR4jZ7RCZxLCfb6zES5STLQprVQabR6SG6aFWZqIUouc76dS+dymdycqw40b42KwACuACNMDJeBB5BQpIwAG6oADWFAAZgqpLAABYAZQlUo45PyKyKiBuOnIfR8fJ5-R06ncTSOCGqiNRJkMnJ96J0grx4xFhulYHQ6Aw5FI+A46vi5E1jG1+pDxoBFKB5rdtIhRkZ5mZrrRVo5liuJxOOwDWBeBLFAGN63BYKT5uIM2bqQhLdbrnarqZHc6Wa1eXSav56QEgupq1EgxQwxGfgIyenTVSQRa9L3bR4B0OXS1dO5yBoanofLtuhowsNGKgIHBFEKF4TMNwGMw2JxPybKcCSiIOoR6IFU5B8nofR8uo+gaF4c61rEUxJCQ-6Zl2gSnkYVQDBCBxlCOg7kI0njqM45EIv0iHCm8RJfOg6GdlurTuJsliWDc+h+NOwQjmoLiBAYDSOLaVRqD4NFvmKkoyExm5AQgWEVLh5j0gRjj8Tc5CemYsHqJYvhaNxUmvOQDZNrA8DrgBWaWEEEFeM4fKDJY1Saa6lRWleKi9E6RgNN0knDK+ZlLhg8mAcUHGnlc3HeiBHFuCOWyuM6Xj6H0pT2Q8IWBmZibJpFWaDio1qWO4XJlpxamwscjo6eRzrXKYjhBMZs55TWtHFV2lQjgAtC4Wh8voDSXpYjiOK1-p3kAA */
  createMachine({
    id: 'UserScoring',
    initial: 'ConsultScoringForm',
    states: {
      ConsultScoringForm: {
        on: {
          NEXT: {
            target: 'EvaluateDni',
            actions: ['setContextAffterStep']
          }
        }
      },
      EvaluateDni: {
        always: [
          {
            cond: 'isDNIError',
            target: 'ShowErrorDni'
          },
          { target: 'GetPersona' }
        ]
      },
      ShowErrorDni: {
        on: {
          NEXT: {
            target: 'finish'
          },
          PREV: {
            target: 'ConsultScoringForm'
          }
        }
      },
      GetPersona: {
        invoke: {
          src: 'getScoringPersona',
          id: 'getScoringPersona',
          onDone: [
            {
              target: 'ScoringForm',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'ConsultScoringForm'
            }
          ]
        }
      },
      ScoringForm: {
        on: {
          PREV: {
            target: 'ConsultScoringForm'
          },
          NEXT: {
            target: 'AddScoring'
          }
        }
      },
      GetPersonaDos: {
        invoke: {
          src: 'getScoringPersonaDos',
          id: 'getScoringPersonaDos',
          onDone: [
            {
              target: 'ShowMessageSuccess',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'ConsultScoringForm'
            }
          ]
        }
      },
      AddScoring: {
        invoke: {
          src: 'addScoring',
          id: 'addScoring',
          onDone: [
            {
              target: 'GetPersonaDos',
              actions: ['setContextAfterInvoke']
            }
          ],
          onError: [
            {
              target: 'ScoringForm'
            }
          ]
        }
      },

      ShowMessageSuccess: {
        on: {
          NEXT: {
            target: 'finish'
          },
          PREV: {
            target: 'ConsultScoringForm'
          }
        }
      },
      finish: {
        entry: 'stopMachine',
        type: 'final'
      }
    }
  });
export default machine;
