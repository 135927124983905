import PropTypes from 'prop-types';
import React from 'react';
import TooltipMaterial from '@mui/material/Tooltip';

/**
 * Componente de Tooltips
 * @param {*} param0
 * @returns
 */
function Tooltip({ title, children, ...props }) {
  return (
    <TooltipMaterial title={title || ''} {...props}>
      <span>{children}</span>
    </TooltipMaterial>
  );
}
export default Tooltip;

Tooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

Tooltip.defaultProps = {
  title: null
};
