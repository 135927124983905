import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepperMaterial from '@mui/material/Stepper';
import * as React from 'react';
/**
 * Componete de Steppers
 * @param {*} param0
 * @returns
 */
export default function Stepper({
  steps = [],
  activeStep,
  spacing = 2,
  alternativeLabel = true
}) {
  return (
    <Stack sx={{ width: '100%' }} spacing={spacing}>
      <StepperMaterial
        alternativeLabel={alternativeLabel}
        activeStep={activeStep}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </StepperMaterial>
    </Stack>
  );
}

Stepper.propTypes = {
  activeStep: PropTypes.any,
  alternativeLabel: PropTypes.bool,
  spacing: PropTypes.number,
  steps: PropTypes.array
};
