import { gql } from '@apollo/client';

const addMotivo = gql`
  mutation addMotivo(
    $nombre: String!
    $descripcion: String!
    $idExterno: String!
    $puntos: Int!
  ) {
    addMotivo(
      nombre: $nombre
      descripcion: $descripcion
      idExterno: $idExterno
      puntos: $puntos
    ) {
      id
      nombre
      descripcion
      idExterno
      puntos
    }
  }
`;
const editMotivo = gql`
  mutation editMotivo(
    $nombre: String!
    $descripcion: String!
    $idExterno: String!
    $puntos: Int
    $id: Int!
  ) {
    editMotivo(
      nombre: $nombre
      descripcion: $descripcion
      idExterno: $idExterno
      puntos: $puntos
      id: $id
    ) {
      id
      nombre
      descripcion
      idExterno
      puntos
    }
  }
`;
const deleteMotivo = gql`
  mutation deleteMotivos($ids: [Int]!) {
    deleteMotivos(ids: $ids)
  }
`;
export default {
  ADD_MOTIVO: addMotivo,
  DELETE_MOTIVO: deleteMotivo,
  EDIT_MOTIVO: editMotivo
};
