/* eslint-disable jsx-a11y/anchor-is-valid */
import Button from 'modules/core/components/Buttons/Button';
import Form, { ControllerInput } from 'modules/core/components/Forms/Form';
import PasswordInput from 'modules/core/components/Forms/Inputs/PasswordInput';
import SectionTitleForm from 'modules/core/components/Templates/SectionTitleForm';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useGoogleRecaptchaV3 from 'modules/core/hooks/useGoogleRecaptchaV3';
import useNavigate from 'modules/core/hooks/useNavigate';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import * as Yup from 'yup';
import paths from 'modules/auth/views/paths';
/**
 *
 * Formulario de login
 * @returns
 */
function ResetPassForm({ onSubmit, template, templateProps }) {
  const { translate } = useDecorator();
  const schema = Yup.object().shape({
    newPassword: Yup.string()
      .required(translate('AUTH.VALIDATION_NEWPASSWORD_NEWPASSWORD_REQUIRED'))
      .nullable(translate('AUTH.VALIDATION_NEWPASSWORD_NEWPASSWORD_REQUIRED')),
    repeatNewpassword: Yup.string()
      .oneOf(
        [Yup.ref('newPassword'), null],
        translate('AUTH.VALIDATION_NEWPASSWORD_REPEATNEWPASSWORD')
      )
      .nullable(translate('AUTH.VALIDATION_NEWPASSWORD_REPEATNEWPASSWORD'))
  });
  const { handleSubmit } = useGoogleRecaptchaV3(
    process.env.REACT_APP_RECAPTCHA_V3_PUBLIC_KEY,
    onSubmit,
    `bottom: 90px!important;`
  );
  const { go } = useNavigate();
  const handleCancel = useCallback(() => {
    go(paths.login);
  }, [go]);
  return (
    <Form
      templateProps={{
        submitButtonProps: {
          children: translate('AUTH.RECOVER_PASS_SUBMIT_BUTTON')
        },
        buttons: {
          cancel: (
            <Button color="secondary" onClick={handleCancel}>
              {translate('AUTH.RECOVER_PASS_CANCEL_BUTTON')}
            </Button>
          )
        },
        ...templateProps
      }}
      schema={schema}
      onSubmit={handleSubmit}
      template={template}
    >
      <SectionTitleForm>
        <ControllerInput
          render={PasswordInput}
          name="newPassword"
          label={translate('AUTH.NEWPASSWORD_FORM_ADD_LABEL_NEWPASSWORD')}
          placeholder={translate(
            'AUTH.NEWPASSWORD_FORM_ADD_PLACEHOLDER_NEWPASSWORD'
          )}
        />
        <ControllerInput
          render={PasswordInput}
          name="repeatNewpassword"
          label={translate('AUTH.NEWPASSWORD_FORM_ADD_LABEL_REPEATNEWPASSWORD')}
          placeholder={translate(
            'AUTH.NEWPASSWORD_FORM_ADD_PLACEHOLDER_REPEATNEWPASSWORD'
          )}
        />
      </SectionTitleForm>
    </Form>
  );
}

ResetPassForm.propTypes = {
  onSubmit: PropTypes.func,
  template: PropTypes.any,
  templateProps: PropTypes.object
};
export default ResetPassForm;
