import { Stack } from '@mui/material';
import AlertTitle from 'modules/core/components/Alerts/AlertTitle';
import Button from 'modules/core/components/Buttons/Button';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useMachine from 'modules/core/contexts/MachineContext/useMachine';
import React, { useCallback } from 'react';
import _ from 'lodash';
import classModule from './ErrorAPIMessageStep.module.scss';
import Styles from './ErrorAPIMessageStep.style';
/**
 * Componente ErrorAPIMessage
 * @param {*} param0
 * @returns
 */
function ErrorAPIMessageStep() {
  const { translate } = useDecorator();
  const { next, context } = useMachine();

  const goToRestart = useCallback(() => {
    next();
  }, [next]);
  return (
    <div className={classModule.ErrorAPIMessageStep}>
      <AlertTitle severity="error" {...Styles.Alert}>
        {_.get(context, 'error')}
      </AlertTitle>
      <Stack
        spacing={2}
        direction="rows"
        justifyContent="flex-end"
        {...Styles.Botonera}
      >
        <Button onClick={goToRestart} {...Styles.Buttons}>
          {translate('PUBLIC.SCORING_LIST_OTHER_SEARCH')}
        </Button>
      </Stack>
    </div>
  );
}

export default ErrorAPIMessageStep;
