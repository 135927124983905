import { gql } from '@apollo/client';

const getMotivosToTable = gql`
  query {
    getMotivos {
      id
      nombre
      descripcion
      idExterno
      puntos
    }
  }
`;

const getMotivosToList = gql`
  query {
    getMotivos {
      id
      nombre
      descripcion
      puntos
      usoFrecuente
      idExterno
    }
  }
`;
const getMotivoById = gql`
  query getMotivo($id: Int!) {
    getMotivo(id: $id) {
      id
      nombre
      descripcion
      idExterno
      puntos
    }
  }
`;
export default {
  GET_MOTIVOS_TO_TABLE: getMotivosToTable,
  GET_MOTIVO_BY_ID: getMotivoById,
  GET_MOTIVOS_TO_LIST: getMotivosToList
};
