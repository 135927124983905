import useFetch from 'modules/core/hooks/useFetch/useFetch';
import { useCallback } from 'react';

/**
 * Hook para llamadas al server para el modulo de auth
 * @param {*} param0
 * @returns
 */
function useAuthServices() {
  const { post, loading } = useFetch({ useToken: false });
  const { get } = useFetch({ useToken: true });
  /**
   * Llado al server para hacer login
   */
  const logout = useCallback(async () => {
    try {
      const result = await get('/api/auth/logout', { redirect: false });
      return result;
    } catch (e) {
      console.log(e);
    }
    return null;
  }, [get]);
  /**
   * Llado al server para hacer login
   */
  const login = useCallback(
    async (params) => {
      const result = await post(
        '/api/auth/login',
        { ...params },
        { redirect: false }
      );
      return result;
    },
    [post]
  );

  /**
   * Llado al server para hacer login
   */
  const recover = useCallback(
    async (params) => {
      const result = await post(
        '/api/auth/recover',
        { ...params },
        { redirect: false }
      );
      return result;
    },
    [post]
  );

  /**
   * Llado al server para hacer login
   */
  const resetPass = useCallback(
    async (params) => {
      const result = await post(
        '/api/auth/resetPass',
        { ...params },
        { redirect: false }
      );
      return result;
    },
    [post]
  );

  return {
    login,
    logout,
    recover,
    resetPass,
    loading
  };
}

export default useAuthServices;
