/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Lottie from 'react-lottie';
import { Link } from '@mui/material';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useNavigate from '../../../core/hooks/useNavigate';
import classesModule from './ForbiddenView.module.scss';
import imgError from '../../assets/error403.json';
import Typography from '../../../core/components/Typographies/Typography';

/**
 * Componente vista de error 403
 * @param {*} param0
 * @returns
 */
function ForbiddenView() {
  const { go } = useNavigate();
  /** redireccionar a la pagina de inicio */
  const handleClick = () => {
    go('/');
  };
  const { translate } = useDecorator();

  return (
    <div className={`${classesModule.ForbiddenView}`}>
      <Lottie
        width="50%"
        height="50%"
        options={{
          loop: true,
          autoplay: true,
          animationData: imgError
        }}
      />
      <div className={classesModule.Title}>
        <Typography variant="h1">
          {translate('SISCO.MISSING_ERROR_404')}
        </Typography>
      </div>
      <Link sx={{ m: 6 }} component="button" onClick={handleClick}>
        {translate('SISCO.MISSING_LINK_ERROR_404')}
      </Link>
    </div>
  );
}

export default ForbiddenView;
