import { gql } from '@apollo/client';

const addJuzgado = gql`
  mutation addJuzgado(
    $nombre: String!
    $descripcion: String!
    $idExterno: String!
  ) {
    addJuzgado(
      nombre: $nombre
      descripcion: $descripcion
      idExterno: $idExterno
    ) {
      id
      nombre
      descripcion
      idExterno
    }
  }
`;
const editJuzgado = gql`
  mutation editJuzgado(
    $id: Int!
    $nombre: String!
    $descripcion: String!
    $idExterno: String!
  ) {
    editJuzgado(
      id: $id
      nombre: $nombre
      descripcion: $descripcion
      idExterno: $idExterno
    ) {
      id
      nombre
      descripcion
      idExterno
    }
  }
`;
const deleteJuzgado = gql`
  mutation deleteJuzgados($ids: [Int]!) {
    deleteJuzgados(ids: $ids)
  }
`;
const syncJuzgados = gql`
  mutation syncJuzgados {
    syncJuzgados
  }
`;

export default {
  ADD_JUZGADO: addJuzgado,
  DELETE_JUZGADO: deleteJuzgado,
  EDIT_JUZGADO: editJuzgado,
  SYNC_JUZGADOS: syncJuzgados
};
