export default {
  TITLE_JUZGADOS_VIEW: 'Juzgados',
  TITLE_JUZGADOS_LIST_VIEW: 'Juzgados',
  META_TITLE_JUZGADOS_LIST_VIEW: 'Listado de Juzgados',
  META_TITLE_USERS_LIST_VIEW: 'Listado de Usuarios',
  META_TITLE_MOTIVOS_LIST_VIEW: 'Listado de Motivos',
  VALIDATION_ERROR_SCORING: 'Ingrese un dato válido',
  META_TITLE_SCORING_LIST_VIEW: 'Scoring',
  INTROTEXT_JUZGADOS_LIST_VIEW: 'Juzgados',
  tooltip_button_delete: 'Borrar Juzgado',
  tooltip_button_sync: 'Sincronizar juzgados',
  tooltip_button_edit: 'Modificar Juzgado',
  tooltip_button_add: 'Agregar Juzgado',
  tooltip_button_refresh: 'Refrescar lista de jugados',
  GRID_JUZGADOS_TITLE_NOMBRE: 'Nombre',
  GRID_JUZGADOS_TITLE_DESCRIPCION: 'Motivos',
  GRID_JUZGADOS_TITLE_PROVINCIA: 'Provincia',
  GRID_JUZGADOS_TITLE_LOCALIDAD: 'Localidad',
  GRID_JUZGADOS_TITLE_DOMICILIO: 'Domicilio',
  GRID_JUZGADOS_TITLE_IDEXTERNO: 'ID Externo',
  LINK_DASHBOARD_INFOMARCION: 'Más información',
  LINK_RECUPERAR_INFOMARCION: 'Recuperar puntos',
  JUZGADOS_CONFIRM_TITLE_DELETE_JUZGADO: 'Sisco',
  LINK_DASHBOARD_MANUAL_USUER: 'Manual del usuario',
  JUZGADOS_CONFIRM_MESSAGE_DELETE_JUZGADO:
    'Esta apunto de borrar los juzgados seleccionados. ¿Confirmar Operación?',
  JUZGADOS_FORM_ADD_EDIT_TITLE:
    '{{#id}}Editar Juzgado{{/id}}{{^id}}Agregar Juzgado{{/id}}',
  JUZGADOS_FORM_ADD_EDIT_BUTTON: '{{#id}}Modificar{{/id}}{{^id}}Crear{{/id}}',
  JUZGADOS_FORM_ADD_LABEL_NAME: 'Juzgado',
  VALIDATION_SCORING: 'El puntaje es obligatorio',
  VALIDATION_MIN_SCORING_RECOVER:
    'El motivo por concurso es para descontar puntos',
  VALIDATION_MAX_SCORING_PENALIZE: 'El valor máximo a descontar es: -20',
  VALIDATION_MAX_SCORING_PENALIZE_CONCURSO:
    'El valor máximo a descontar es: -15',
  VALIDATION_MAX_SCORING_RECOVER:
    'El motivo por concurso es para descontar puntos',
  VALIDATION_MIN_SCORING_PENALIZE: 'El valor mínimo a descontar es: -1',
  JUZGADOS_FORM_ADD_LABEL_ID_EXTERNO: 'Id externo',
  JUZGADOS_FORM_ADD_LABEL_DESCRIPTION: 'Descripción',
  JUZGADOS_FORM_ADD_PLACEHOLDER_NAME: 'Ingrese nombre del juzgado',
  JUZGADOS_FORM_ADD_PLACEHOLDER_ID_EXTERNO: 'Ingrese el id Externo',
  JUZGADOS_FORM_ADD_PLACEHOLDER_DESCRIPTION:
    ' Ingrese una descripción del juzgado',
  VALIDATION_JUZGADO_JUZGADO_REQUIRED: 'Juzgado es obligatorio',
  VALIDATION_EXPEDINETE__REQUIRED: 'El expediente es obligatorio',
  VALIDATION_JUZGADO_DESCRIPTION_REQUIRED: 'La descripción es obligatoria',
  VALIDATION_JUZGADO_IDEXTERNO_REQUIRED: 'Id Externo es un campo obligatorio',
  // MOTIVOS
  TITLE_MOTIVOS_VIEW: 'Motivos',
  TITLE_MOTIVOS_LIST_VIEW: 'Motivos',
  TOOLTIP_BUTTON_ADD_MOTIVOS: 'Agregar Motivo',
  TOOLTIP_BUTTON_EDIT_MOTIVOS: 'Modificar Motivo',
  TOOLTIP_BUTTON_REFRESH_MOTIVOS: 'Refrescar lista de Motivo',
  TOOLTIP_BUTTON_DELETE_MOTIVOS: 'Eliminar Motivo',
  VALIDATION_MOTIVOS_DESCRIPTION_REQUIRED: 'La descripción es obligatoria',
  VALIDATION_MOTIVOS_SCORE_REQUIRED: 'El puntaje es obligatorio',
  GRID_MOTIVOS_TITLE_IDZ: 'ID',
  VALIDATION_MOTIVOS_IDEXTERNO_REQUIRED: 'El ID externo es requerido',
  MOTIVOS_FORM_ADD_LABEL_ID_EXTERNO: 'ID externo',
  MOTIVOS_FORM_ADD_PLACEHOLDER_ID_EXTERNO: 'Ingrese el ID externo',
  // motivo
  MOTIVOS_FORM_ADD_EDIT_TITLE:
    '{{#id}}Editar Motivo {{/id}}{{^id}}Agregar Motivo{{/id}}',
  MOTIVOS_FORM_ADD_BUTTON: '{{#id}}Modificar{{/id}}{{^id}}Crear{{/id}}',
  MOTIVOS_FORM_ADD_LABEL_DESCRIPTION: 'Descripción',
  VALIDATION_DESCRIPCION__REQUIRED: 'La descripción es obligatoria',
  SNAKBAR_MESSAGE_DELETE_MOTIVO_SUCCESS_MUTATION: 'se a borrado un motivo',
  MOTIVOS_FORM_ADD_PLACEHOLDER_DESCRIPTION:
    ' Ingrese una descripción del motivo',
  INTROTEXT_MOTIVOS_LIST_VIEW: 'Motivos',
  MOTIVOS_CONFIRM_TITLE_DELETE_MOTIVOS: 'Sisco',
  MOTIVOS_CONFIRM_MESSAGE_DELETE_MOTIVOS:
    'Esta apunto de borrar los motivos seleccionados. ¿Confirmar Operación?',
  VALIDATION_MOTIVOS_NAME_REQUIRED: 'El nombre  de la falta es requerido',
  MOTIVOS_FORM_ADD_LABEL_NAME: 'falta',
  MOTIVOS_FORM_ADD_PLACEHOLDER_NAME: 'Ingrese nombre de la falta',
  MOTIVOS_FORM_ADD_LABEL_SCORE: 'Score',
  MOTIVOS_FORM_ADD_PLACEHOLDER_SCORE: ' Ingrese el score',
  GRID_MOTIVOS_TITLE_NOMBRE: 'Nombre',
  GRID_MOTIVOS_TITLE_DESCRIPCION: 'Descripción',
  GRID_JUZGADOS_TITLE_PUNTOS: 'Puntos',
  GRID_MOTIVOS_TITLE_IDEXTERNO: 'ID Externo',
  // SCORIGN title
  DASHBOARD_CARD_TITLE_REOVER_SCORING: 'Recuperar puntos',
  DASHBOARD_CARD_TITLE_PENALIZE_SCORING: 'Descontar puntos',
  SCORING_FORM_ADD_LABEL_DNI: 'DNI',
  SCORING_FORM_ADD_PLACEHOLDER_DNI: 'Ingrese el DNI',
  SCORING_FORM_ADD_LABEL_REASON_NEGATIVE: 'Listado de infracciones',
  SCORING_FORM_ADD_LABEL_REASON_POSITIVE: 'Motivo',
  SCORING_FORM_ADD_LABEL_DESCRIPTION: 'Observaciones',
  SCORING_FORM_ADD_PLACEHOLDER_DESCRIPTION:
    'Ingrese las observaciones del scoring',
  INTROTEXT_SCORING_LIST_PENALIZE_VIEW: 'Descontar puntos',
  INTROTEXT_SCORING_LIST_REOVER_VIEW: 'Recuperar puntos',
  SCORING_FORM_ADD_BUTTON: 'Agregar',
  VALIDATION_SCORING_DNI_REQUIRED: 'El DNI es requerido',
  VALIDATION_SCORING_REASON_REQUIRED: 'El motivo es requerido',
  VALIDATION_SCORING_DATE_INFRACTION_BEFORE:
    'La fecha debe ser anterior a la actual',
  VALIDATION_SCORING_DESCRIPTION_REQUIRED: 'La descripción es requerida',
  // USER
  GRID_USERVIEW_TITLE_ID: 'ID',
  TOOLTIP_BUTTON_ADD_USERVIEW: 'Agregar Usuario',
  TOOLTIP_BUTTON_EDIT_USERVIEW: 'Modificar Usuario',
  TOOLTIP_BUTTON_RESET_PASS_USERVIEW: 'Modificar contraseña',
  TOOLTIP_BUTTON_DELETE_USERVIEW: 'Borrar Usuario',
  TOOLTIP_BUTTON_REFRESH_SCORINGS: 'Refrescar',
  TOOLTIP_BUTTON_REFRESH_USERVIEW: 'Refrescar lista de Usuarios',
  USERS_FORM_ADD_EDIT_TITLE:
    '{{#id}}Editar Usuario {{/id}}{{^id}}Agregar Usuario{{/id}}',
  USER_FORM_ADD_BUTTON: '{{#id}}Modificar{{/id}}{{^id}}Crear{{/id}}',
  // titles
  TITLE_USERS_LIST_VIEW: 'Listado de usuarios',
  LABEL_MOTIVO: 'Motivo',
  LABEL_NEXT_SCORING_STEP: 'Procesar',
  LABEL_STATE: 'Provincia',
  LABEL_LOCALITY: 'Localidad',
  TITLE_USERS_LIST_PAGE: 'Listado de usuarios',
  INTROTEXT_USERS_LIST_VIEW: 'Usuarios',
  INTROTEXT_PERSONLIST_WITH_FILTERS: 'Lista de Scorings',
  USERVIEW_FORM_ADD_TITLE: 'Agregar Usuario',
  GRID_PERSON_TITLE_TYPE: 'Tipo',
  USERVIEW_FORM_ADD_BUTTON: 'Crear',
  USER_FORM_ADD_LABEL_USER: 'Email',
  USER_FORM_ADD_PLACEHOLDER_USER: 'Ingrese su Email',
  USER_FORM_ADD_LABEL_COURTS: 'Juzgado',
  USER_FORM_ADD_LABEL_NAME: 'Nombre',
  USER_FORM_ADD_PLACEHOLDER_NAME: 'Ingrese el nombre',
  USER_FORM_ADD_LABEL_LAST_NAME: 'Apellido',
  USER_FORM_ADD_PLACEHOLDER_LAST_NAME: 'Ingrese el apellido',
  USER_CONFIRM_MESSAGE_DELETE_MOTIVOS:
    'Esta apunto de borrar los usuarios seleccionados. ¿Confirmar Operación?',
  USER_CONFIRM_TITLE_DELETE_MOTIVOS: 'Sisco',
  VALIDATION_ERROR_EMAIL: 'Email invalido',
  VALIDATION_USER_EMAIL_REQUIRED: 'El email es requerido',
  VALIDATION_USER_COURTS_REQUIRED: 'Juzgado es requerido',
  VALIDATION_USER_NAME_REQUIRED: 'El nombre es obligatorio',
  VALIDATION_USER_LAST_NAME_REQUIRED: 'El apellido es obligatorio',
  SNAKBAR_MESSAGE_DELETE_JUZGADO_SUCCESS_MUTATION:
    'Se ha eliminado con exito el juzgado',
  SNAKBAR_MESSAGE_SYNC_JUZGADOS_SUCCESS_MUTATION:
    'Se han sincronizado los juzgados con exito',
  VALIDATION_LOGIN_PASSWORD_REQUIRED: 'La contraseña es obligatoria',
  LOGIN_FORM_ADD_LABEL_PASSWORD: 'contraseña',
  LOGIN_FORM_ADD_PLACEHOLDER_PASSWORD: 'Ingrese su contraseña',
  GRID_USER_TITLE_NOMBRE: 'Nombre',
  GRID_USER_TITLE_APELLIDO: 'Apellido',
  GRID_USER_TITLE_STATUS_LICENSE: 'Licencia',
  GRID_USER_TITLE_EMAIL: 'Email',
  GRID_USER_TITLE_JUZGADO_ID: 'Juzgado ID',
  LICENSE_ENABLED: 'Habilitada',
  LICENSE_DISABLED: 'Deshabilitada',
  SNAKBAR_MESSAGE_DELETE_USER_SUCCESS_MUTATION:
    'Se a borrado el usuario con exito',
  VALIDATION_MOTIVOS_JUZGADO_ID_REQUIRED: 'El juzgado es obligatorio',
  SCORING_FORM_ADD_LABEL_PUNTOS: 'Puntos',
  SCORING_FORM_ADD_PLACEHOLDER_PUNTOS: 'Ingrese puntos',
  SCORING_FORM_ADD_LABEL_EXPEDIENTE: 'Expediente',
  SCORING_FORM_ADD_PLACEHOLDER_EXPEDIENTE: 'Ingrese numero de expediente',
  SCORING_ADD_NEW: 'Ingresar otra licencia',
  SCORING_GO_DASHBOARD: 'Ir al panel de control',
  SCORING_DOCUMENT_STATUS_LICENCIA_HABILITADA: 'Habilitada',
  SCORING_DOCUMENT_STATUS_LICENCIA_BLOQUEADA: 'Inhabilitada',
  // penalizar o recuperar
  BREADCRUMS_SCORING:
    '{{#penalize}}Descontar puntos{{/penalize}}{{#recover}}Recuperar puntos{{/recover}}',
  GRID_USER_TITLE_JUZGADO: 'Juzgado',
  VALIDATION_SCORING_FECHA_REQUIRED: 'La fecha es obligatoria',
  SCORING_FORM_ADD_LABEL_DESCRIPTION_FECHA:
    '{{#penalize}}Fecha de sentencia firme{{/penalize}}{{#recover}}Fecha del curso{{/recover}}',
  TITLE_TEXT_MOTIVOS_LIST_VIEW: 'Motivos',
  TITLE_TEXT_JUZGADOS_LIST_VIEW: 'Juzgados',
  VALIDATION_FECHA_REASON_REQUIRED: 'La fecha es obligatoria',
  VALIDATION_FECHA_ERROR_REASON_REQUIRED: 'Fecha inválida',
  TOOLTIP_FAVORITO: 'Favorito',
  SCORING_ERROR_DNI: 'El documento ingresado no posee licencia nacional',
  SCORING_LIST_OTHER_SEARCH: 'Volver a consultar',
  SCORING_RESULT_ADD_OK: 'Operación realizada',
  SCORING_RESULT_ADD_BLOCK:
    'Notifique al conductor/a y retenga la licencia por haber llegado a 0 (cero) puntos',
  SCORING_RESULT_ADD_UNBLOCK:
    'Se cargó el curso correctamente. La persona quedará rehabilitada una vez cumplido el plazo de inhabilitación correspondiente.',
  NOTIFICATION_MESSAGE_TITLE: 'Atención',
  META_TITLE: 'Scoring | Agencia Nacional de Seguridad Vial',
  SCORING_OPEN_DETAILS: 'Ver detalle',
  SCORING_CLOSE_DETAILS: 'Ocultar detalle',
  ALERT_INFO_MAIL: 'Mesa de ayuda',
  ALERT_INFO_MAIL_MESSAGE: 'Si necesitás asesoramiento, escribí a ',
  ALERT_INFO_MAIL_MESSAGE_EMAIL: 'ayudascoring@seguridadvial.gob.ar',
  FOOTER_CONTACT:
    '<h2>Contacto</h2><p><b>Agencia Nacional de Seguridad Vial</b></p> <p><b>Dirección:</b> Adolfo Alsina N° 756, Piso 3°, CABA</p> <p><b>Código postal:</b> C1087AAL </p> <p><b>Web:</b> <a href="https://www.argentina.gob.ar/seguridadvial" target="_blank" >www.argentina.gob.ar/seguridadvial</a></p>',
  GRID_PERSON_TITLE_DNI: 'Documento N°',
  GRID_PERSON_TITLE_DNI_TYPE: 'Tipo de documento',
  GRID_PERSON_TITLE_JUZG: 'Juzgado',
  GRID_PERSON_TITLE_MOTIVE: 'Motivo',
  GRID_PERSON_TITLE_DATE: 'Fecha',
  GRID_PERSON_TITLE_PROV: 'Provincia',
  GRID_PERSON_TITLE_LOCAL: 'Localidad',
  GRID_PERSON_TITLE_RECORD: 'Expediente',
  GRID_PERSON_TITLE_POINTS: 'Puntos'
};
