import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@mui/material';
/**
 * este componente pone el helpertext solo cuando no hay algun tipo de error
 * @param {*} param0
 * @returns
 */
function FormHelperError({ helperText = null, errorMessage = null }) {
  return (
    <>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      {helperText && !errorMessage && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </>
  );
}
export default FormHelperError;

FormHelperError.propTypes = {
  errorMessage: PropTypes.string,
  helperText: PropTypes.string
};

FormHelperError.defaultProps = {
  helperText: null
};
