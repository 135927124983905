import _ from 'lodash';
import { useAddScoring } from 'modules/admin/services/useScoringServices';
import { useGetScoringPersona } from 'modules/auth/services/useScoringUserServices';
import Wizard from 'modules/core/components/Wizard';
import LoadingStep from 'modules/core/components/Wizard/components/LoadingStep';
import useNavigate from 'modules/core/hooks/useNavigate';
import React, { useCallback, useMemo } from 'react';
import { assign } from 'xstate';
import ConsultScoringStep from './Steps/ConsultScoringStep';
import ErrorDniMessageStep from './Steps/ErrorDniMessageStep';
import ScoringStep from './Steps/ScoringStep';
import SuccessMessageStep from './Steps/SuccessMessageStep';
import machine from './UserScoringMachine';
/**
 * Wizard para la registracion de usuarios por via web
 * @returns
 */
function UserScoringWizard() {
  const { getScoringPersona } = useGetScoringPersona();
  const { addScoring } = useAddScoring();
  const { go, params } = useNavigate();

  const steps = useMemo(
    () => ({
      ConsultScoringForm: {
        render: ConsultScoringStep,
        stepperPosition: 0
      },
      GetPersona: {
        render: LoadingStep,
        stepperPosition: 0
      },
      ScoringForm: {
        render: ScoringStep,
        renderProps: { type: _.get(params, 'type') },
        stepperPosition: 1
      },
      AddScoring: {
        render: LoadingStep,
        stepperPosition: 1
      },
      GetPersonaDos: {
        render: LoadingStep,
        stepperPosition: 1
      },

      ShowErrorDni: {
        render: ErrorDniMessageStep,
        renderProps: { type: _.get(params, 'type') },
        stepperPosition: 2
      },
      ShowMessageSuccess: {
        render: SuccessMessageStep,
        renderProps: { type: _.get(params, 'type') },
        stepperPosition: 2
      }
    }),
    [params]
  );
  const guards = {
    isDNIError: (context) => {
      try {
        const dni = parseInt(_.get(context, 'documento', 0), 10);
        return dni === 0;
      } catch (e) {
        return false;
      }
    }
  };
  const actions = {
    setContextAffterStep: assign((context, event) => {
      const values = _.get(event, 'payload.values');
      return { ...context, ...values };
    })
  };
  const services = {
    getScoringPersona: async (context, event) => {
      const aux = _.get(event, 'payload.values');
      return await getScoringPersona({ ...aux });
    },
    getScoringPersonaDos: async (context) => {
      const values = {
        tipoDocumento: _.get(context, 'tipoDocumento'),
        documento: _.get(context, 'documento'),
        sexo: _.get(context, 'sexo')
      };

      return await getScoringPersona(values);
    },

    addScoring: async (context, event) => {
      const values = _.get(event, 'payload.values');
      // para el submit

      return await addScoring(
        {
          personaId: Number(_.get(context, 'getScoringPersona.persona.id', '')),
          motivoId: Number(_.get(values, 'motivoId', '')),
          fechaInfraccion: values.fechaInfraccion.toISOString(),
          descripcion: values.descripcion,
          expediente: values.expediente,
          puntos: values.puntos
        },
        { success: { show: false } }
      );
    }
  };
  const handleStopMachine = useCallback(() => {
    go('/');
  }, [go]);
  return (
    <Wizard
      guards={guards}
      machine={machine}
      steps={steps}
      services={services}
      actions={actions}
      onStopMachine={handleStopMachine}
    />
  );
}

export default UserScoringWizard;
