import moduleSisco from '../../modules/sisco/views';
import moduleAuth from '../../modules/auth/views';
import moduleCore from '../../modules/core/views';
import modulePublic from '../../modules/public/views';
import moduleAdmin from '../../modules/admin/views';
// moduleCore should always go at the end of the array because global contains the 404 pagecore: moduleCore,

const routes = {
  core: moduleCore,
  auth: moduleAuth,
  public: modulePublic,
  admin: moduleAdmin,
  sisco: moduleSisco
};

export default routes;
