import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import logo from 'assets/images/logo.png';
import useNavigate from 'modules/core/hooks/useNavigate';
import React from 'react';
import argentinaUnida from 'assets/images/seguridad_nacional.png';
import HeaderAvatarButton from '../../../../components/HeaderAvatarButton';
import classes from './Header.module.scss';

/**
 * Header de la pantalla principal
 * @returns Material Header
 */
function Header() {
  const { go } = useNavigate();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0}>
        <Toolbar>
          <Box
            onClick={() => go('/admin/dashboard')}
            sx={{
              cursor: 'pointer',
              flexGrow: 1,
              display: { xs: 'none', sm: 'block' }
            }}
          >
            <img src={logo} alt="MiScoring" className={classes.logo} />
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <img
              src={argentinaUnida}
              className={classes.argentinaUnida}
              alt="Argentina Unida"
            />
          </Box>
          <HeaderAvatarButton />
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
