import PlatformLayout from 'modules/sisco/layouts/PlatformLayout';
import PlatformPublicLayout from 'modules/sisco/layouts/PlatformPublicLayout';
import ChangePasswordView from './ChangePasswordView';
import ConsultAuthScoringView from './ConsultAuthScoringView';
import LoginView from './LoginView';
import LogoutView from './LogoutView';
import MyUserView from './MyUserView';
import NewPasswordView from './NewPasswordView';
import paths from './paths';
import ResetPassView from './ResetPassView';
import RecoverView from './RecoverView';

const moduleRouter = {
  login: {
    path: paths.login,
    content: LoginView,
    Template: PlatformPublicLayout,
    templateProps: {
      title: 'AUTH.TITLE_LOGIN_LIST_VIEW',
      header: {
        title: 'AUTH.META_TITLE'
      }
    },
    access: {
      session: false
    }
  },
  logout: {
    path: paths.logout,
    content: LogoutView,
    Template: PlatformPublicLayout,
    access: {
      session: true
    }
  },
  recover: {
    path: paths.recover,
    content: RecoverView,
    Template: PlatformPublicLayout,
    templateProps: {
      title: 'AUTH.TITLE_RECOVER_VIEW',
      header: {
        title: 'AUTH.META_TITLE'
      }
    },
    access: {
      session: false
    }
  },
  reset_pass: {
    path: paths.reset_pass,
    content: ResetPassView,
    Template: PlatformPublicLayout,
    templateProps: {
      title: 'AUTH.TITLE_RECOVER_PASS_VIEW',
      header: {
        title: 'AUTH.META_TITLE'
      }
    },
    access: {
      session: false
    }
  },
  changePassword: {
    path: paths.changePassword,
    content: ChangePasswordView,
    Template: PlatformLayout,
    templateProps: {
      header: {
        title: 'AUTH.META_TITLE'
      },
      breadcrums: [
        { href: '/admin/dashboard', children: 'Panel de control' },
        { children: 'Cambio de contraseña' }
      ],
      title: 'AUTH.TITLE_RESET_PASSWORD_VIEW',
      expanded: false
    },
    access: {
      session: true
    }
  },
  myAcount: {
    path: paths.myAcount,
    content: MyUserView,
    Template: PlatformLayout,
    templateProps: {
      header: {
        title: 'AUTH.META_TITLE'
      },
      breadcrums: [
        { href: '/admin/dashboard', children: 'Panel de control' },
        { children: 'Datos del usuario' }
      ],
      title: 'AUTH.TITLE_MY_ACOUNT_VIEW',
      expanded: false
    },
    access: {
      session: true
    }
  },
  newPassword: {
    path: paths.newPassword,
    content: NewPasswordView,
    Template: PlatformPublicLayout,
    access: {
      session: true
    },
    templateProps: {
      header: {
        title: 'AUTH.META_TITLE'
      }
    }
  },
  consultAuthScoring: {
    path: paths.consultAuthScoring,
    content: ConsultAuthScoringView,
    Template: PlatformLayout,
    templateProps: {
      breadcrums: [
        { href: '/admin/dashboard', children: 'Panel de control' },
        { children: 'AUTH.TITLE_SCORING_PAGE' }
      ],
      title: 'AUTH.TITLE_SCORING',
      header: {
        title: 'AUTH.META_TITLE'
      },
      expanded: true
    },
    access: {
      session: true
    }
  }
};
export default moduleRouter;
