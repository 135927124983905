import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey
} from '@mui/material/colors';

/**
 * Helper para manejar colores material
 * @returns
 */
const useColors = () => {
  const materialColors = [
    red[500],
    pink[500],
    purple[500],
    deepPurple[500],
    indigo[500],
    blue[500],
    lightBlue[500],
    cyan[500],
    teal[500],
    green[500],
    lightGreen[500],
    lime[500],
    yellow[800],
    amber[500],
    orange[500],
    deepOrange[500],
    brown[500],
    grey[500],
    blueGrey[500]
  ];

  const opacity = 0.16;

  /**
   * Seleciona un color de material
   */
  const getMaterialColor = () => {
    const newColor =
      materialColors[Math.floor(Math.random() * materialColors.length)];
    return newColor;
  };

  /**
   * Calcula un color de fondo para un componente
   */
  const getMaterialBackgroundColor = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    const result = `rgba(${r}, ${g}, ${b}, ${opacity})`;
    return result;
  };

  return {
    getMaterialColor,
    getMaterialBackgroundColor
  };
};
export default useColors;
