import _ from 'lodash';
import { SelectInput } from 'modules/core/components/Forms/Inputs';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import { TYPE_DOCUMENTS } from 'modules/sisco/constants';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
/**
 * Componente que busca y trae los juzgados
 * @param {*} param0
 * @returns
 */
function TypeDocumentsSelectInput({
  children,
  label,
  helperText = null,
  ...props
}) {
  const { translate } = useDecorator();
  const defaultLabel = useMemo(
    () => (translate ? translate('SISCO.LABEL_TYPE_DOCUMENT') : ''),
    [translate]
  );

  const list = useMemo(() => {
    if (translate) {
      return _.map(TYPE_DOCUMENTS, (typeDocument) => ({
        id: typeDocument,
        description: translate(`SISCO.TYPE_DOCUMENT_LABEL_${typeDocument}`)
      }));
    }
    return [];
  }, [translate]);

  return (
    <SelectInput
      label={label || defaultLabel}
      {...props}
      list={list}
      helperText={helperText}
    />
  );
}

TypeDocumentsSelectInput.propTypes = {
  children: PropTypes.node,
  label: PropTypes.any,
  helperText: PropTypes.string
};
export default TypeDocumentsSelectInput;
