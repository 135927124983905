/* eslint-disable react/jsx-no-useless-fragment */
import ScoringForm from 'modules/admin/forms/ScoringForm';
import WizardTemplate from 'modules/core/components/Templates/WizardTemplate';
import useMachine from 'modules/core/contexts/MachineContext/useMachine';
import DocumentScoring from 'modules/sisco/components/DocumentScoring/DocumentScoring';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import _ from 'lodash';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import ConsultPublicScoringUser from 'modules/public/ConsultPublicScoringUser';
/**
 * Step donde se agregan los datos del scoring
 * @param {*} param0
 * @returns
 */
function ScoringStep({ type }) {
  const { translate } = useDecorator();
  const { next, prev, context } = useMachine();
  const [open, setOpen] = useState(false);
  const components = useMemo(
    () => ({
      left: {
        color: 'secondary',
        onClick: () => setOpen(!open),
        children: (
          <>
            {!open
              ? translate('ADMIN.SCORING_OPEN_DETAILS')
              : translate('ADMIN.SCORING_CLOSE_DETAILS')}
          </>
        )
      },
      prev: { onClick: prev },
      next: { children: translate('ADMIN.LABEL_NEXT_SCORING_STEP') },
      header: (
        <DocumentScoring
          scoring={_.get(context, 'getScoringPersona.persona')}
        />
      )
    }),
    [prev, context, open]
  );
  const handleSubmit = useCallback(
    (values) => {
      next(values);
    },
    [next]
  );

  return (
    <>
      <ScoringForm
        template={WizardTemplate}
        onSubmit={handleSubmit}
        templateProps={{ components }}
        filterMotivos={type}
        person={context.getScoringPersona.persona}
      />
      {open && (
        <ConsultPublicScoringUser
          list={_.get(context, 'getScoringPersona.scoring', [])}
        />
      )}
    </>
  );
}

ScoringStep.propTypes = {
  type: PropTypes.any
};
export default ScoringStep;
