import React from 'react';
import NewPasswordForm from 'modules/auth/forms/NewPasswordForm';
// import classesModule from './LoginView.module.scss';

/**
 * Página de Login
 * @param {*} param0
 * @returns
 */
function NewPasswordView() {
  return (
    <div>
      <NewPasswordForm />
    </div>
  );
}
export default NewPasswordView;
