export default {
  CONFIRM_BUTTON_TEXT_YES: 'Si',
  CONFIRM_BUTTON_TEXT_NO: 'No',
  MESSAGE_STEP: 'La pantalla esta en un paso sin step',
  SNAKBAR_MESSAGE_DEFAULT_ERROR_MUTATION:
    'Se ha generado un error en la operación',
  SNAKBAR_MESSAGE_DEFAULT_SUCCESS_MUTATION: 'Operación con exito',
  SNAKBAR_MESSAGE_DEFAULT_ERROR_QUERY:
    'Se ha generado un error en la operación',
  SNAKBAR_MESSAGE_DEFAULT_SUCCESS_QUERY: 'Operación con exito',
  module_core_test: 'Prueba de multi_idioma',
  notification_message_default_success_post: 'Operación terminada con exito',
  notification_message_default_error_post: 'No se pudo terminar la operación',
  // radio
  OPTION_LABEL_YES: 'si',
  OPTION_LABEL_NO: 'no',
  LABEL_OPTION: 'Ingrese una opción',
  SUBMITBUTTON_TEXT: 'Guardar',
  WIZARD_TEMPLATE_BUTTON_SUBMIT_TEXT_NEXT: 'Siguiente',
  WIZARD_TEMPLATE_BUTTON_SUBMIT_TEXT_PREV: 'Anterior',
  PLACEHOLDER_COUNTRY: 'Ingrese el pais',
  LABEL_COUNTRY: 'Pais',
  LABEL_CITY: 'Ciudad',
  PLACEHOLDER_CITY: 'Ingrese la ciudad',
  LABEL_STATE: 'Localidad',
  PLACEHOLDER_STATE: 'Ingrese localidad',
  LABEL_STREET: 'Calle',
  PLACEHOLDER_STREET: 'Ingrese calle',
  LABEL_ZIPCODE: 'Codigo Postal',
  PLACEHOLDER_ZIPCODE: 'Ingrese Codigo Postal',
  LABEL_DETAILS: 'Detalle',
  PLACEHOLDER_DETAILS: 'Ingrese algún detalle',
  LABEL_DESTINATION: 'Destino',
  PLACEHOLDER_DESTINATION: 'Ingrese su destino',
  LABEL_additionalAddress: 'Piso/Departamento',
  PLACEHOLDER_additionalAddress: 'Piso/Depto.',
  ERROR_FILE_MAX_UPLOAD_FILES: 'No puede adjuntar más de ',
  ERROR_FILE_EXTENSION: 'extensión ',
  ERROR_FILE_EXCLUDED: ' A sido excluida',
  ERROR_FILE_MAX_FILE_SIZE: 'El tamaño de los archivos no se puede exceder de ',
  ERROR_MAX_UPLOAD_FILES: ' Archivos',
  DELETE_ALL: 'Borrar todo',
  AUTOCOMPLETE_TEXT_LOADING: 'Cargando...',
  AUTOCOMPLETE_TEXT_NO_OPTION: 'Sin resultados',
  AUTOCOMPLETE_TEXT_NO_SEACH: 'Realice una busqueda',
  META_TITLE: 'Mi Scoring | Agencia Nacional de Seguridad Vial',
  LOADING_COMPONENT: {
    general_loading: 'Cargando...',
    bbq_l_loading: 'Prendiendo...',
    cake_l_loading: 'Sirviendo..',
    chicken_l_loading: 'Asando...',
    chopping_board_l_loading: 'Picando...',
    cooking_l_loading: 'Salteando...',
    cutlery_l_loading: 'Poniendo la mesa...',
    cutting_l_loading: 'Cortando...',
    dish_l_loading: 'Sirviendo...',
    egg_l_loading: 'Rompiendo...',
    hat_l_loading: 'Preparandose...',
    knife_l_loading: 'Afilando...',
    ladle_l_loading: 'Revolviendo...',
    mitten_l_loading: 'Verificando...',
    noodle_l_loading: 'Revolviendo...',
    oven_l_loading: 'Cosinando...',
    pepper_l_loading: 'Condimentando...',
    recipe_book_l_loading: 'Estudiando...',
    rolling_pin_l_loading: 'Amasando...',
    salad_l_loading: 'Preparando...',
    saucepan_l_loading: 'Hirviendo...',
    slow_cooker_l_loading: 'Calentando...',
    toaster_l_loading: 'Tostando...',
    coffe_cup_l_loading: 'Sirviendo...',
    salt_and_pepper_l_loading: 'Salando...'
  }
};
