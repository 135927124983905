import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import React from 'react';
import _ from 'lodash';
import SubmitButton from '../../Buttons/SubmitButton';
import classesModule from './SimpleFormTemplate.module.scss';
/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function SimpleFormTemplate({
  children,
  title,
  spacing = 2,
  submitButtonProps,
  buttons = {}
}) {
  return (
    <div>
      {title && <h1>{title}</h1>}
      <Grid container spacing={spacing}>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
      <div className={classesModule.botonera}>
        {_.map(buttons, (button) => button)}

        <SubmitButton {...submitButtonProps} />
      </div>
    </div>
  );
}

SimpleFormTemplate.propTypes = {
  children: PropTypes.any,
  spacing: PropTypes.number,
  submitButtonProps: PropTypes.any,
  title: PropTypes.any,
  buttons: PropTypes.array
};
export default SimpleFormTemplate;
