import { useLazyQuery as useLazyQueryApollo } from '@apollo/client';
import { useCallback } from 'react';
import _ from 'lodash';
import useSnakbar from 'modules/core/contexts/SnakbarContext/useSnakbar';
import useLogger from 'modules/core/contexts/LoggerContext/useLogger';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useNavigate from '../useNavigate';
import { ERRORS } from '../../constants';
import useSession from '../useSession';
/**
 * Hook para llamados al server con Apollo
 * @param {*} param0
 * @returns
 */
function useLazyQuery({ query, options }) {
  const { go } = useNavigate();
  const { translate } = useDecorator();
  const { error, success } = useSnakbar();
  const { error: logError } = useLogger();
  const { logout } = useSession();
  const handleError = useCallback(
    (errorExeption, config) => {
      logError(errorExeption);

      const statusCode = _.get(errorExeption, 'networkError.statusCode', 500);
      switch (statusCode) {
        case 401:
        case 403:
          logout();
          // TODO: Cambiar por logout cuando este la redireccion
          // go('/logout');
          break;
        default: {
          if (_.get(config, 'error.show', true)) {
            const errorCode = _.get(errorExeption, 'graphQLErrors.0.errorCode');
            let messageError = _.get(
              config,
              'error.message',
              translate('CORE.SNAKBAR_MESSAGE_DEFAULT_ERROR_QUERY')
            );
            switch (errorCode) {
              case ERRORS.API:
                messageError = _.get(errorExeption, 'graphQLErrors.0.msg');
                break;
              default:
            }
            error(messageError);
          }
          throw new Error(errorExeption);
        }
      }
    },
    [logError, error, translate, go]
  );
  const handleSuccess = useCallback(
    (config) => {
      if (_.get(config, 'success.show', false)) {
        const messageSuccess = _.get(
          config,
          'success.message',
          translate('CORE.SNAKBAR_MESSAGE_DEFAULT_SUCCESS_QUERY')
        );
        success(messageSuccess);
      }
    },
    [success, translate]
  );
  const [manualQuery, rest] = useLazyQueryApollo(query, options);
  const handleManualQuery = useCallback(
    async (params, config) => {
      let result = null;
      try {
        result = await manualQuery(params);
      } catch (e) {
        handleError(e, config);
        return null;
      }
      if (result.error) {
        handleError(result.error, config);
        return null;
      }
      handleSuccess(config);
      return result;
    },
    [manualQuery, handleError, handleSuccess]
  );
  return [handleManualQuery, rest];
}
export default useLazyQuery;
