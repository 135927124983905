import React from 'react';
import classesModule from './LegacyView.module.scss';
/**
 * Página de terminos y condiciones
 * @returns
 */
function LegacyView() {
  return <div className={` ${classesModule.LegacyView} `}>Legacy </div>;
}
export default LegacyView;
