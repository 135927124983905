import { inspect } from '@xstate/inspect';
import { assign } from 'xstate';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import MachineProvider from '../../contexts/MachineContext/MachineProvider';
import Step from './components/Step';

/**
 * machine: una maquina de estado ya creado
 * steps: json que mapea los sptes de la machine con componetnes nuestros
 */
function Wizard({
  machine,
  steps,
  stepper,
  actions: _actions = {},
  services: _services = {},
  guards: _guards = {},
  onStopMachine
}) {
  /**
   * Solo usado para desarrollo y activar el debug de la XState
   */
  useEffect(() => {
    if (process.env.REACT_APP_DEV_DEBUG_XSTATE === 'true') {
      inspect({ iframe: false });
    }
  }, []);

  const actions = useMemo(
    () => ({
      setContextAffterStep: assign((context, event) => {
        const idState = _.get(event, 'payload.idState');
        const values = _.get(event, 'payload.values');
        let newValueState = {};
        if (values && idState) newValueState = { [idState]: values };
        return { ...context, ...newValueState };
      }),
      setContextAfterInvoke: assign((context, event) => {
        const type = _.get(event, 'type', '');
        const values = _.get(event, 'data');
        const typeSplit = type.split('.');
        const indexType = typeSplit[typeSplit.length - 1];

        let newValueState = {};
        if (values && indexType) newValueState = { [indexType]: values };
        return { ...context, ...newValueState };
      }),
      setContextAfterError: assign((context, event) => ({
        ...context,
        error: _.get(event, 'data')
      })),
      ..._actions,
      stopMachine: async (context, event) => {
        if (onStopMachine) {
          onStopMachine(context, event);
        }
      }
    }),
    [_actions, onStopMachine]
  );
  return (
    <MachineProvider
      guards={_guards}
      machine={machine}
      steps={steps}
      stepper={stepper}
      actions={actions}
      services={_services}
    >
      <Step />
    </MachineProvider>
  );
}

Wizard.propTypes = {
  actions: PropTypes.object,
  guards: PropTypes.any,
  machine: PropTypes.any,
  onStopMachine: PropTypes.func,
  services: PropTypes.object,
  stepper: PropTypes.array,
  steps: PropTypes.any.isRequired
};

export default Wizard;
