import _ from 'lodash';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useMutation from 'modules/core/hooks/useMutation/useMutation';
import { useCallback } from 'react';
import mutations from './gql/mutationUsers';
/**
 * Hook creado para el llamado de servicios que borra usuarios
 * @returns
 */
function useDeleteUser() {
  const { translate } = useDecorator();
  const [mutationDelete] = useMutation({
    mutation: mutations.DELETE_USUARIO
  });
  const deleteUser = useCallback(
    async (values) => {
      if (mutationDelete) {
        const result = await mutationDelete(
          { variables: values },
          {
            success: {
              message: translate(
                'ADMIN.SNAKBAR_MESSAGE_DELETE_USER_SUCCESS_MUTATION'
              )
            }
          }
        );
        const aux = _.get(result, 'data.deleteUsuario', null);
        return aux;
      }
      return null;
    },
    [mutationDelete, translate]
  );
  return {
    deleteUser
  };
}

export default useDeleteUser;
