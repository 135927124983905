import _ from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useSettings from '../contexts/SettingsContext/useSettings';

/**
 * Hook para el manejo de seguridad
 * @returns
 */
const useSecurity = () => {
  const session = useSelector((store) => _.get(store, 'session'));

  const {
    hasAccess: hasAccessCustom,
    hasRoleAccess: hasRoleAccessCustom,
    hasSessionAccess: hasSessionAccessCustom
  } = useSettings();

  const hasRoleAccess = useCallback(
    (needRoles) => {
      if (hasRoleAccessCustom) {
        return hasRoleAccessCustom(needRoles, session);
      }
      if (_.isEmpty(needRoles)) {
        return true;
      }
      const roles = _.get(session, 'access.roles', []);
      const aux = !!_.find(roles, (role) => needRoles.indexOf(role) >= 0);

      return aux;
    },
    [session, hasRoleAccessCustom]
  );
  const hasSessionAccess = useCallback(
    (needSession) => {
      if (hasSessionAccessCustom) {
        return hasSessionAccessCustom(needSession, session);
      }
      if (needSession === null) {
        return true;
      }
      const hasSession = session && session.token;
      if (needSession && !hasSession) return false;
      if (!needSession && hasSession) return false;
      return true;
    },
    [session, hasSessionAccessCustom]
  );
  const hasAccess = useCallback(
    (access) => {
      if (hasAccessCustom) {
        return hasAccessCustom(access, session);
      }
      const needRoles = _.get(access, 'roles', []);
      const needSession = _.get(access, 'session', null);
      const sessionAccess = hasSessionAccess(needSession);
      if (!sessionAccess) return false;
      const roleAccess = hasRoleAccess(needRoles);
      if (!roleAccess) return false;
      return true;
    },
    [hasRoleAccess, hasSessionAccess, hasAccessCustom, session]
  );

  return {
    hasRoleAccess,
    hasSessionAccess,
    hasAccess
  };
};
export default useSecurity;
