import { useQuery } from '@apollo/client';
import _ from 'lodash';
import query from 'modules/auth/services/useAcount/gql/getMyUser';
import useSessionStore from 'modules/core/store/session/useSessionStore';
import { useEffect } from 'react';
/**
 * Componente que maneja el pedido de usuario
 * @param {*} param0
 * @returns
 */
function Session() {
  const { error, data } = useQuery(query.GET_MY_USER);
  const { setMeData, setAccess, setLoading } = useSessionStore();

  useEffect(() => {
    if (data) {
      const { role, ...user } = _.get(data, 'getMyUsuario');
      setMeData(user);
      setAccess({ roles: [role] });
      setLoading(false);
    }
  }, [data, setAccess, setMeData, setLoading]);
  useEffect(() => {
    if (error) {
      // TODO: Remplazar por variable
      window.location = '/error';
    }
  }, [error]);

  return null;
}

export default Session;
