import React from 'react';
import Stack from '@mui/material/Stack';
import classesModule from './NoRowsOverlay.module.scss';
import emptyImage from '../../../../../../assets/svgs/noRows.svg';
// TODO: el emptyImage tiene que estar en el modulo y poder ser un parametro
/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function NoRowsOverlay() {
  return (
    <div className={` ${classesModule.NoRowsOverlay} `}>
      <Stack height="100%" alignItems="center" justifyContent="center">
        <img src={emptyImage} alt="" style={{ width: '500px' }} />
        No hay datos todavía.
      </Stack>
    </div>
  );
}
export default NoRowsOverlay;
