import Stack from '@mui/material/Stack';
import React from 'react';
import classesModule from './NoResultsOverlay.module.scss';

/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function NoResultsOverlay() {
  return (
    <div className={` ${classesModule.NoResultsOverlay} `}>
      <Stack height="100%" alignItems="center" justifyContent="center">
        No hay resultados que mostrar...
      </Stack>
    </div>
  );
}
export default NoResultsOverlay;
