import IconButton from '@mui/material/IconButton';
import MateIcon from 'modules/core/components/Icons/MaterialIcon';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '../../Helpers/Tooltip';
import Access from '../../Security/Access';
/**
 * Material Button Icon
 * @param {*} param0
 * @returns
 */
function MaterialButtonIcon({
  tooltip,
  placement = 'bottom',
  color = 'primary',
  size = 'small',
  sizeIcon = '1.5rem',
  onClick,
  disabled = false,
  type = 'button',
  access = null,
  icon,
  ...props
}) {
  /**
   * Cambia un hex a RGBA
   * @returns
   */
  const convertToRgba = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    const result = `rgba(${r}, ${g}, ${b}, ${opacity})`;
    return result;
  };

  return (
    <Access access={access}>
      <Tooltip title={tooltip} placement={placement}>
        <IconButton
          {...props}
          size={size}
          onClick={onClick}
          disabled={disabled}
          type={type}
          style={{ backgroundColor: convertToRgba(color, 0.16) }}
          sx={{
            '&& .MuiTouchRipple-root': {
              color
            }
          }}
        >
          <MateIcon icon={icon} size={sizeIcon} iconColor={color} />
        </IconButton>
      </Tooltip>
    </Access>
  );
}

MaterialButtonIcon.propTypes = {
  access: PropTypes.any,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  placement: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  sizeIcon: PropTypes.string,
  tooltip: PropTypes.string,
  type: PropTypes.string
};

MaterialButtonIcon.defaultProps = {
  size: 'small',
  color: 'primary',
  tooltip: null,
  onClick: null,
  disabled: false
};

export default MaterialButtonIcon;
