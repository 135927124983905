export default {
  title_form_login: 'Ingresar a SISco',
  VALIDATION_NEWPASSWORD_PASSWORD_REQUIRED: 'la contraseña es obligatoria',
  VALIDATION_NEWPASSWORD_NEWPASSWORD_REQUIRED:
    'la nueva contraseña es obligatoria',
  VALIDATION_NEWPASSWORD_REPEATNEWPASSWORD: 'las contraseñas no son iguales',
  VALIDATION_LOGIN_USER_NAME_REQUIRED: 'El nombre de usuario obligariorio',
  VALIDATION_LOGIN_PASSWORD_REQUIRED: 'La contraseña es obligatoria',
  TITLE_LOGIN_LIST_VIEW: 'Iniciar sesión',
  LOGIN_FORM_ADD_BUTTON: 'Ingresar',
  LOGIN_FORM_ADD_LABEL_USERNAME: 'Email',
  LOGIN_FORM_ADD_PLACEHOLDER_USERNAME: 'Ingresá tu e-mail',
  LOGIN_FORM_ADD_LABEL_PASSWORD: 'Contraseña',
  LOGIN_FORM_ADD_PLACEHOLDER_PASSWORD: 'Ingresá tu contraseña',
  NEWPASSWORD_FORM_ADD_BUTTON: 'Guardar cambios',
  NEWPASSWORD_FORM_ADD_LABEL_PASSWORD: 'Contraseña',
  NEWPASSWORD_FORM_ADD_PLACEHOLDER_PASSWORD: 'Ingresá tu contraseña actual',
  NEWPASSWORD_FORM_ADD_LABEL_NEWPASSWORD: 'Nueva contraseña',
  NEWPASSWORD_FORM_ADD_PLACEHOLDER_NEWPASSWORD: 'Ingresá una nueva contraseña',
  NEWPASSWORD_FORM_ADD_LABEL_REPEATNEWPASSWORD: 'Confirmá tu contraseña',
  NEWPASSWORD_FORM_ADD_PLACEHOLDER_REPEATNEWPASSWORD:
    'Volvé a escribir tu nueva contraseña',
  TITLE_RECOVER_VIEW: 'Recuperar contraseña',
  TITLE_RECOVER_PASS_VIEW: 'Asignar nueva contraseña',
  RECOVER_PASS_CANCEL_BUTTON: 'Cancelar',
  RECOVER_PASS_SUBMIT_BUTTON: 'Continuar',
  RECOVER_SUBMIT_BUTTON: 'Recuperar',
  RECOVER_CANCEL_BUTTON: 'Cancelar',
  RECOVER_PASS_EMAIL_SENT:
    'Se envió un email a tu casilla, revisalo y sigue las instrucciones para restablecer tu contraseña',
  ERROR_EMAIL: 'Ingrese un Email valido',
  VALIDATION_USER_USER_REQUIRED: 'El Email es obligatorio',
  PASSWORDRESET_FORM_ADD_BUTTON: 'Enviar',
  PASSWORDRESET_FORM_ADD_LABEL_USER: 'Email',
  PASSWORDRESET_FORM_ADD_PLACEHOLDER_USER: 'Ingresá tu e-mail',
  TITLE_RESET_PASSWORD_VIEW: 'Cambiá tu contraseña',
  TITLE_MY_ACOUNT_VIEW: 'Datos del usuario',
  MY_USUARIO_NAME_LASTNAME: 'Nombre y Apellido',
  MY_USER_EMAIL: 'Email',
  MY_USUARIO_JUZGADO: 'Juzgado',
  // SCORING TITLE
  TITLE_SCORING: 'Consultar scoring',
  TITLE_SCORING_PAGE: 'Consultar scoring',
  META_TITLE_SCORING: 'Consultar scoring',
  INTROTEXT_TITLE_LIST_SCORING_VIEW: 'Consultar scoring',
  WIZARD_SCORING_BUTTON_STEP1_NEXT: 'Consultar',
  INTROTEXT_SCORING: 'Ingresá tus datos',
  GRID_SCORING_TITLE_MOTIVO: 'Motivo',
  GRID_SCORING_TITLE_FECHA: 'Fecha',
  GRID_SCORING_TITLE_FECHA_INFRACCION: 'Fecha Infracción',
  GRID_SCORING_TITLE_FECHA_SENTENCIA: 'Fecha Sentencia',
  GRID_SCORING_TITLE_PROVINCIA: 'Provincia',
  GRID_SCORING_TITLE_LOCALIDAD: 'Localidad',
  GRID_SCORING_TITLE_EXPEDIENTE: 'Expediente',
  GRID_SCORING_TITLE_PUNTOS: 'Puntos',
  SCORING_USER_NEW: 'Consultar otra persona',
  INTROTEXT_LIST_SCORING_VIEW: 'Histórico',
  GRID_SCORING_TITLE_JUZGADO: 'Juzgado',
  ALT_IMAGE_SEGURIDAD_VIAL: 'Seguridad Vial',
  META_TITLE: 'Scoring | Agencia Nacional de Seguridad Vial',
  GO_TO_LOGIN: 'Ir al Login',
  RESET_PASS_SUCCESS: 'El cambio de contraseña se realizó con éxito'
};
