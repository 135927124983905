/* eslint-disable react/jsx-no-constructed-context-values */
import PropTypes from 'prop-types';
import { SnackbarProvider as SnackbarProviderNoti } from 'notistack';
import React, { useState } from 'react';
import SnakbarContext from '.';

const defaultAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'center'
};
/**
 *
 * Documentation: https://iamhosseindhv.com/notistack/demos
 */
function SnakbarProvider({
  children,
  maxSnack: maxSnackProps = 3,
  anchorOrigin: anchorOriginProps = defaultAnchorOrigin
}) {
  const [maxSnack, setMaxSnack] = useState(maxSnackProps);
  const [anchorOrigin, setAnchorOrigin] = useState(anchorOriginProps);

  return (
    <SnakbarContext.Provider value={{ setMaxSnack, setAnchorOrigin }}>
      <SnackbarProviderNoti maxSnack={maxSnack} anchorOrigin={anchorOrigin}>
        {children}
      </SnackbarProviderNoti>
    </SnakbarContext.Provider>
  );
}

SnakbarProvider.propTypes = {
  anchorOrigin: PropTypes.oneOf([
    defaultAnchorOrigin.vertical,
    defaultAnchorOrigin.horizontal
  ]),
  children: PropTypes.node,
  maxSnack: PropTypes.number
};

export default SnakbarProvider;
