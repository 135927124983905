import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import { useMemo } from 'react';

/**
 * Hook creado para tener sepado del componente de react la definicion y logica de la grilla
 */
function useDataGridAttributes() {
  const { translate } = useDecorator();
  const defaultOrder = [{ field: 'id', sort: 'desc' }];
  const defaultPageSize = 10;

  const defaultFilters = useMemo(() => [], []);
  const columns = useMemo(
    () => [
      {
        field: 'nombre',
        headerName: translate('ADMIN.GRID_USER_TITLE_NOMBRE'),
        filterable: true,
        sortable: true,
        flex: 1
      },
      {
        field: 'apellido',
        headerName: translate('ADMIN.GRID_USER_TITLE_APELLIDO'),
        filterable: true,
        sortable: true,
        flex: 1
      },
      {
        field: 'email',
        headerName: translate('ADMIN.GRID_USER_TITLE_EMAIL'),
        filterable: true,
        sortable: true,
        flex: 2
      },
      {
        field: 'juzgado',
        headerName: translate('ADMIN.GRID_USER_TITLE_JUZGADO'),
        filterable: true,
        sortable: true,
        flex: 1
      }
    ],
    [translate]
  );
  return { columns, defaultFilters, defaultOrder, defaultPageSize };
}

export default useDataGridAttributes;

// ADMIN.GRID_USERVIEW_TITLE_ID
