import PropTypes from 'prop-types';
import { DevTool } from '@hookform/devtools';
import React from 'react';
import { useFormContext } from 'react-hook-form';

/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function DevtoolForm({ placement = 'top-left' }) {
  const { control } = useFormContext();
  return <DevTool control={control} placement={placement} />;
}

DevtoolForm.propTypes = {
  placement: PropTypes.string
};

export default DevtoolForm;
