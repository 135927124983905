import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import logo from 'assets/images/logo.png';
import React from 'react';
import argentinaUnida from 'assets/images/seguridad_vial.png';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import classes from './Header.module.scss';
/**
 * Layout de las paginas de la plataforma sin estar logueado
 * @returns Material Header
 */
function Header() {
  const { translate } = useDecorator();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
            <img src={logo} alt="MiScoring" className={classes.logo} />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', sm: 'block', textAlign: 'right' }
            }}
          >
            <img
              src={argentinaUnida}
              className={classes.logo_seguridad}
              alt={translate('AUTH.ALT_IMAGE_SEGURIDAD_VIAL')}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
