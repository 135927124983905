import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { FormControl } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import classes from './DateInput.module.scss';
import Style from './DateInput.stlye';
import FormHelperError from '../../Form/components/FormHelperError';
/**
 * Componente de DAteInput
 * @param {*} param0
 * @returns
 */
function DateInput({
  children,
  errorMessage,
  variant,
  defaultValue = null,
  size,
  label,
  helperText = null,
  required = false,
  PopperProps = {},
  ...props
}) {
  const { dateFormat } = useDecorator();
  return (
    <FormControl sx={{ width: '100%' }} error={!!errorMessage}>
      <DesktopDatePicker
        label={label}
        className={`${classes.main}`}
        inputFormat={dateFormat}
        defaultValue={defaultValue}
        PopperProps={{
          placement: 'right',
          ...PopperProps
        }}
        renderInput={(params) => (
          <TextField
            variant={variant}
            size={size}
            required={required}
            {...params}
            {...Style.TextField}
          />
        )}
        {...props}
      />
      <FormHelperError errorMessage={errorMessage} helperText={helperText} />
    </FormControl>
  );
}

DateInput.propTypes = {
  PopperProps: PropTypes.object,
  children: PropTypes.node, //* Variant style component
  defaultValue: PropTypes.any,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'string']),
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard'])
};

DateInput.defaultProps = {
  children: null,
  variant: process.env.REACT_APP_STYLED_DEFAULT_INPUT_VARIANT || 'outlined',
  size: process.env.REACT_APP_STYLED_DEFAULT_INPUT_SIZE || 'small',
  errorMessage: null,
  label: null,
  helperText: null,
  defaultValue: null
};
export default DateInput;
