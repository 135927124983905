import _ from 'lodash';
import useLazyQuery from 'modules/core/hooks/useLazyQuery/useLazyQuery';
import { useCallback } from 'react';
import queries from './gql/getScoring';
/**
 * Hook creado para el llamado a Graphql de servicios de scoring
 * @returns
 */
function useGetScoringPersona() {
  /** Servicios para obtener los scoring para la visa de la tabla */
  const [queryGetScoring] = useLazyQuery({
    query: queries.GET_SCORING_TO_TABLE,
    options: { fetchPolicy: 'no-cache', nextFetchPolicy: 'no-cache' }
  });
  const getScoringPersona = useCallback(
    async (values) => {
      if (queryGetScoring) {
        const result = await queryGetScoring({ variables: values });
        const scoringResult = _.get(
          result,
          'data.getScoringPersona.persona.scorings',
          []
        );
        const personaResult = _.get(result, 'data.getScoringPersona', {});

        const scoring = _.map(scoringResult, (s) => ({
          id: _.get(s, 'id'),
          fechaInfraccion: _.get(s, 'fechaInfraccion'),
          fechaSentencia: _.get(s, 'fechaSentencia'),
          provincia: _.get(s, 'juzgado.provincia'),
          localidad: _.get(s, 'juzgado.localidad'),
          expediente: _.get(s, 'expediente'),
          juzgado: _.get(s, 'juzgado.nombre'),
          motivo: _.get(s, 'motivo.nombre'),
          puntos: _.get(s, 'puntos')
        }));
        const persona = {
          documento: _.get(personaResult, 'persona.documento'),
          estado: _.get(personaResult, 'estado'),
          tipoDocumento: _.get(personaResult, 'persona.tipoDocumento'),
          puntos: _.get(personaResult, 'persona.puntos'),
          nombre: _.get(personaResult, 'persona.nombre'),
          apellido: _.get(personaResult, 'persona.apellido'),
          id: _.get(personaResult, 'persona.id')
        };
        return {
          scoring,
          persona
        };
      }
      return {
        scoring: [],
        persona: {}
      };
    },
    [queryGetScoring]
  );
  return {
    getScoringPersona
  };
}

export default useGetScoringPersona;
