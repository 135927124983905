import { CircularProgress, FormControl, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useLogger from 'modules/core/contexts/LoggerContext/useLogger';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FormHelperError from '../../Form/components/FormHelperError';
/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function AutocompleteInput({
  list: _list = [],
  label,
  errorMessage,
  variant,
  disabled,
  helperText = null,
  service,
  filters,
  onChange,
  onChangeInput,
  filterOptions: _filterOptions = null,
  autocompleProps = {},
  searchEmpty = false,
  searchOnlyTime = false,
  debounceTime = 200,
  size,
  take = 20,
  value: _formValue,
  mapValue,
  ...props
}) {
  const [list, setList] = useState(_list);
  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(false);
  const { translate } = useDecorator();
  const { error: errorLogger } = useLogger();
  const [firstSearchComplete, setFirstSearchComplete] = useState(false);
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  /**
   * Cambia los valores del query
   * @param {*} event
   */
  const changeHandler = (e) => {
    const valueEvent = _.get(e, 'target.value');
    setQuery(valueEvent);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    _.debounce(changeHandler, debounceTime),
    [changeHandler, debounceTime]
  );
  const handleChangeInput = useCallback(
    (event, newInputValue) => {
      debouncedChangeHandler(event, newInputValue);
      setInputValue(newInputValue);
      if (onChangeInput) {
        onChangeInput(newInputValue);
      }
    },
    [debouncedChangeHandler, setInputValue, onChangeInput]
  );
  const handleChange = useCallback(
    (e, newValue) => {
      setAutoCompleteValue(newValue);
      if (onChange) {
        onChange(_.get(newValue, 'id'), _.get(newValue, 'value'));
      }
    },
    [setAutoCompleteValue, onChange]
  );

  const getData = useCallback(async () => {
    // Busca los datos en el server, mientras que

    // condicion si puede buscar dependiendo de la cantidad de veces que hizo la busqueda
    const canSearchByTimes =
      (searchOnlyTime && !firstSearchComplete) || !searchOnlyTime;
    // condicion si puede buscar dependiendo de si tiene valor o si tiene que cargar los datos de forma automatica
    const canSearchFirstTime =
      searchEmpty ||
      (_formValue && !firstSearchComplete) ||
      (!searchEmpty && query);
    if (service && canSearchByTimes && canSearchFirstTime) {
      setLoading(true);
      try {
        const resultList = await service({
          ...filters,
          q: query,
          take,
          id: _formValue
        });

        let finedDefaultValueInList =
          !firstSearchComplete &&
          _formValue &&
          !_.isEmpty(resultList) &&
          _.get(resultList, '0.id') === _formValue;
        if (finedDefaultValueInList) {
          handleChange(null, resultList[0]);
        } else if (
          mapValue &&
          !firstSearchComplete &&
          _formValue &&
          !_.isEmpty(resultList)
        ) {
          finedDefaultValueInList = _.find(resultList, (o) => {
            const a = mapValue(o, _formValue);
            return a;
          });
          if (finedDefaultValueInList) {
            handleChange(finedDefaultValueInList.id, finedDefaultValueInList);
          }
        }
        setList(resultList);
      } catch (e) {
        setList([]);
        errorLogger(e);
      }
      setLoading(false);
      setFirstSearchComplete(true);
    }
  }, [
    service,
    filters,
    query,
    searchEmpty,
    _formValue,
    firstSearchComplete,
    searchOnlyTime,
    errorLogger,
    handleChange,
    take
  ]);
  useEffect(() => {
    if (getData) getData();
  }, [getData]);
  const filterOptions = useMemo(() => {
    if (_filterOptions) return _filterOptions;
    if (searchOnlyTime)
      return (options) =>
        _.filter(
          options,
          (o) =>
            o.description.toUpperCase().indexOf(inputValue.toUpperCase()) >= 0
        );
    if (service) return (options) => options;
    return null;
  }, [service, _filterOptions, searchOnlyTime, inputValue]);
  return (
    <FormControl sx={{ width: '100%' }} error={!!errorMessage} size={size}>
      <Autocomplete
        disabled={disabled}
        options={list}
        getOptionLabel={(option) => option.description}
        filterOptions={filterOptions}
        noOptionsText={
          !firstSearchComplete
            ? translate('CORE.AUTOCOMPLETE_TEXT_NO_SEACH')
            : translate('CORE.AUTOCOMPLETE_TEXT_NO_OPTION')
        }
        {...autocompleProps}
        onChange={handleChange}
        // onInputChange={debouncedChangeHandler}
        value={autoCompleteValue}
        inputValue={inputValue}
        onInputChange={handleChangeInput}
        renderInput={(listRender) => (
          <TextField
            {...props}
            {...listRender}
            variant={variant}
            label={label}
            size={size}
            InputProps={{
              ...listRender.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {loading && <CircularProgress size="1rem" />}
                  {listRender.InputProps.endAdornment}
                </InputAdornment>
              )
            }}
          />
        )}
      />
      <FormHelperError errorMessage={errorMessage} helperText={helperText} />
    </FormControl>
  );
}
AutocompleteInput.propTypes = {
  autoLoadService: PropTypes.bool,
  autocompleProps: PropTypes.object,
  debounceTime: PropTypes.number,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string, //* Etiqueta
  filterOptions: PropTypes.any,
  filters: PropTypes.any,
  helperText: PropTypes.string,
  label: PropTypes.string,
  list: PropTypes.array,
  onChange: PropTypes.func,
  onChangeInput: PropTypes.func,
  searchEmpty: PropTypes.bool,
  searchOnlyTime: PropTypes.bool,
  service: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'string']),
  take: PropTypes.number,
  value: PropTypes.any,
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  mapValue: PropTypes.func
};

AutocompleteInput.defaultProps = {
  errorMessage: null,
  label: null,
  variant: process.env.REACT_APP_STYLED_DEFAULT_INPUT_VARIANT || 'outlined',
  size: process.env.REACT_APP_STYLED_DEFAULT_INPUT_SIZE || 'small',
  helperText: null,
  disabled: false
};

export default AutocompleteInput;
