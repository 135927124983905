import _ from 'lodash';
import useLazyQuery from 'modules/core/hooks/useLazyQuery/useLazyQuery';
import { useCallback } from 'react';
import queries from './gql/getUsers';

/**
 * Hook creado para el llamado a Graphql de servicios de persons
 * @returns
 */
function useGetPersonListTable() {
  /** Servicios para obtener los usuario para la visa de la tabla */
  const [queryGetPersons] = useLazyQuery({
    query: queries.GET_PERSONS_TO_TABLE,
    options: { fetchPolicy: 'network-only' }
  });
  const getPersonListTable = useCallback(
    async ({ filters }) => {
      let aux = {
        rows: [],
        count: 0
      };
      if (queryGetPersons) {
        const result = await queryGetPersons({ variables: { filters } });
        aux = _.get(result, 'data.getReportScoring', []);

        const person = _.map(aux, (s) => ({
          id: _.get(s, 'id'),
          apellido: _.get(s, 'apellido'),
          nombre: _.get(s, 'nombre'),
          dni: _.get(s, 'dni'),
          motivo: _.get(s, 'motivo'),
          fecha: _.get(s, 'fecha'),
          provinciA_ID: _.get(s, 'provinciA_ID'),
          localidaD_ID: _.get(s, 'localidaD_ID'),
          juzgadO_ID: _.get(s, 'juzgadO_ID'),
          provinciA_NOMBRE: _.get(s, 'provinciA_NOMBRE'),
          localidaD_NOMBRE: _.get(s, 'localidaD_NOMBRE'),
          juzgadO_NOMBRE: _.get(s, 'juzgadO_NOMBRE'),
          expediente: _.get(s, 'expediente'),
          puntos: _.get(s, 'puntos'),
          inhabilitado: _.get(s, 'inhabilitado'),
          type: _.get(s, 'type')
        }));
        return {
          rows: person,
          count: aux.length
        };
      }
      return {
        rows: aux,
        count: aux.length
      };
    },
    [queryGetPersons]
  );
  return {
    getPersonListTable
  };
}

export default useGetPersonListTable;
