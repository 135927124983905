import _ from 'lodash';
import useMutation from 'modules/core/hooks/useMutation/useMutation';
import { useCallback } from 'react';
import * as mutations from './gql/mutationScoring';

/**
 * Hook creado para llamar al server y crear un Scoring
 * @returns
 */
function useAddScoring() {
  const [mutationAdd] = useMutation({
    mutation: mutations.ADD_SCORING
  });

  const addScoring = useCallback(
    async (params, config) => {
      if (mutationAdd) {
        const result = await mutationAdd({ variables: params }, config);
        const aux = _.get(result, 'data.addScoring', null);
        return aux;
      }
      return null;
    },
    [mutationAdd]
  );
  return {
    addScoring
  };
}

export default useAddScoring;
