/**
 * Helper para hacer calculos de textos
 * @returns
 */
const useTexts = () => {
  /**
   * Calcula las iniciales de un nombre
   */
  const calculateInitials = (name) => {
    let result = '';
    if (name === undefined || name === null) {
      result = '?';
    } else {
      let newText = name.replace(/[^a-zA-Z ]/g, '');
      newText = newText.trim().split(' ').slice(0, 3).join(' ');
      const newInitials = newText.split(' ');
      if (newInitials.length === 1) {
        result = newInitials[0].slice(0, 1).toUpperCase();
      }
      if (newInitials.length === 2) {
        result =
          newInitials[0].slice(0, 1).toUpperCase() +
          newInitials[1].slice(0, 1).toUpperCase();
      }
      if (newInitials.length <= 3) {
        result =
          newInitials[0].slice(0, 1).toUpperCase() +
          newInitials[newInitials.length - 1].slice(0, 1).toUpperCase();
      }
    }
    return result;
  };

  return {
    calculateInitials
  };
};
export default useTexts;
