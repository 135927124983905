import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import Menu from '../../Menu';
import ButtonAvatar from '../ButtonAvatar/ButtonAvatar';
/**
 *
 * Componente que muestra un menu de opciones
 * @returns
 */
function ButtonAvatarMenu({
  title,
  children,
  name,
  avatar,
  color,
  variant = 'small',
  onClick,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  /** aca guardamos un elemento html para luego saber la posicion done va a aparecer el menu */
  const handleClick = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);
  /** funcinon para cerrar el menu */
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  return (
    <>
      <ButtonAvatar
        title={title}
        onClick={handleClick}
        name={name}
        variant={variant}
        avatar={avatar}
        color={color}
        {...props}
      />
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        open={open}
      >
        {children}
      </Menu>
    </>
  );
}

ButtonAvatarMenu.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  title: PropTypes.string,
  name: PropTypes.string,
  avatar: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['small', 'normal', 'xxl', 'xxxl'])
};
export default ButtonAvatarMenu;
