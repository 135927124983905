import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import React from 'react';
import classesModule from './SectionTitleForm.module.scss';

/**
 * Template para secciones de foprmulario
 * @param {*} param0
 * @returns
 */
function SectionTitleForm({ children, title, spacing = 2 }) {
  return (
    <div className={` ${classesModule.SectionTitleForm} `}>
      {/* TODO: remplazar por un componente de titulo */}
      <h1>{title}</h1>
      <Grid container spacing={spacing}>
        {children}
      </Grid>
    </div>
  );
}

SectionTitleForm.propTypes = {
  children: PropTypes.node,
  spacing: PropTypes.number,
  title: PropTypes.any
};
export default SectionTitleForm;
