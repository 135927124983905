import { useInterpret, useMachine as useMachineXstate } from '@xstate/react';
import PropTypes from 'prop-types';
// import _ from 'lodash';
import React, { useMemo } from 'react';
import MachineContext from '.';

/**
 * Provier para el manejo de Maquina de estado
 * @param {*} param0
 * @returns
 */
function MachineProvider({
  guards,
  machine,
  steps,
  children,
  stepper,
  actions,
  services
}) {
  const [state, send] = useMachineXstate(machine, {
    // devTools: _.get(process, 'env.REACT_APP_DEV_DEBUG_XSTATE', false)
    guards,
    actions,
    services
  });
  const machineService = useInterpret(machine);

  const value = useMemo(
    () => ({ machineService, steps, stepper, state, send }),
    [machineService, steps, stepper, state, send]
  );
  return (
    <MachineContext.Provider value={value}>{children}</MachineContext.Provider>
  );
}

MachineProvider.propTypes = {
  actions: PropTypes.any,
  children: PropTypes.any.isRequired,
  guards: PropTypes.any,
  machine: PropTypes.any.isRequired,
  services: PropTypes.any,
  stepper: PropTypes.array,
  steps: PropTypes.any
};

export default MachineProvider;
