import _ from 'lodash';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useMutation from 'modules/core/hooks/useMutation/useMutation';
import { useCallback } from 'react';
import mutations from './gql/mutationJuzgados';
/**
 * Hook creado para el llamado de servicios que borra juzgados
 * @returns
 */
function useSyncJuzgados() {
  const { translate } = useDecorator();
  const [mutationSync] = useMutation({
    mutation: mutations.SYNC_JUZGADOS
  });
  const syncJuzgados = useCallback(
    async (values) => {
      if (mutationSync) {
        const result = await mutationSync(
          { variables: values },
          {
            success: {
              message: translate(
                'ADMIN.SNAKBAR_MESSAGE_SYNC_JUZGADOS_SUCCESS_MUTATION'
              )
            }
          }
        );
        const aux = _.get(result, 'data.syncJuzgados', null);
        return aux;
      }
      return null;
    },
    [mutationSync, translate]
  );
  return {
    syncJuzgados
  };
}

export default useSyncJuzgados;
