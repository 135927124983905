/* eslint-disable react/jsx-no-constructed-context-values */
import PropTypes from 'prop-types';
import mustache from 'mustache';
import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { setLocale } from 'yup';
import DecoratorContext from '.';

/**
 * Provaider para el uso de decoradore
 * @param {*} param0
 * @returns
 */
function DecoratorProvider({
  children,
  translations: translationsProps,
  defaultLang: defaultLangProp = 'es',
  defaultDateFormat: defaultDateFormatProp = 'DD/MM/YYYY',
  defaultDateTimeFormat: defaultDateTimeFormatProp = 'DD/MM/AAAA',
  defaultTimeFormat: defaultTimeFormatProp = 'DD/MM/AAAA',
  lang: langProp
}) {
  const [translations] = useState(translationsProps);
  const [lang, setLang] = useState(langProp);

  const [defaultLang] = useState(defaultLangProp);
  const [defaultDateFormat] = useState(defaultDateFormatProp);
  const [defaultDateTimeFormat] = useState(defaultDateTimeFormatProp);
  const [defaultTimeFormat] = useState(defaultTimeFormatProp);

  /**
   * funcion para traducciones
   * @key: clave a traducir
   * @params: parametros a usar dentro de la traducción
   * @defaultKey: clave alternatica a utilizar
   */
  const translate = useCallback(
    (key, _params, defaultKey) => {
      let solution = null;
      const params = _params || {};
      const currentLang = lang || defaultLang;
      if (key) {
        const label = _.get(
          translations,
          `${currentLang}.${key}`,
          translate(defaultKey, params)
        );
        if (!label) {
          solution = key;
        } else if (label && params) {
          solution = mustache.render(label, params);
        } else {
          solution = label;
        }
      }
      return solution;
    },
    [defaultLang, lang, translations]
  );
  const formatDate = useCallback((value, config) => {
    if (!value) {
      return '';
    }
    const format = _.get(config, 'format');
    const solution = moment(value).format(format);
    return solution;
  }, []);
  const transfomNumber = useCallback((value) => {
    if (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    return null;
  }, []);
  const transfomDate = useCallback(
    (value, config = {}) => {
      const format = _.get(config, 'format', defaultDateFormat);
      return formatDate(value, { ...config, format });
    },
    [defaultDateFormat, formatDate]
  );
  const transfomDateTime = useCallback(
    (value, config = {}) => {
      const format = _.get(config, 'format', defaultDateTimeFormat);
      return formatDate(value, { ...config, format });
    },
    [defaultDateTimeFormat, formatDate]
  );
  const transfomTime = useCallback(
    (value, config = {}) => {
      const format = _.get(config, 'format', defaultTimeFormat);
      return formatDate(value, { ...config, format });
    },
    [defaultTimeFormat, formatDate]
  );
  useEffect(() => {
    if (translate) {
      setLocale({
        mixed: {
          default: 'Não é válido',
          required: 'Não é válido XXXX'
        },
        number: {
          // eslint-disable-next-line no-template-curly-in-string
          min: 'Deve ser maior que ${min}'
        }
      });
    }
  }, [translate]);
  return (
    <DecoratorContext.Provider
      value={{
        translate,
        setLang,
        transfomDate,
        transfomTime,
        transfomDateTime,
        transfomNumber,
        dateFormat: defaultDateFormat
      }}
    >
      {children}
    </DecoratorContext.Provider>
  );
}

DecoratorProvider.propTypes = {
  children: PropTypes.node,
  defaultDateFormat: PropTypes.string,
  defaultDateTimeFormat: PropTypes.string,
  defaultLang: PropTypes.string,
  defaultTimeFormat: PropTypes.string,
  lang: PropTypes.any.isRequired,
  translations: PropTypes.any
};

export default DecoratorProvider;
