import _ from 'lodash';
import useLazyQuery from 'modules/core/hooks/useLazyQuery/useLazyQuery';
import { useCallback } from 'react';
import useValue from 'modules/core/hooks/useValue';
import queries from './gql/getMotivos';
/**
 * Hook creado para el llamado a Graphql de servicios de lista de motivos
 * @returns
 */
function useGetMotivosList({ filter: _filter, person }) {
  const { getValueList } = useValue();
  const [queryGetMotivos] = useLazyQuery({
    query: queries.GET_MOTIVOS_TO_LIST,
    options: { fetchPolicy: 'network-only' }
  });
  const getMotivosList = useCallback(async () => {
    let aux = [];
    if (queryGetMotivos) {
      const result = await queryGetMotivos();
      aux = _.get(result, 'data.getMotivos', []);
      let listResult = getValueList(aux, { description: 'nombre' });
      if (_filter) {
        listResult = _.filter(
          listResult,
          (element) =>
            (_filter === 'positive' &&
              element.value.puntos >= 0 &&
              element.value.puntos !== null &&
              20 - person.puntos >= element.value.puntos) ||
            (_filter === 'negative' && element.value.puntos <= 0)
        );
      }
      return listResult;
    }
    return aux;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryGetMotivos), getValueList]);

  return {
    getMotivosList
  };
}

export default useGetMotivosList;
