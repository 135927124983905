import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function GroupControllerInput({
  name,
  as,
  onChange,
  components = [],
  ...props
}) {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name
  });

  return <>{as({ name, components, ...props, fields, append, remove })}</>;
}
GroupControllerInput.propTypes = {
  name: PropTypes.string,
  as: PropTypes.elementType,
  onChange: PropTypes.func,
  components: PropTypes.array
};
GroupControllerInput.defaultProps = {
  components: []
};
export default GroupControllerInput;
