import React from 'react';
import classesModule from './LogoutView.module.scss';
/**
 * Logout view
 * @param {*} param0
 * @returns
 */
function LogoutView() {
  return <div className={` ${classesModule.LogoutView} `}>Logout</div>;
}
export default LogoutView;
