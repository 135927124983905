import React from 'react';
import classesModule from './FaqView.module.scss';

/**
 * Faq View
 * @returns
 */
function FaqView() {
  return <div className={` ${classesModule.FaqView} `}>Faq</div>;
}
export default FaqView;
