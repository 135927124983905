import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import { useResizeObserver } from 'beautiful-react-hooks';
import classnames from 'classnames';
import useNavigate from 'modules/core/hooks/useNavigate';
import classesModule from './Scrollbar.module.scss';

/**
 * Scrollbar componente
 * @param {*} param0
 * @returns
 */
function Scrollbar({
  children,
  className,
  id,
  scrollBottom = false,
  overflowX = true,
  overflowY = true
}) {
  const { location } = useNavigate();
  const scrollEndRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const DOMRect = useResizeObserver(scrollEndRef);
  let contentHeight = 0;

  /**
   * TODO: Componente
   * @param {*} param0
   * @returns
   */
  const scrollToBottom = () => {
    if (DOMRect?.height !== contentHeight) {
      const scroll =
        scrollContainerRef.current.scrollHeight -
        scrollContainerRef.current.clientHeight;
      scrollContainerRef.current.scrollTo({
        top: scroll,
        behavior: 'smooth'
      });
      contentHeight = DOMRect?.height;
    }
  };

  useEffect(() => {
    if (scrollBottom) scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DOMRect?.height, contentHeight]);

  useEffect(() => {
    if (scrollContainerRef?.current?.scrollTop) {
      scrollContainerRef?.current?.scrollTo({
        top: 0
      });
    }
  }, [location?.pathname, scrollContainerRef]);

  return (
    <div
      className={classnames(`${classesModule.Scrollbar} ${className}`, {
        [classesModule['overflow-y']]: overflowY,
        [classesModule['overflow-x']]: overflowX
      })}
      id={id}
      ref={scrollContainerRef}
    >
      {scrollBottom && <div ref={scrollEndRef}>{children}</div>}
      {!scrollBottom && children}
    </div>
  );
}

Scrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.any,
  id: PropTypes.any,
  overflowX: PropTypes.bool,
  overflowY: PropTypes.bool,
  scrollBottom: PropTypes.bool
};
export default Scrollbar;
