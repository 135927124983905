import React from 'react';
import PropTypes from 'prop-types';
import MenuMui from '@mui/material/Menu';

// import classesModule from './Menu.module.scss';

/**
 * Componente de menu
 * @returns
 */
function Menu({ anchorEl, onClose, onClick, open, children, ...props }) {
  return (
    <MenuMui
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      onClick={onClick}
      {...props}
    >
      {children}
    </MenuMui>
  );
}

Menu.propTypes = {
  anchorEl: PropTypes.any,
  children: PropTypes.node,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default Menu;
