import Button from 'modules/core/components/Buttons/Button';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useMachine from 'modules/core/contexts/MachineContext/useMachine';
import React, { useCallback } from 'react';
import { Stack } from '@mui/material';
import _ from 'lodash';
// import DocumentScoring from 'modules/sisco/components/DocumentScoring/DocumentScoring';
import DocumentScoring from 'modules/sisco/components/DocumentScoring/DocumentScoring';
import ConsultScoringUser from '../../../../ConsultScoringUser/ConsultScoringUser';

/**
 * Step para mostrar el resultado public de scoring
 * @param {*} param0
 * @returns
 */
function ResultScoringStep() {
  const { translate } = useDecorator();
  const { context, prev } = useMachine();
  const handlePrevStep = useCallback(() => {
    prev();
  }, [prev]);

  return (
    <div>
      <Stack direction="column">
        <DocumentScoring
          scoring={_.get(context, 'getScoringPersona.persona')}
        />
        <ConsultScoringUser
          list={_.get(context, 'getScoringPersona.scoring')}
        />
        <Stack direction="row" justifyContent="flex-end">
          <Button onClick={handlePrevStep}>
            {translate('AUTH.SCORING_USER_NEW')}
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}
export default ResultScoringStep;
