import { loadState, saveState } from 'modules/core/store/utils';
import { ACTIONS } from './useSessionStore';

const defaultInitialValues = {
  token: null,
  login: false
};
const initialData = loadState('session') || defaultInitialValues;
/**
 * Setea el loader de pagina
 * @param {*} state
 * @param {*} param1
 * @returns
 */
const getStoreAfterSetLoading = (state, { loading }) => {
  const newState = {
    ...state,
    loading
  };
  saveState('session', newState);
  return newState;
};
/**
 * Genera un nuevo storage
 * @param {*} state
 * @param {*} param1
 * @returns
 */
const getStoreAfterSetToken = (state, { token }) => {
  const newState = {
    ...state,
    token
  };
  saveState('session', newState);
  return newState;
};
/**
 * Setea en redux los datos de la session del getMe
 * @param {*} state
 * @param {*} param1
 * @returns
 */
const getStoreAfterSetMeData = (state, meData) => {
  const newState = {
    ...state,
    user: meData
  };
  saveState('session', newState);
  return newState;
};
/**
 * Limpia toda los datos del storage por que se hizo logout
 * @param {*} state
 * @param {*} meData
 * @returns
 */
const getStoreAfterLogout = () => {
  const newState = {};
  saveState('session', newState);
  return newState;
};
/**
 * Setea en redux los datos de la session del setAccess
 * @param {*} state
 * @param {*} param1
 * @returns
 */
const getStoreAfterSetAccess = (state, access) => {
  const newState = {
    ...state,
    access
  };
  saveState('session', newState);
  return newState;
};
export default (state = initialData, action = {}) => {
  switch (action.type) {
    case ACTIONS.SET_LOADING_PAGE:
      return getStoreAfterSetLoading(state, action.payload);
    case ACTIONS.SET_TOKEN:
      return getStoreAfterSetToken(state, action.payload);
    case ACTIONS.SET_ME_DATA:
      return getStoreAfterSetMeData(state, action.payload);
    case ACTIONS.LOGOUT:
      return getStoreAfterLogout(state, action.payload);
    case ACTIONS.SET_ACCESS:
      return getStoreAfterSetAccess(state, action.payload);
    default:
      return state;
  }
};
