import _ from 'lodash';
import useLazyQuery from 'modules/core/hooks/useLazyQuery/useLazyQuery';
import { useCallback } from 'react';
import queries from './gql/getMotivos';
/**
 * Hook creado para el llamado a Graphql de obtener un motivo por ud
 * @returns
 */
function useGetMotivoById() {
  const [queryGetMotivo] = useLazyQuery({
    query: queries.GET_MOTIVO_BY_ID,
    options: { fetchPolicy: 'network-only' }
  });
  const getMotivo = useCallback(
    async (values) => {
      if (queryGetMotivo) {
        const result = await queryGetMotivo({ variables: values });
        return _.get(result, 'data.getMotivo', null);
      }
      return null;
    },
    [queryGetMotivo]
  );
  return {
    getMotivo
  };
}

export default useGetMotivoById;
