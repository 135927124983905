import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Breadcrumbs as BreadcrumbsMaterial } from '@mui/material';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useNavigate from 'modules/core/hooks/useNavigate';
import Link from '../Link';
import Typography from '../Typographies/Typography';
/**
 * Componente de Breadcrumbs
 * @param {*} param0
 * @returns
 */
function Breadcrumbs({ links = [] }) {
  const { translate } = useDecorator();
  const { params } = useNavigate();
  return (
    <BreadcrumbsMaterial>
      {_.map(links, (link, index) => {
        if (index < links.length - 1) {
          return (
            <Link key={index} underline="hover" color="inherit" {...link} />
          );
        }

        if (typeof link.children === 'string') {
          let paramsTranslate = params;
          if (link.paramsTransform) {
            paramsTranslate = link.paramsTransform(params);
          }
          return (
            <Typography key={index} color="text.primary">
              {translate(link.children, paramsTranslate)}
            </Typography>
          );
        }
        return link.children;
      })}
    </BreadcrumbsMaterial>
  );
}

Breadcrumbs.propTypes = {
  links: PropTypes.array
};
export default Breadcrumbs;
