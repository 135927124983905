import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import { useImperativeHandle, useMemo, useRef } from 'react';
import {
  getGridSingleSelectOperators,
  getGridDateOperators
} from '@mui/x-data-grid-pro';
import moment from 'moment';
import LICENSE from 'modules/sisco/constants/lisence';
import { Box } from '@mui/material';
import MotivoAutocompleteInput from 'modules/admin/components/forms/MotivoAutocompleteInput';
/**
 *
 * @param {*} props
 * @returns
 */
function AutocompleteFilterInputValue(props) {
  const { item, applyValue, focusElementRef } = props;
  const ratingRef = useRef(null);
  useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      if (ratingRef && ratingRef.current) {
        ratingRef.current
          .querySelector(`input[value="${Number(item.value) || ''}"]`)
          .focus();
      }
    }
  }));
  /**
   *
   * @param {*} event
   * @param {*} newValue
   */
  const handleFilterChange = (newValue, obj) => {
    if (newValue) {
      applyValue({ ...item, value: obj?.idExterno });
    }
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        pl: '20px'
      }}
    >
      <MotivoAutocompleteInput
        onChange={handleFilterChange}
        ref={ratingRef}
        value={item.value}
        mapValue={(m, value) => m?.value?.idExterno === value}
      />
    </Box>
  );
}
/**
 * Hook creado para tener sepado del componente de react la definicion y logica de la grilla
 */
function useDataGridAttributes({
  juzgados,
  localidades,
  provincias,
  type,
  statusLicense
}) {
  const { translate } = useDecorator();
  const defaultOrder = [{ field: 'id', sort: 'desc' }];
  const defaultPageSize = 10;
  const defaultFilters = useMemo(
    () => [
      {
        id: 1,
        columnField: 'fecha',
        operatorValue: 'after',
        value: moment().subtract(1, 'month').format('YYYY-MM-DD')
      }
    ],
    []
  );
  const columns = useMemo(
    () => [
      {
        field: 'apellido',
        headerName: translate('ADMIN.GRID_USER_TITLE_APELLIDO'),
        filterable: false,
        sortable: true,
        flex: 1
      },
      {
        field: 'nombre',
        headerName: translate('ADMIN.GRID_USER_TITLE_NOMBRE'),
        filterable: false,
        sortable: true,
        flex: 1
      },
      {
        field: 'dni',
        headerName: translate('ADMIN.GRID_PERSON_TITLE_DNI'),
        filterable: false,
        sortable: true,
        flex: 1
      },
      {
        field: 'motivo',
        headerName: translate('ADMIN.GRID_PERSON_TITLE_MOTIVE'),
        sortable: true,
        filterable: true,
        flex: 2,
        type: 'singleSelect',
        filterOperators: getGridSingleSelectOperators()
          .filter(({ value }) => ['is'].includes(value))
          .map((operator) => ({
            ...operator,
            InputComponent: operator.InputComponent
              ? AutocompleteFilterInputValue
              : undefined
          }))
      },
      {
        field: 'fecha',
        headerName: translate('ADMIN.GRID_PERSON_TITLE_DATE'),
        filterable: true,
        sortable: true,
        type: 'date',
        flex: 1,
        filterOperators: getGridDateOperators().filter(({ value }) =>
          ['after', 'before'].includes(value)
        ),
        renderCell: (v) => <>{moment(v?.row?.fecha).format('DD/MM/YYYY')}</>
      },
      {
        field: 'provinciA_NOMBRE',
        headerName: translate('ADMIN.GRID_PERSON_TITLE_PROV'),
        filterable: true,
        sortable: true,
        flex: 1,
        type: 'singleSelect',
        valueOptions: provincias,
        filterOperators: getGridSingleSelectOperators().filter(({ value }) =>
          ['is'].includes(value)
        )
      },
      {
        field: 'localidaD_NOMBRE',
        headerName: translate('ADMIN.GRID_PERSON_TITLE_LOCAL'),
        filterable: true,
        sortable: true,
        flex: 1,
        type: 'singleSelect',
        filterOperators: getGridSingleSelectOperators().filter(({ value }) =>
          ['is'].includes(value)
        ),
        valueOptions: localidades
      },
      {
        field: 'juzgadO_NOMBRE',
        headerName: translate('ADMIN.GRID_PERSON_TITLE_JUZG'),
        filterable: true,
        sortable: true,
        flex: 1,
        type: 'singleSelect',
        filterOperators: getGridSingleSelectOperators().filter(({ value }) =>
          ['is'].includes(value)
        ),
        valueOptions: juzgados
      },
      {
        field: 'expediente',
        headerName: translate('ADMIN.GRID_PERSON_TITLE_RECORD'),
        filterable: false,
        sortable: true,
        flex: 1
      },
      {
        field: 'puntos',
        headerName: translate('ADMIN.GRID_PERSON_TITLE_POINTS'),
        filterable: false,
        sortable: true,
        flex: 0.5,
        valueGetter: (params) => {
          if (params?.row?.type > 0) {
            return params?.row?.puntos;
          }
          return (params?.row?.puntos ?? 0) * -1;
        }
      },
      {
        field: 'type',
        headerName: translate('ADMIN.GRID_PERSON_TITLE_TYPE'),
        filterable: true,
        sortable: true,
        hide: true,
        type: 'singleSelect',
        valueOptions: type,
        filterOperators: getGridSingleSelectOperators().filter(({ value }) =>
          ['is'].includes(value)
        )
      },
      {
        field: 'inhabilitado',
        headerName: translate('ADMIN.GRID_USER_TITLE_STATUS_LICENSE'),
        filterable: true,
        sortable: true,
        hide: true,
        valueOptions: statusLicense,
        type: 'singleSelect',
        filterOperators: getGridSingleSelectOperators().filter(({ value }) =>
          ['is'].includes(value)
        ),
        valueGetter: (params) => {
          if (params?.row?.inhabilitado === LICENSE.NO) {
            return translate('ADMIN.LICENSE_ENABLED');
          }
          if (params?.row?.inhabilitado === LICENSE.SI) {
            return translate('ADMIN.LICENSE_DISABLED');
          }
          return '';
        }
      }
    ],
    [juzgados, localidades, provincias, statusLicense, translate, type]
  );
  return { columns, defaultFilters, defaultOrder, defaultPageSize };
}

export default useDataGridAttributes;

// ADMIN.GRID_USERVIEW_TITLE_ID
