import WizardTemplate from 'modules/core/components/Templates/WizardTemplate';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useMachine from 'modules/core/contexts/MachineContext/useMachine';
import ConsultScoringForm from 'modules/public/forms/ConsultScoringForm';
import React, { useCallback, useMemo } from 'react';

/**
 * Step para consultar los datos de la persona a ver el scoring
 * @param {*} param0
 * @returns
 */
function SelectPersonStep() {
  const { next } = useMachine();

  const { translate } = useDecorator();

  const handleSubmit = useCallback(
    async (params) => {
      next(params);
    },
    [next]
  );

  const components = useMemo(() => {
    if (translate) {
      return {
        next: {
          children: translate('AUTH.WIZARD_SCORING_BUTTON_STEP1_NEXT')
        },
        prev: null
      };
    }
    return {};
  }, [translate]);

  return (
    <ConsultScoringForm
      template={WizardTemplate}
      onSubmit={handleSubmit}
      templateProps={{ components }}
    />
  );
}
export default SelectPersonStep;
