/* eslint-disable react/no-array-index-key */
import _ from 'lodash';
import useGetJuzgadosList from 'modules/admin/services/useJuzgadosServices/useGetJuzgadosList';
import { AutocompleteInput } from 'modules/core/components/Forms/Inputs';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import ContentJuzgadosAutoComplete from './ContentJuzgadosAutoComplete';
/**
 * Componente que busca y trae los motivos
 * @param {*} param0
 * @returns
 */
function JuzgadoAutocompleteInput({
  children,
  label,
  helperText = null,
  filter,
  onChange,
  ...props
}) {
  const { translate } = useDecorator();
  const defaultLabel = useMemo(
    () => (translate ? translate('ADMIN.LABEL_MOTIVO') : ''),
    [translate]
  );
  const { getJuzgadosList } = useGetJuzgadosList();
  const handleOnChange = useCallback(
    (id, obj) => {
      if (onChange) onChange(id, obj);
    },
    [onChange]
  );
  return (
    <AutocompleteInput
      label={label || defaultLabel}
      {...props}
      service={getJuzgadosList}
      helperText={helperText}
      onChange={handleOnChange}
      searchEmpty
      searchOnlyTime
      autocompleProps={{
        filterOptions: (options, { inputValue }) =>
          options
            .filter((op) => {
              const reg = [
                _.get(op, 'value.nombre', ''),
                _.get(op, 'value.provincia', ''),
                _.get(op, 'value.localidad', '')
              ]
                .join(' ')
                .toLowerCase()
                .split(' ');
              return inputValue
                .toLowerCase()
                .split(' ')
                .every((v) => reg.some((r) => r.includes(v)));
            })
            .slice(0, 200),
        renderOption: (propsRender, option, { inputValue }) => (
          <ContentJuzgadosAutoComplete
            key={`${_.get(option, 'value.id')}-${_.get(option, 'value.name')}`}
            propsRender={propsRender}
            option={option}
            inputValue={inputValue}
          />
        )
      }}
    />
  );
}

JuzgadoAutocompleteInput.propTypes = {
  children: PropTypes.node,
  filter: PropTypes.any,
  helperText: PropTypes.string,
  label: PropTypes.any,
  onChange: PropTypes.any
};
export default JuzgadoAutocompleteInput;
