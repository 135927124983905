import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import { useMemo } from 'react';

/**
 * Hook creado para tener sepado del componente de react la definicion y logica de la grilla
 */
function useDataGridAttributes() {
  const { translate, transfomDate } = useDecorator();
  const defaultOrder = [{ field: 'id', sort: 'desc' }];
  const defaultPageSize = 10;

  const defaultFilters = useMemo(() => [], []);
  const columns = useMemo(
    () => [
      {
        field: 'motivo',
        headerName: translate('AUTH.GRID_SCORING_TITLE_MOTIVO'),
        filterable: true,
        sortable: true,
        flex: 4
      },
      {
        field: 'fechaSentencia',
        headerName: translate('AUTH.GRID_SCORING_TITLE_FECHA_SENTENCIA'),
        filterable: true,
        sortable: true,
        flex: 1,
        valueFormatter: (field) => transfomDate(field.value)
      },
      {
        field: 'provincia',
        headerName: translate('AUTH.GRID_SCORING_TITLE_PROVINCIA'),
        filterable: true,
        sortable: true,
        flex: 1
      },
      {
        field: 'localidad',
        headerName: translate('AUTH.GRID_SCORING_TITLE_LOCALIDAD'),
        filterable: true,
        sortable: true,
        flex: 1
      },
      {
        field: 'juzgado',
        headerName: translate('AUTH.GRID_SCORING_TITLE_JUZGADO'),
        filterable: true,
        sortable: true,
        flex: 1
      },
      {
        field: 'expediente',
        headerName: translate('AUTH.GRID_SCORING_TITLE_EXPEDIENTE'),
        filterable: true,
        sortable: true,
        flex: 1
      },
      {
        field: 'puntos',
        headerName: translate('AUTH.GRID_SCORING_TITLE_PUNTOS'),
        filterable: true,
        sortable: true,
        flex: 1,
        align: 'right'
      }
    ],
    [translate, transfomDate]
  );
  return { columns, defaultFilters, defaultOrder, defaultPageSize };
}

export default useDataGridAttributes;
