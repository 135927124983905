import React from 'react';
import DecoratorContext from '.';

/**
 * Hooks para el uso de decoradores sobre los textos y/o datos
 * @returns
 */
function useDecorator() {
  const {
    translate,
    setLang,
    transfomDate,
    transfomTime,
    transfomDateTime,
    dateFormat,
    transfomNumber
  } = React.useContext(DecoratorContext);
  return {
    translate,
    setLang,
    transfomDate,
    transfomTime,
    transfomDateTime,
    dateFormat,
    transfomNumber
  };
}

export default useDecorator;
