import React, { useCallback, useMemo } from 'react';
import useMachine from 'modules/core/contexts/MachineContext/useMachine';
import WizardTemplate from 'modules/core/components/Templates/WizardTemplate';
import Typograpgy from 'modules/core/components/Typographies/Typography';
import ConsultScoringForm from '../../../../../public/forms/ConsultScoringForm/ConsultScoringForm';
/**
 * Step para consultas de scoring
 * @param {*} param0
 * @returns
 */
function ScoringFormStep() {
  const { next } = useMachine();
  const components = useMemo(
    () => ({
      prev: null
    }),
    []
  );
  const handleSubmit = useCallback(
    (values) => {
      next(values);
    },
    [next]
  );
  return (
    <>
      {/* //TODO: Pasar traducion */}
      <Typograpgy>Ingresá los datos de la persona</Typograpgy>
      <ConsultScoringForm
        template={WizardTemplate}
        onSubmit={handleSubmit}
        templateProps={{ components }}
      />
    </>
  );
}
export default ScoringFormStep;
