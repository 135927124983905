import { ACTIONS } from './useDataStore';

const defaultInitialValues = {};
const initialData = defaultInitialValues;
/**
 * Setea el loader de pagina
 * @param {*} state
 * @param {*} param1
 * @returns
 */
const getStoreAfterSetData = (state, data) => {
  const newState = {
    ...state,
    ...data
  };
  return newState;
};

export default (state = initialData, action = {}) => {
  switch (action.type) {
    case ACTIONS.SET_DATA:
      return getStoreAfterSetData(state, action.payload);
    default:
      return state;
  }
};
