import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import Scrollbar from '../Scrollbar';
import classesModule from './ScrollbarBox.module.scss';

/**
 *
 * @param {*} param0
 * @returns
 */
function ScrollbarBox({ children, className, style }) {
  return (
    <Box className={`${classesModule.container} ${className}`} style={style}>
      <Scrollbar>{children}</Scrollbar>
    </Box>
  );
}

ScrollbarBox.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  style: PropTypes.any
};
export default ScrollbarBox;
