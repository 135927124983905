/**
 * recupera los datos del local stororage segun una key
 * @param {string} key
 * @returns json data
 */
export const loadState = (key) => {
  try {
    const serializedData = localStorage.getItem(key);
    if (serializedData === null) {
      return undefined;
    }
    return JSON.parse(serializedData);
  } catch (error) {
    // console.log('Load Error', error);
    return undefined;
  }
};
/**
 * Graba una serie de datos en el localstorage
 * @param {string} key
 * @param {any} state
 */
export const saveState = (key, state) => {
  try {
    const serializedData = JSON.stringify(state);
    localStorage.setItem(key, serializedData);
  } catch (error) {
    // console.log('Save Error', error);
  }
};
/**
 * REcupera los datos del session storage
 * @param {string} key
 * @returns
 */
export const loadSessionState = (key) => {
  try {
    const serializedData = sessionStorage.getItem(key);
    if (serializedData === null) {
      return undefined;
    }
    return JSON.parse(serializedData);
  } catch (error) {
    // console.log('Load Error', error);
    return undefined;
  }
};
/**
 * Guarda los datos en el sessionStorage
 * @param {string} key
 * @param {*} state
 * @returns
 */
export const saveSessionState = (key, state) => {
  try {
    const serializedData = JSON.stringify(state);
    sessionStorage.setItem(key, serializedData);
    return undefined;
  } catch (error) {
    // console.log('Save Error', error);
    // throw error;
    return undefined;
  }
};
