import _ from 'lodash';
import SettingsProvider from 'modules/core/contexts/SettingsContext/SettingsProvider';
import PropTypes from 'prop-types';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import Application from './Application';

/**
 * Componente que engloba toda la configuración que va a utlizar la App a nivel global
 * @param {*} param0
 * @returns
 */
function AppManager({
  children,
  translations,
  theme,
  store,
  paths,
  requestGetErrorFunction: _requestGetErrorFunction
}) {
  /**
   * Funcion para saber como detectar un error en todos los request
   * @param {*} request
   * @param {*} config//No se pone el parametro en la funcion por no usar por el momento
   * @returns
   */
  const requestGetErrorFunction = (request) => _.get(request, 'error', null);
  /**
   * Funcion para saber como detectar un error en todos los request
   * @param {*} request
   * @param {*} config //No se pone el parametro en la funcion por no usar por el momento
   * @returns
   */
  const requestGetDataFunction = (request) => request;
  return (
    <SettingsProvider
      paths={paths}
      requestGetDataFunction={requestGetDataFunction}
      requestGetErrorFunction={
        _requestGetErrorFunction || requestGetErrorFunction
      }
    >
      <ReduxProvider store={store}>
        <Application theme={theme} translations={translations}>
          {children}
        </Application>
      </ReduxProvider>
    </SettingsProvider>
  );
}

AppManager.propTypes = {
  children: PropTypes.node,
  paths: PropTypes.any,
  requestGetErrorFunction: PropTypes.any,
  store: PropTypes.any,
  theme: PropTypes.any,
  translations: PropTypes.any
};
export default AppManager;
