import ButtonMaterial from '@mui/material/Button';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function Button({ children, variant, color, loading, disabled, ...props }) {
  return (
    <ButtonMaterial
      variant={variant}
      color={color}
      disabled={loading || disabled}
      {...props}
    >
      {children}
    </ButtonMaterial>
  );
}
Button.propTypes = {
  /** Property children */
  children: PropTypes.node,
  /** Variant style component */
  variant: PropTypes.oneOf(['contained', 'outlined', 'text', 'string']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'error',
    'info',
    'warning'
  ]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  children: null,
  variant: process.env.REACT_APP_STYLED_DEFAULT_BUTTON_VARIANT || 'contained',
  loading: false,
  disabled: false
};

export default Button;
