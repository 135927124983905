import PropTypes from 'prop-types';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import * as Yup from 'yup';
import Form, { ControllerInput } from 'modules/core/components/Forms/Form';
import { TextInput } from 'modules/core/components/Forms/Inputs';
import SectionTitleForm from 'modules/core/components/Templates/SectionTitleForm';
/**
 * Componente de formulario motivos
 * @returns
 */
function MotivosForm({ onSubmit, template, templateProps, defaultValues }) {
  const { translate } = useDecorator();
  const schema = Yup.object().shape({
    nombre: Yup.string()
      .nullable(translate('ADMIN.VALIDATION_MOTIVOS_NAME_REQUIRED'))
      .required(translate('ADMIN.VALIDATION_MOTIVOS_NAME_REQUIRED')),
    descripcion: Yup.string()
      .nullable(translate('ADMIN.VALIDATION_MOTIVOS_DESCRIPTION_REQUIRED'))
      .required(translate('ADMIN.VALIDATION_MOTIVOS_DESCRIPTION_REQUIRED')),
    idExterno: Yup.string()
      .nullable(translate('ADMIN.VALIDATION_MOTIVOS_IDEXTERNO_REQUIRED'))
      .required(translate('ADMIN.VALIDATION_MOTIVOS_IDEXTERNO_REQUIRED')),
    puntos: Yup.number()
      .nullable()
      .transform((val) => (!Number.isNaN(val) ? val : null))
  });

  return (
    <Form
      templateProps={{
        submitButtonProps: {
          children: translate('ADMIN.MOTIVOS_FORM_ADD_BUTTON', defaultValues)
        },
        ...templateProps
      }}
      schema={schema}
      onSubmit={onSubmit}
      template={template}
      defaultValues={defaultValues}
    >
      <SectionTitleForm>
        <ControllerInput
          render={TextInput}
          defaultValue=""
          name="nombre"
          label={translate('ADMIN.MOTIVOS_FORM_ADD_LABEL_NAME')}
          placeholder={translate('ADMIN.MOTIVOS_FORM_ADD_PLACEHOLDER_NAME')}
        />
        <ControllerInput
          render={TextInput}
          defaultValue=""
          name="descripcion"
          label={translate('ADMIN.MOTIVOS_FORM_ADD_LABEL_DESCRIPTION')}
          placeholder={translate(
            'ADMIN.MOTIVOS_FORM_ADD_PLACEHOLDER_DESCRIPTION'
          )}
        />
        <ControllerInput
          render={TextInput}
          defaultValue=""
          name="idExterno"
          label={translate('ADMIN.MOTIVOS_FORM_ADD_LABEL_ID_EXTERNO')}
          placeholder={translate(
            'ADMIN.MOTIVOS_FORM_ADD_PLACEHOLDER_ID_EXTERNO'
          )}
        />
        <ControllerInput
          render={TextInput}
          defaultValue=""
          name="puntos"
          type="number"
          label={translate('ADMIN.MOTIVOS_FORM_ADD_LABEL_SCORE')}
          placeholder={translate('ADMIN.MOTIVOS_FORM_ADD_PLACEHOLDER_SCORE')}
        />
      </SectionTitleForm>
    </Form>
  );
}

MotivosForm.propTypes = {
  defaultValues: PropTypes.any,
  onSubmit: PropTypes.func,
  template: PropTypes.any,
  templateProps: PropTypes.object
};
export default MotivosForm;
