// process.env.REACT_APP_RECAPTCHA_V3_PUBLIC_KEY;

import { useCallback, useEffect } from 'react';

/**
 * Hook con llamados para el uso de recaptcha 3 de google
 * @returns
 */
function useGoogleRecaptchaV3(
  apiKey,
  submit,
  cssString,
  tokenField = 'recaptchaToken'
) {
  useEffect(() => {
    let script = null;
    let style = null;
    if (apiKey) {
      script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${apiKey}`;
      script.async = true;

      style = document.createElement('style');
      style.type = 'text/css';
      const css = `.grecaptcha-badge {${cssString}}`;
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }

      document.body.appendChild(script);
      document.body.appendChild(style);
    }
    return () => {
      if (script) {
        document.body.removeChild(script);
        document.getElementsByClassName('grecaptcha-badge')[0].remove();
      }
      if (style) document.body.removeChild(style);
    };
  }, [apiKey, cssString]);
  const handleSubmit = useCallback(
    async (values) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(apiKey, {
            action: 'submit'
          })
          .then((recaptchaToken) => {
            submit({ [tokenField]: recaptchaToken, ...values });
          });
      });
    },
    [submit, apiKey, tokenField]
  );
  return { handleSubmit };
}

export default useGoogleRecaptchaV3;
