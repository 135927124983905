/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { blueGrey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';
import classesModule from './Avatar.module.scss';
import useColors from '../../hooks/useColors';
import useTexts from '../../hooks/useTexts';

/**
 * Material Avatar
 * @param {*} param0
 * @returns
 */
function Avatar({ name, variant, avatar, color }) {
  const { getMaterialBackgroundColor } = useColors();
  const { calculateInitials } = useTexts();
  const [initials, setInitials] = useState('');
  const [accentColor, setAccentColor] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [size, setSize] = useState('48px');
  const theme = useTheme();

  /**
   * Calcular el tamaño de la fuente
   */
  const calculateFontSize = (avatarSize) => {
    const valueCalc = avatarSize.replace('px', '');
    const fontSize = parseInt(valueCalc, 10) / 2;
    return `${fontSize.toString()}px`;
  };

  useEffect(() => {
    if (calculateInitials(name) !== '?') {
      setInitials(calculateInitials(name));
    }
  }, [name]);
  useEffect(() => {
    let useColor = color;
    if (color === 'primary') {
      useColor = theme.palette.primary.main;
    } else if (color === 'secondary') {
      useColor = theme.palette.secondary.main;
    }
    setAccentColor(useColor);
    const bgColor = getMaterialBackgroundColor(useColor);
    setBackgroundColor(bgColor);
  }, [color]);

  useEffect(() => {
    if (variant === 'small') {
      setSize('32px');
    }
    if (variant === 'normal') {
      setSize('48px');
    }
    if (variant === 'xxl') {
      setSize('64px');
    }
    if (variant === 'xxxl') {
      setSize('128px');
    }
  }, [variant]);

  return avatar ? (
    <div
      className={classesModule.avatarSpace}
      style={{ width: size, height: size }}
    >
      <img src={avatar} alt={name} style={{ width: size, height: size }} />
    </div>
  ) : backgroundColor != null && initials !== '' ? (
    <div
      className={classesModule.avatarSpace}
      style={{
        width: size,
        height: size,
        backgroundColor: `${backgroundColor}`
      }}
    >
      <span
        className={classesModule.textInitials}
        style={{
          color: `${accentColor}`,
          fontSize: calculateFontSize(size)
        }}
      >
        {initials}
      </span>
    </div>
  ) : (
    <div
      className={classesModule.avatarSpace}
      style={{
        width: size,
        height: size
      }}
    >
      <PersonIcon
        style={{
          color: 'white'
        }}
        fontSize="large"
      />
    </div>
  );
}

Avatar.propTypes = {
  name: PropTypes.string,
  avatar: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['small', 'normal', 'xxl', 'xxxl'])
};

Avatar.defaultProps = {
  name: null,
  variant: 'normal',
  avatar: null,
  color: blueGrey[500]
};
export default Avatar;
