export default {
  label_business_units: 'Unidad de negocio',
  HEADERAVATARBUTTON_ACCOUNT: 'Mi cuenta',
  HEADERAVATARBUTTON_CHANGE_PASSWORD: 'Cambiar contraseña',
  HEADERAVATARBUTTON_LOGOUT: 'Cerrar sesion',
  LABEL_TYPE_DOCUMENT: 'Tipo de documento',
  TYPE_DOCUMENT_LABEL_DNI: 'DNI - Documento Nacional de Identidad',
  TYPE_DOCUMENT_LABEL_LC: 'LC - Libreta Cívica',
  TYPE_DOCUMENT_LABEL_LE: 'LE - Libreta de Enrolamiento',
  TYPE_DOCUMENT_LABEL_PAS: 'PAS - Pasaporte',
  TYPE_DOCUMENT_LABEL_CI: 'CI - Cédula de Identidad',
  LABEL_SEX: 'Sexo que figura en el documento',
  SEX_LABEL_M: 'Masculino',
  SEX_LABEL_F: 'Femenino',
  SEX_LABEL_X: 'No Binario',
  MISSING_ERROR_404: 'Página no encontrada',
  MISSING_LINK_ERROR_404: 'Volver al inicio',
  MISSING_ERROR_403: 'Página restringida',
  MISSING_LINK_ERROR_403: 'Volver al inicio',
  FREQUENT_QUESTIONS: ' Preguntas Frecuentes',
  TERMS_AND_CONDITIONS: 'Terminos y Condiciones',
  META_TITLE: 'Scoring | Agencia Nacional de Seguridad Vial'
};
