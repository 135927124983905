import _ from 'lodash';
import useLazyQuery from 'modules/core/hooks/useLazyQuery/useLazyQuery';
import { useCallback } from 'react';
import queries from './gql/queryScoring';
/**
 * Hook creado para el llamado a Graphql de obtener los datos de una persona
 * @returns
 */
function useGetPersona() {
  const [queryGetPersona] = useLazyQuery({
    query: queries.GET_PERSONA,
    options: { fetchPolicy: 'network-only' }
  });
  const getPersona = useCallback(
    async (values) => {
      if (queryGetPersona) {
        const result = await queryGetPersona({ variables: values });
        return _.get(result, 'data.getPersona', null);
      }
      return null;
    },
    [queryGetPersona]
  );
  return {
    getPersona
  };
}

export default useGetPersona;
