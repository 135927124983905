import _ from 'lodash';
import useLazyQuery from 'modules/core/hooks/useLazyQuery/useLazyQuery';
import { useCallback } from 'react';
import queries from './gql/getUsers';

/**
 * Hook creado para el llamado a Graphql de servicios de consulta
 * @returns
 */
function useGetConsultaScoring() {
  /** Servicios para obtener los usuario para la visa de la tabla */
  const [queryGetConsulta] = useLazyQuery({
    query: queries.GET_FILTERS_SCORING,
    options: { fetchPolicy: 'network-only' }
  });
  const getFiltersScoring = useCallback(async () => {
    if (queryGetConsulta) {
      const result = await queryGetConsulta();
      return _.get(result, 'data.filters', {});
    }
    return [];
  }, [queryGetConsulta]);
  return {
    getFiltersScoring
  };
}

export default useGetConsultaScoring;
