import {
  useGetJuzgadosTable,
  useSyncJuzgados
} from 'modules/admin/services/useJuzgadosServices';
import DataGrid from 'modules/core/components/DataGrid';
import useLogger from 'modules/core/contexts/LoggerContext/useLogger';
import React, { useCallback, useRef, useState } from 'react';
import Botonera from './components/Botonera';
import useDataGridAttributes from './hooks/useDataGridAttributes';
import classesModule from './JuzgadosView.module.scss';
/**
 * Página de juzgados
 * @param {*} param0
 * @returns
 */
function JuzgadosView() {
  /** Configuraciones y Helpers */
  const { error: errorLogger } = useLogger();
  const { columns, defaultFilters, defaultOrder, defaultPageSize } =
    useDataGridAttributes();
  /** Servicios */
  const { getJuzgadosTable } = useGetJuzgadosTable();
  const { syncJuzgados } = useSyncJuzgados();

  /** Estados */
  const [selectedRows, setSelectedRows] = useState([]);
  /** Referencias */
  const tableRef = useRef();
  /** Funciones handlers */
  const handleSelect = useCallback(
    (newSelectedRows) => {
      setSelectedRows(newSelectedRows);
    },
    [setSelectedRows]
  );
  const handleRefresh = useCallback(() => {
    handleSelect([]);
    tableRef.current.refresh();
  }, [handleSelect, tableRef]);

  const handleSyncJuzgados = useCallback(async () => {
    try {
      await syncJuzgados();
      handleRefresh();
    } catch (e) {
      errorLogger(e);
    }
  }, [handleRefresh, syncJuzgados, errorLogger]);

  return (
    <div className={`${classesModule.main}`}>
      <Botonera
        selectedRows={selectedRows}
        onSync={handleSyncJuzgados}
        onRefresh={handleRefresh}
      />

      <DataGrid
        columns={columns}
        service={getJuzgadosTable}
        defaultFilters={defaultFilters}
        defaultOrder={defaultOrder}
        defaultPageSize={defaultPageSize}
        onSelectionModelChange={handleSelect}
        innerRef={tableRef}
        mode="client"
      />
    </div>
  );
}
export default JuzgadosView;
