import PropTypes from 'prop-types';
import React from 'react';
import classes from './Styles.module.scss';

/** funcion para que sea un enlace tipo boton */
function LinkSisco({ href = '#', title, children, download, ...props }) {
  return (
    <div>
      <a
        href={href}
        title={title}
        download={download}
        className={`${classes.container} ${classes.btn} ${classes.btnLink} ${classes.btnSm}`}
        {...props}
      >
        {children}
      </a>
    </div>
  );
}

LinkSisco.propTypes = {
  children: PropTypes.any,
  download: PropTypes.any,
  href: PropTypes.string,
  title: PropTypes.any
};

export default LinkSisco;
