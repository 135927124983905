import _ from 'lodash';
import useLazyQuery from 'modules/core/hooks/useLazyQuery/useLazyQuery';
import { useCallback } from 'react';
import queries from './gql/getMotivos';
/**
 * Hook creado para el llamado a Graphql de servicios de motivos
 * @returns
 */
function useGetMotivosTable() {
  /** Servicios para obtener los motivo para la visa de la tabla */
  const [queryGetMotivos] = useLazyQuery({
    query: queries.GET_MOTIVOS_TO_TABLE,
    options: { fetchPolicy: 'network-only' }
  });
  const getMotivosTable = useCallback(async () => {
    let aux = {
      rows: [],
      count: 0
    };
    if (queryGetMotivos) {
      const result = await queryGetMotivos();
      aux = _.get(result, 'data.getMotivos', []);
      return {
        rows: aux,
        count: aux.length
      };
    }
    return {
      rows: aux,
      count: aux.length
    };
  }, [queryGetMotivos]);
  return {
    getMotivosTable
  };
}

export default useGetMotivosTable;
