/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React from 'react';
import Typography from 'modules/core/components/Typographies/Typography';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import classesModule from './TextHightLight.module.scss';

/** Componente para el resaltado */
function TextHightLight({ variant = 'caption', highlightText, text }) {
  const matches = match(text, highlightText);
  const parts = parse(text, matches);
  return (
    <Typography variant={variant}>
      {parts.map((part, index) => (
        <span
          key={index}
          className={classesModule.span}
          style={{
            fontWeight: part.highlight ? 800 : 300
          }}
        >
          {part.text}
        </span>
      ))}
    </Typography>
  );
}

TextHightLight.propTypes = {
  highlightText: PropTypes.any,
  text: PropTypes.any,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'button',
    'caption',
    'overline'
  ])
};
export default TextHightLight;
