import _ from 'lodash';
import useLazyQuery from 'modules/core/hooks/useLazyQuery/useLazyQuery';
import useValue from 'modules/core/hooks/useValue';
import { useCallback } from 'react';
import queries from './gql/getJuzgados';
/**
 * Hook creado para el llamado a Graphql de servicios de juzgados
 * @returns
 */
function useGetJuzgadosList() {
  const { getValueList } = useValue();
  const [queryGetJuzgados] = useLazyQuery({
    query: queries.GET_JUZGADOS_TO_LIST,
    options: { fetchPolicy: 'network-only' }
  });
  const getJuzgadosList = useCallback(async () => {
    let aux = [];
    if (queryGetJuzgados) {
      const result = await queryGetJuzgados();
      aux = _.get(result, 'data.getJuzgados', []);
      const listResult = getValueList(aux, { description: 'nombre' });
      return listResult;
    }
    return aux;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryGetJuzgados), getValueList]);

  return {
    getJuzgadosList
  };
}

export default useGetJuzgadosList;
