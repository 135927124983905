import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import Button from '../Button';

/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function SubmitButton({ children, isSubmitting, handleDisabled, ...props }) {
  const { formState } = useFormContext();
  const { isSubmitting: isSubmittingForm } = formState;
  const { translate } = useDecorator();
  /**
   * TODO: Componente
   * @param {*} param0
   * @returns
   */
  const disabledButton = () =>
    handleDisabled ? handleDisabled(formState) : false;
  return (
    <Button
      type="submit"
      loading={isSubmitting || isSubmittingForm}
      disabled={disabledButton()}
      {...props}
    >
      {children || translate('CORE.SUBMITBUTTON_TEXT')}
    </Button>
  );
}

SubmitButton.propTypes = {
  children: PropTypes.any,
  handleDisabled: PropTypes.func,
  isSubmitting: PropTypes.any
};
export default SubmitButton;
