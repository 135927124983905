import _ from 'lodash';
import useLazyQuery from 'modules/core/hooks/useLazyQuery/useLazyQuery';
import { useCallback } from 'react';
import queries from './gql/getUsers';
/**
 * Hook creado para el llamado a Graphql de servicios de usuarios
 * @returns
 */
function useGetUsersTable() {
  /** Servicios para obtener los usuario para la visa de la tabla */
  const [queryGetUsers] = useLazyQuery({
    query: queries.GET_USERS_TO_TABLE,
    options: { fetchPolicy: 'network-only' }
  });
  const getUsersTable = useCallback(async () => {
    let aux = {
      rows: [],
      count: 0
    };
    if (queryGetUsers) {
      const result = await queryGetUsers();
      aux = _.get(result, 'data.getUsuarios', []);

      const user = _.map(aux, (s) => ({
        apellido: _.get(s, 'apellido'),
        email: _.get(s, 'email'),
        id: _.get(s, 'id'),
        juzgado: _.get(s, 'juzgado.nombre'),
        nombre: _.get(s, 'nombre'),
        juzgadoId: _.get(s, 'juzgadoId')
      }));
      return {
        rows: user,
        count: aux.length
      };
    }
    return {
      rows: aux,
      count: aux.length
    };
  }, [queryGetUsers]);
  return {
    getUsersTable
  };
}

export default useGetUsersTable;
