/* eslint-disable no-unused-vars */

import coreReducer from 'modules/core/store';

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // debug reducer
const rootReducer = combineReducers({
  ...coreReducer
});
const middlewares = [thunk];
const initialData = {};

const store = createStore(
  rootReducer,
  initialData,
  composeEnhancers(applyMiddleware(...middlewares)),
  +window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export default store;
// unable to load resolver alias
