import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../Typography';

/**
 * Componente de react que muestras los diferentes tipografias
 * @param {*} param0
 * @returns
 */
function Title({ children, ...props }) {
  return (
    <Typography variant="h1" component="h1" color="primary" {...props}>
      {children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any
};

Title.defaultProps = {
  children: null
};

export default Title;
