import _ from 'lodash';
import useMutation from 'modules/core/hooks/useMutation/useMutation';
import { useCallback } from 'react';
import mutations from './gql/mutationNewPassword';
/**
 * Hook creado para el llamado a Graphql de servicios de usuarios
 * @returns
 */
function useChangePassword() {
  const [mutationChangePassword] = useMutation({
    mutation: mutations.EDIT_PASSWORD
  });
  const changePassword = useCallback(
    async (params) => {
      if (mutationChangePassword) {
        const result = await mutationChangePassword({ variables: params });
        const aux = _.get(result, 'data.changePassword', null);
        return aux;
      }
      return null;
    },
    [mutationChangePassword]
  );

  return {
    changePassword
  };
}

export default useChangePassword;
