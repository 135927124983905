import Stepper from 'modules/core/components/Stepper';
import useMachine from 'modules/core/contexts/MachineContext/useMachine';
import React, { useMemo } from 'react';
import _ from 'lodash';

/**
 * Compoentne que maneja los steppers dentro de un wizard
 * @param {*} param0
 * @returns
 */
function StepperWizard() {
  const { stepper, steps, state } = useMachine();

  /**
   * Obtengo la posicion de paso actual
   */
  const activeStep = useMemo(
    () => _.get(steps, `${state.value}.stepperPosition`, 0),
    [steps, state.value]
  );
  return <Stepper steps={stepper} activeStep={activeStep} />;
}
export default StepperWizard;
