import { Stack } from '@mui/material';
import ResetPassForm from 'modules/auth/forms/ResetPassForm';
import useNavigate from 'modules/core/hooks/useNavigate';
import { useCallback, useState } from 'react';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import Button from 'modules/core/components/Buttons/Button';
import useAuthServices from '../../services/useAuthServices';
import paths from '../paths';
import classesModule from './ResetPassView.module.scss';
/**
 * Página de Login
 * @param {*} param0
 * @returns
 */
function ResetPassView() {
  const {
    params: { token = null },
    go
  } = useNavigate();
  const { resetPass } = useAuthServices();
  const [isOk, setOk] = useState(false);
  const { translate } = useDecorator();
  const handleSubmit = useCallback(
    async (values) => {
      const user = await resetPass({ ...values, token });
      if (user) {
        setOk(true);
      }
    },
    [resetPass, token]
  );
  const goToLogin = useCallback(() => {
    go(paths.login);
  }, [go]);

  return (
    <div className={`${classesModule.main}`}>
      {isOk && (
        <Stack gap={2}>
          <div className="alert alert-success" role="alert">
            {translate('AUTH.RESET_PASS_SUCCESS')}
          </div>
          <Button onClick={goToLogin} variant="contained" color="primary">
            {translate('AUTH.GO_TO_LOGIN')}
          </Button>
        </Stack>
      )}
      {!isOk && <ResetPassForm onSubmit={handleSubmit} />}
    </div>
  );
}
export default ResetPassView;
