import Wizard from 'modules/core/components/Wizard';
import usePublicServices from 'modules/public/services/usePublicServices';
import React, { useMemo } from 'react';
import _ from 'lodash';
import { assign } from 'xstate';
import LoadingStep from 'modules/core/components/Wizard/components/LoadingStep';
import machine from './PublicScoringWizardMachine';
import ResultScoringStep from './Steps/ResultScoringStep';
import SelectPerson from './Steps/SelectPersonStep/SelectPersonStep';
import ErrorAPIMessageStep from './Steps/ErrorAPIMessage';
/**
 * Wizard para la registracion de usuarios por via web
 * @returns
 */
function PublicScoringWizard() {
  const { getScoring } = usePublicServices();
  const steps = useMemo(
    () => ({
      submit: {
        render: LoadingStep,
        stepperPosition: 0
      },
      select: {
        render: SelectPerson,
        stepperPosition: 0
      },
      result: {
        render: ResultScoringStep,
        stepperPosition: 1
      },
      ShowErrorAPI: {
        render: ErrorAPIMessageStep,
        stepperPosition: 2
      }
    }),
    []
  );
  const guards = {};
  const services = {
    getScoring: async (context, event) => {
      const values = _.get(event, 'payload.values');
      return await getScoring(values);
    }
  };
  const actions = {
    setContextAffterStep: assign((context, event) => {
      const values = _.get(event, 'payload.values');
      return { ...context, ...values };
    }),
    setContextAfterInvoke: assign((context, event) => {
      const values = _.get(event, 'data');
      return { ...context, ...values };
    })
  };
  return (
    <Wizard
      guards={guards}
      machine={machine}
      steps={steps}
      services={services}
      actions={actions}
    />
  );
}

export default PublicScoringWizard;
