import PublicLayout from 'modules/sisco/layouts/PublicLayout';
import ConsultScoringView from './ConsultScoringView';
import FaqView from './FaqView';
import LegacyView from './LegacyView';
import paths from './paths';

const moduleRouter = {
  consultScoring: {
    path: paths.consultScoring,
    content: ConsultScoringView,
    Template: PublicLayout,
    templateProps: {
      title: 'PUBLIC.TITLE_SCORING',
      header: {
        title: 'PUBLIC.META_TITLE_SCORING'
      }
    }
  },

  sisco_legacy: {
    path: paths.legacy,
    content: LegacyView,
    Template: PublicLayout
  },
  sisco_faq: {
    path: paths.faq,
    content: FaqView,
    Template: PublicLayout
  }
};
export default moduleRouter;
