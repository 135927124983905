import { Box } from '@mui/material';
import useGetMotivosList from 'modules/admin/services/useMotivosServices/useGetMotivosList';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import { useGetPersonListTable } from 'modules/admin/services/useUserServices';
import useGetConsultaScoring from 'modules/admin/services/useUserServices/useGetConsultaScoring';
import DataGrid from 'modules/core/components/DataGrid';
import useLogger from 'modules/core/contexts/LoggerContext/useLogger';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import LICENSE from 'modules/sisco/constants/lisence';
import Botonera from './components/Botonera';
import useDataGridAttributes from './hooks/useDataGridAttributes';
/**
 * Página de motivos
 * @param {*} param0
 * @returns
 */
function PersonListView({ filter, person }) {
  /** Configuraciones y Helpers */
  const { translate } = useDecorator();
  const { error: errorLogger } = useLogger();
  const [motivos, setMotivos] = useState();
  const [juzgados, setJuzgados] = useState();
  const [localidades, setLocalidades] = useState();
  const [provincias, setProvincias] = useState();
  const type = useMemo(
    () => [
      { value: '-1', label: 'Descuento' },
      { value: '1', label: 'Recupero' }
    ],
    []
  );
  const statusLicense = useMemo(
    () => [
      { value: LICENSE.NO, label: translate('ADMIN.LICENSE_ENABLED') },
      { value: LICENSE.SI, label: translate('ADMIN.LICENSE_DISABLED') }
    ],
    [translate]
  );
  const { columns, defaultFilters, defaultOrder, defaultPageSize } =
    useDataGridAttributes({
      motivos,
      juzgados,
      localidades,
      provincias,
      type,
      statusLicense
    });

  /** Servicios */
  const { getPersonListTable } = useGetPersonListTable();
  const { getFiltersScoring } = useGetConsultaScoring();
  const { getMotivosList } = useGetMotivosList({ filter, person });
  /** Referencias */
  const tableRef = useRef();

  /** Handler */
  const handleRefresh = useCallback(() => {
    try {
      tableRef.current.refresh();
    } catch (err) {
      errorLogger(err);
    }
  }, [errorLogger]);

  const getMotivos = useCallback(async () => {
    const listMotivos = await getMotivosList();

    setMotivos(listMotivos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMotivos, JSON.stringify(getMotivosList)]);

  useEffect(() => {
    if (getMotivos) getMotivos();
  }, [getMotivos]);

  useEffect(() => {
    if (getFiltersScoring) {
      getFiltersScoring().then(({ juzg = [], loc = [], prov = [] }) => {
        setJuzgados(juzg);
        setLocalidades(loc);
        setProvincias(prov);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(getFiltersScoring)]);
  /** Sobreescribo los motivos para que se muestren en la tabla */
  const getListaTabla = useCallback(
    async (props) => {
      if (motivos) {
        const personas = await getPersonListTable(props);

        const result = personas.rows.map(
          (p) => ({
            ...p,
            motivo:
              motivos.find((mot) => mot.value.idExterno === p.motivo)
                ?.description ?? ''
          }),
          []
        );
        personas.rows = result;
        return personas;
      }
      return {};
    },
    [getPersonListTable, motivos]
  );

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Botonera onRefresh={handleRefresh} />
      {motivos && (
        <DataGrid
          disablePin
          checkboxSelection={false}
          columns={columns}
          service={getListaTabla}
          defaultFilters={defaultFilters}
          defaultOrder={defaultOrder}
          defaultPageSize={defaultPageSize}
          innerRef={tableRef}
          mode="server"
        />
      )}
    </Box>
  );
}
PersonListView.propTypes = {
  filter: PropTypes.any,
  person: PropTypes.any
};
export default PersonListView;
