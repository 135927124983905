import PublicScoringWizard from 'modules/public/wizard/PublicScoringWizard';
import React from 'react';

/**
 * Página de consulta de scoring publica
 * @param {*} param0
 * @returns
 */
function ConsultScoringView() {
  return <PublicScoringWizard />;
}
export default ConsultScoringView;
