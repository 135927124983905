import React from 'react';
import Lottie from 'react-lottie';
import animation from 'modules/core/assets/loading-spinner.json';
import classesModule from './LoadingSpinerAnimation.module.scss';
/**
 * Lottie Loading
 * @param {*} param0
 * @returns
 */
function LoadingSpinerAnimation() {
  return (
    <div className={classesModule.LoadingSpinerAnimation}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animation
        }}
      />
    </div>
  );
}
export default LoadingSpinerAnimation;
