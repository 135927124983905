import useSession from 'modules/core/hooks/useSession';
import React, { useCallback } from 'react';
import LoginForm from '../../forms/LoginForm';
import useAuthServices from '../../services/useAuthServices';
import classesModule from './LoginView.module.scss';
/**
 * Página de Login
 * @param {*} param0
 * @returns
 */
function LoginView() {
  const { login: loginService } = useAuthServices();
  const { login } = useSession();

  const handleSubmit = useCallback(
    async (values) => {
      const resp = await loginService(values);
      const { sessionToken: token } = resp;
      login(token);
    },
    [loginService, login]
  );
  return (
    <div className={`${classesModule.main}`}>
      <LoginForm onSubmit={handleSubmit} />
    </div>
  );
}
export default LoginView;
