import Breadcrumbs from 'modules/core/components/Breadcrumbs';
import ScrollbarBox from 'modules/core/components/Scrollbars/ScrollbarBox';
import Title from 'modules/core/components/Typographies/Title';
import Typography from 'modules/core/components/Typographies/Typography';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useNavigate from 'modules/core/hooks/useNavigate';
import PropTypes from 'prop-types';
import React from 'react';
import Meta from 'modules/core/components/Meta';
import classnames from 'classnames';
import Footer from './components/Footer';
import Header from './components/Header';
import classesModule from './PlatformLayout.module.scss';
import Style from './PlantformLayout.stlye';
/**
 * Layout principarl para la plataforma
 * @param {*} param0
 * @returns
 */
function PlatformLayout({
  children,
  breadcrums,
  title,
  introText,
  expanded = true,
  titleObjet,
  header = {}
}) {
  const { translate } = useDecorator();
  const { params } = useNavigate();
  return (
    <div className={classesModule.PlatformLayout}>
      <Meta {...header} />
      <Header />
      <ScrollbarBox className={classesModule.ScrollbarBox}>
        <div
          className={classnames(`${classesModule.conatainer}`, {
            [classesModule.expanded]: expanded
          })}
        >
          {breadcrums && (
            <div className={classesModule.Breadcrumbs}>
              <Breadcrumbs links={breadcrums} />
            </div>
          )}
          {title && <Title {...Style.Title}>{translate(title)}</Title>}
          {titleObjet && (
            <Title {...Style.TitleObjet}>
              {' '}
              {translate(titleObjet.title, titleObjet.paramsTransform(params))}
            </Title>
          )}
          {introText && <Typography>{translate(introText)}</Typography>}
          {children}
        </div>
        <Footer />
      </ScrollbarBox>
    </div>
  );
}

PlatformLayout.propTypes = {
  breadcrums: PropTypes.any,
  children: PropTypes.any,
  expanded: PropTypes.bool,
  header: PropTypes.object,
  introText: PropTypes.any,
  title: PropTypes.any,
  titleObjet: PropTypes.object
};
export default PlatformLayout;
