const Title = {
  sx: {
    mt: 2
  }
};
const TitleObjet = {
  sx: {
    mt: 2
  }
};

export default {
  Title,
  TitleObjet
};
