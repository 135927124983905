import LoadingSpinerAnimation from 'modules/core/components/Animations/LoadingSpinerAnimation';
import React from 'react';
import classModule from './LoadingStep.module.scss';
/**
 * Componetne a mostrarse cuando no se encuntra un paso
 * @returns
 */
function LoadingStep() {
  return (
    <div className={classModule.LoadingStep}>
      <LoadingSpinerAnimation />
    </div>
  );
}
export default LoadingStep;
