import { gql } from '@apollo/client';

const changePassword = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;
export default {
  EDIT_PASSWORD: changePassword
};
