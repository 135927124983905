/* eslint-disable no-console */
/* eslint-disable react/jsx-no-constructed-context-values */
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import LoggerContext from '.';

/**
 * Provider para el manejo de Logs
 * @param {*} param0
 * @returns
 */
function LoggerProvider({ children }) {
  const error = useCallback((message) => {
    console.error(message);
  }, []);
  const warning = useCallback((message) => {
    console.warn(message);
  }, []);
  const log = useCallback((message) => {
    console.log(message);
  }, []);

  return (
    <LoggerContext.Provider value={{ error, warning, log }}>
      {children}
    </LoggerContext.Provider>
  );
}

LoggerProvider.propTypes = {
  children: PropTypes.any
};

export default LoggerProvider;
