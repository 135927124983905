import _ from 'lodash';
import useLazyQuery from 'modules/core/hooks/useLazyQuery/useLazyQuery';
import { useCallback } from 'react';
import queries from './gql/getMyUser';
/**
 * Hook creado para el llamado a Graphql de obtener mis datoso de usuario
 * @returns
 */
function useGetMyUser() {
  const [queryGetMyUser] = useLazyQuery({
    query: queries.GET_MY_USER,
    options: { fetchPolicy: 'network-only' }
  });
  const getMyUsuario = useCallback(
    async (values) => {
      if (queryGetMyUser) {
        const result = await queryGetMyUser({ variables: values });
        return _.get(result, 'data.getMyUsuario', null);
      }
      return null;
    },
    [JSON.stringify(queryGetMyUser)]
  );
  return {
    getMyUsuario
  };
}

export default useGetMyUser;
