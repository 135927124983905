export default {
  logout: '/logout',
  login: '/login',
  recover: '/recover',
  reset_pass: '/reset_pass/:token',
  changePassword: '/auth/changePassword',
  newPassword: '/newPassword',
  myAcount: '/auth/myAcount',
  consultAuthScoring: '/auth/listScoring'
};
