import PlatformLayout from 'modules/sisco/layouts/PlatformLayout';
import PublicLayout from 'modules/sisco/layouts/PublicLayout';
import paths from './paths';
import DashboardView from './DashboardView';
import ErrorView from './Error/ErrorView';
import ForbiddenView from './Forbidden/ForbiddenView';
import MissingView from './Missing/MissingView';
import RedirectView from './Redirect/RedirectView';
import { ROLES } from '../constants';

const moduleRouter = {
  sisco_dashboard: {
    path: paths.sisco_dashboard,
    content: DashboardView,
    Template: PlatformLayout,
    templateProps: {
      title: 'Panel de control',
      expanded: false,
      header: {
        title: 'SISCO.META_TITLE'
      }
    },
    access: {
      session: true,
      roles: [ROLES.ADMIN, ROLES.DEFAULT]
    }
  },
  raiz: {
    path: '/',
    exact: true,
    content: RedirectView,
    Template: PublicLayout,
    propsTemplate: {},
    access: {
      session: null,
      roles: []
    }
  },
  error: {
    path: paths.error,
    exact: true,
    content: ErrorView,
    Template: PublicLayout,
    propsTemplate: {}
  },
  forbidden: {
    path: paths.sisco_error403,
    exact: true,
    content: ForbiddenView,
    Template: PublicLayout,
    propsTemplate: {}
  },
  error404: {
    path: paths.sisco_error404,
    exact: true,
    content: MissingView,
    Template: PublicLayout,
    propsTemplate: {}
  }
};
export default moduleRouter;
