import React, { useCallback, useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import useSession from 'modules/core/hooks/useSession';
import ButtonAvatarMenu from 'modules/core/components/Buttons/ButtonAvatarMenu';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import LockResetIcon from '@mui/icons-material/LockReset';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useNavigate from 'modules/core/hooks/useNavigate';
import _ from 'lodash';
import { useGetMyUser } from 'modules/auth/services/useAcount';
import useAuthServices from 'modules/auth/services/useAuthServices';
import Avatar from '../../../core/components/Avatar';

/** Componente para utilizar en el header con el menu desplegable */
function HeaderAvatarButton() {
  const { logout: logoutService } = useAuthServices();
  const { translate } = useDecorator();
  const { logout, user: sessionUser } = useSession();
  const { getMyUsuario } = useGetMyUser();
  const [user, setUser] = useState(sessionUser);
  const getServerData = useCallback(async () => {
    const userServer = await getMyUsuario();
    setUser(userServer);
  }, [getMyUsuario]);
  useEffect(() => {
    if (getServerData) getServerData();
  }, [getServerData]);
  const { go } = useNavigate();
  const handleLogout = useCallback(async () => {
    await logoutService();
    logout();
  }, [logout, logoutService]);
  const goToResetPassword = useCallback(() => {
    go('/auth/changePassword');
  }, [go]);
  const goToMyAccount = useCallback(() => {
    go('/auth/myAcount');
  }, [go]);
  // const handleResetPassword=useCallback(()=>{

  // },[])
  return (
    <ButtonAvatarMenu variant="small" avatar={user.avatar}>
      <MenuItem onClick={goToMyAccount}>
        <ListItemIcon>
          <Avatar avatar={user.avatar} variant="small" />
        </ListItemIcon>
        {/* TODO: consultarle a sergio por los estilos de estos campos */}
        {_.get(user, 'nombre', '')} {_.get(user, 'apellido', '')}
        <br />
        {_.get(user, 'juzgado.nombre', '')} <br />
        {_.get(user, 'juzgado.domicilio', '')}
      </MenuItem>
      <Divider />
      <MenuItem onClick={goToResetPassword}>
        <ListItemIcon>
          <LockResetIcon fontSize="small" />
        </ListItemIcon>
        {translate('SISCO.HEADERAVATARBUTTON_CHANGE_PASSWORD')}
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        {translate('SISCO.HEADERAVATARBUTTON_LOGOUT')}
      </MenuItem>
    </ButtonAvatarMenu>
  );
}

export default HeaderAvatarButton;
