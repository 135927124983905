import {
  Card as CardMaterial,
  CardActions,
  CardContent,
  CardMedia
} from '@mui/material';
import Typography from 'modules/core/components/Typographies/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import Style from './Card.stlye';
import classesModule from './Card.module.scss';
/**
 * Tarjeta Para Menues
 * @param {*} param0
 * @returns
 */
function Card({ description, img, onClick }) {
  return (
    <CardMaterial onClick={onClick} className={classesModule.container}>
      <Box display="flex" {...Style.container}>
        <CardMedia
          component="img"
          height="80"
          image={img}
          {...Style.CardMedia}
        />
      </Box>
      <CardContent>
        <Typography
          {...Style.Description}
          variant="body2"
          color="text.secondary"
        >
          {description}
        </Typography>
      </CardContent>
      <CardActions {...Style.CardActions} />
    </CardMaterial>
  );
}

Card.propTypes = {
  description: PropTypes.any,
  img: PropTypes.any,
  onClick: PropTypes.func
};
export default Card;
