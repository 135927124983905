import _ from 'lodash';
import MaterialButtonIcon from 'modules/core/components/Buttons/MaterialButtonIcon';
import MaterialMenuButtonIcon from 'modules/core/components/MaterialMenuButtonIcon/MaterialMenuButtonIcon';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useModal from 'modules/core/contexts/ModalContext/useModal';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { ROLES } from 'modules/sisco/constants';
/**
 * Botonera creada para la pantalla de Listado de Juzgados
 * @param {*} param0
 * @returns
 */
function Botonera({
  selectedRows = [],
  onDelete,
  onAdd,
  onEdit,
  onRefresh,
  onSync
}) {
  const { translate } = useDecorator();
  const { confirm } = useModal();
  /**
   * Función para ejecutar cuando se quiere refrescar la tabla
   */
  const handleRefresh = useCallback(() => {
    if (onRefresh) onRefresh();
  }, [onRefresh]);
  /**
   * Función para ejecutar cuando se quiere agregar un elemento
   */
  const handleAddElement = useCallback(() => {
    if (onAdd) onAdd();
  }, [onAdd]);
  /**
   * Función para ejecutar cuando se quiere editar un elemento
   */
  const handleEditElement = useCallback(() => {
    if (onEdit) onEdit(selectedRows[0]);
  }, [onEdit, selectedRows]);
  /**
   * Función para ejecutar cuando se quiere borrar varios elementos
   */
  const handleSyncJuzgados = useCallback(async () => {
    if (onSync) onSync();
  }, [onSync]);

  /**
   * Función para ejecutar cuando se quiere borrar varios elementos
   */
  const handleDeleteElements = useCallback(async () => {
    const resp = await confirm.current.open(
      translate('ADMIN.JUZGADOS_CONFIRM_MESSAGE_DELETE_JUZGADO'),
      {
        title: translate('ADMIN.JUZGADOS_CONFIRM_TITLE_DELETE_JUZGADO')
      }
    );
    if (resp && onDelete) {
      onDelete(selectedRows);
    }
  }, [onDelete, confirm, translate, selectedRows]);

  return (
    <MaterialMenuButtonIcon tooltip="Título de prueba">
      {!!onAdd && (
        <MaterialButtonIcon
          icon="add"
          tooltip={translate('ADMIN.tooltip_button_add')}
          onClick={handleAddElement}
        />
      )}
      {!!onEdit && (
        <MaterialButtonIcon
          disabled={_.isEmpty(selectedRows) || selectedRows.length > 1}
          icon="edit_note"
          onClick={handleEditElement}
          tooltip={translate('ADMIN.tooltip_button_edit')}
        />
      )}
      <MaterialButtonIcon
        icon="refresh"
        onClick={handleRefresh}
        tooltip={translate('ADMIN.tooltip_button_refresh')}
      />
      <MaterialButtonIcon
        icon="sync-alt"
        onClick={handleSyncJuzgados}
        tooltip={translate('ADMIN.tooltip_button_sync')}
        access={{ roles: [ROLES.ADMIN] }}
      />
      {!!onDelete && (
        <MaterialButtonIcon
          color="#d50000"
          disabled={_.isEmpty(selectedRows)}
          icon="delete"
          onClick={handleDeleteElements}
          tooltip={translate('ADMIN.tooltip_button_delete')}
          access={{ roles: [ROLES.ADMIN] }}
        />
      )}
    </MaterialMenuButtonIcon>
  );
}

Botonera.propTypes = {
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onSync: PropTypes.func,
  onEdit: PropTypes.func,
  onRefresh: PropTypes.func,
  selectedRows: PropTypes.array
};
export default Botonera;
