import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import { useMemo } from 'react';

/**
 * Hook creado para tener sepado del componente de react la definicion y logica de la grilla
 */
function useDataGridAttributes() {
  const { translate, dateFormat } = useDecorator();
  const defaultOrder = [{ field: 'id', sort: 'desc' }];
  const defaultPageSize = 10;

  const defaultFilters = useMemo(() => [], []);
  const columns = useMemo(
    () => [
      {
        field: 'nombre',
        headerName: translate('ADMIN.GRID_MOTIVOS_TITLE_NOMBRE'),
        filterable: true,
        sortable: true,
        flex: 1
      },
      {
        field: 'descripcion',
        headerName: translate('ADMIN.GRID_JUZGADOS_TITLE_DESCRIPCION'),
        filterable: true,
        sortable: true,
        flex: 2
      },
      {
        field: 'puntos',
        headerName: translate('ADMIN.GRID_JUZGADOS_TITLE_PUNTOS'),
        filterable: true,
        sortable: true,
        flex: 1
      },
      {
        field: 'idExterno',
        headerName: translate('ADMIN.GRID_JUZGADOS_TITLE_IDEXTERNO'),
        filterable: true,
        sortable: true,
        flex: 0.5
      }
    ],
    [translate, dateFormat]
  );
  return { columns, defaultFilters, defaultOrder, defaultPageSize };
}

export default useDataGridAttributes;
