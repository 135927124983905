import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
/** Definicion de acciones */
export const ACTIONS = {
  SET_DATA: 'SET_DATA'
};
/** Definicion de fucniones de accion */

/** Funcion para seteo data */
const setDataAction = (payload) => ({
  type: ACTIONS.SET_DATA,
  payload
});

/** Hook para el uso de las acciones */
export default function useDataStore() {
  const dispatch = useDispatch();

  const setData = useCallback(
    (data) => {
      dispatch(setDataAction(data));
    },
    [dispatch]
  );

  return { setData };
}
