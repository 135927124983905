import _ from 'lodash';
import Typography from 'modules/core/components/Typographies/Typography';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import PropTypes from 'prop-types';
import TextHightLight from '../../../../../core/components/TextHightLight';
import classesModule from './ContentJuzgadosAutoComplete.module.scss';

/**
 * componente para la estructura de la lista
 * @param {*} param0
 * @returns
 */
function ContentJuzgadosAutoComplete({ propsRender, option, inputValue }) {
  const { translate } = useDecorator();
  return (
    <li {...propsRender}>
      <div className={classesModule.RenderAutocomplete}>
        <TextHightLight
          text={_.get(option, 'value.nombre')}
          highlightText={inputValue}
          variant="caption"
        />
        <Typography variant="subtitle1">
          {translate('ADMIN.LABEL_STATE')}:{' '}
          <TextHightLight
            text={_.get(option, 'value.provincia')}
            highlightText={inputValue}
            variant="subtitle1"
          />
        </Typography>
        <Typography variant="subtitle1">
          {translate('ADMIN.LABEL_LOCALITY')}:{' '}
          <TextHightLight
            text={_.get(option, 'value.localidad')}
            highlightText={inputValue}
            variant="subtitle1"
          />
        </Typography>
      </div>
    </li>
  );
  //
}

ContentJuzgadosAutoComplete.propTypes = {
  inputValue: PropTypes.any,
  option: PropTypes.any,
  propsRender: PropTypes.any
};
export default ContentJuzgadosAutoComplete;
