import { useCallback } from 'react';
import _ from 'lodash';
/**
 * Hook creado para transformar o ayudar a obtener un valor
 * @returns
 */
function useValue() {
  /**
   * Esta funcion detecta, si el obj pasado es una funcion la ejecuta y retorna el valor, sino devuelve el valor directamente
   * Con esta funcion se da flexibilidad para el pasaje de algunos datos a los componentes
   */
  const getValue = useCallback((obj, params) => {
    if (typeof obj === 'function') {
      return obj(params);
    }
    return obj;
  }, []);
  /**
   * Esta funcion detecta, si el field pasado es una funcion la ejecuta y retorna el valor, sino devuelve el _.get  del object y field
   * Con esta funcion se da flexibilidad para el pasaje de algunos datos a los componentes
   */
  const getValueGet = useCallback((obj, field) => {
    if (typeof field === 'function') {
      return field(obj);
    }
    return _.get(obj, field);
  }, []);

  /**
   * Esta funcion transforma una lista en un elemento en formato de valor de lista para front
   * Con esta funcion se da flexibilidad para el pasaje de algunos datos a los componentes
   */
  const getValueList = useCallback(
    (list, { id = 'id', description = 'description' }) =>
      _.map(list, (element) => ({
        id: getValueGet(element, id),
        description: getValueGet(element, description),
        value: element
      })),
    [getValueGet]
  );

  return { getValue, getValueGet, getValueList };
}

export default useValue;
