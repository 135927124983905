/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
// import { DataGrid as DataGridMaterial, esES } from '@mui/x-data-grid';
import { DataGridPro as DataGridMaterial, esES } from '@mui/x-data-grid-pro';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import useLogger from 'modules/core/contexts/LoggerContext/useLogger';
import useFetch from '../../hooks/useFetch/useFetch';
import NoResultsOverlay from './components/NoResultsOverlay';
import NoRowsOverlay from './components/NoRowsOverlay';
import Toolbar from './components/Toolbar';
import classesModule from './DataGrid.module.scss';
/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function DataGrid({
  disablePin,
  url,
  service,
  defaultFilters,
  defaultOrder,
  defaultPageSize,
  list,
  columns,
  fetchParams = {},
  checkboxSelection = true,
  onSelectionModelChange,
  columnVisibilityModel,
  onColumnVisibilityChange,
  mode = 'server',
  innerRef
}) {
  const defaultPage = 0;
  const { error: errorLogger } = useLogger();
  const { post, loading } = useFetch({ ...fetchParams });
  const [rows, setRows] = useState([]);
  const [filters, setFilters] = React.useState(defaultFilters);
  const [page, setPage] = React.useState(defaultPage);
  const [pageSize, setPageSize] = React.useState(defaultPageSize);

  const [totalRows, setTotalRows] = React.useState(0);
  const [order, setOrder] = React.useState(defaultOrder);
  const onSortChange = React.useCallback((orderModel) => {
    setOrder(orderModel);
  }, []);
  const onFilterChange = React.useCallback((filterModel) => {
    setFilters(filterModel.items);
  }, []);

  const onPageChange = React.useCallback((pageChanged) => {
    setPage(pageChanged);
  }, []);

  const onPageSizeChange = React.useCallback((pageSizeChanged) => {
    setPageSize(pageSizeChanged);
  }, []);

  const getTableData = useCallback(async () => {
    try {
      if (list) {
        setTotalRows(list.length);
        setRows(list);
        return;
      }
      if (service) {
        const result = await service({
          filters,
          order,
          offset: page,
          limit: pageSize
        });
        setTotalRows(result?.count);
        setRows(result?.rows);
      } else if (url) {
        const result = await post(url, {
          filters,
          order,
          offset: page,
          limit: pageSize
        });
        setTotalRows(result.count);
        setRows(result.rows);
      }
    } catch (e) {
      errorLogger(e);
    }
  }, [
    filters,
    order,
    JSON.stringify(list),
    page,
    pageSize,
    post,
    service,
    url,
    errorLogger
  ]);

  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, order, page, pageSize, list]);
  useImperativeHandle(
    innerRef,
    () => ({
      refresh: getTableData
    }),
    [getTableData]
  );
  return (
    <div className={`${classesModule.DataGrid}`}>
      {rows && (
        <DataGridMaterial
          disableColumnPinning={disablePin}
          rowHeight={30}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          checkboxSelection={checkboxSelection}
          columns={columns}
          filterMode={mode}
          paginationMode={mode}
          sortingMode="client"
          pageSize={pageSize}
          rowsPerPageOptions={[10, 50, 100]}
          initialState={{
            filter: {
              filterModel: {
                items: defaultFilters
              }
            },
            pagination: {
              page
            }
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityChange={onColumnVisibilityChange}
          sortModel={order}
          onSortModelChange={onSortChange}
          onFilterModelChange={onFilterChange}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          loading={loading}
          components={{
            Toolbar,
            NoRowsOverlay,
            NoResultsOverlay
          }}
          componentsProps={{
            footer: { rows }
          }}
          autoHeight
          onSelectionModelChange={onSelectionModelChange}
          // disableColumnMenu
        />
      )}
    </div>
  );
}

DataGrid.propTypes = {
  checkboxSelection: PropTypes.any,
  columns: PropTypes.any,
  defaultFilters: PropTypes.any,
  defaultOrder: PropTypes.any,
  defaultPageSize: PropTypes.any,
  fetchParams: PropTypes.object,
  innerRef: PropTypes.any,
  list: PropTypes.any,
  mode: PropTypes.oneOf(['client', 'server']),
  onSelectionModelChange: PropTypes.any,
  service: PropTypes.func,
  url: PropTypes.string,
  disablePin: PropTypes.bool,
  columnVisibilityModel: PropTypes.any,
  onColumnVisibilityChange: PropTypes.any
};

export default DataGrid;
