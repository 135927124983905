import PropTypes from 'prop-types';
import React from 'react';
// import { Helmet } from 'react-helmet';
import { Helmet } from 'react-helmet-async';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';

/**
 * Metada Page
 * @param {*} param0
 * @returns
 */
function Meta({ title }) {
  const { translate } = useDecorator();
  return (
    <>
      {' '}
      <Helmet>
        <meta charSet="utf-8" />
        {title ? (
          <title>{translate(title)}</title>
        ) : (
          <title>{translate('CORE.META_TITLE')}</title>
        )}
      </Helmet>
    </>
  );
}

Meta.propTypes = {
  title: PropTypes.any
};
export default Meta;
