/* eslint-disable react/jsx-no-constructed-context-values */

import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import Alert from 'modules/core/components/Modals/Alert';
import Confirm from 'modules/core/components/Modals/Confirm';
import ConfirmMultiple from 'modules/core/components/Modals/ConfirmMultiple';
import ModalContext from '.';
/**
 * Provider para los modales
 * @param {*} param0
 * @returns
 */
function ModalProvider({ children }) {
  const alertRef = useRef();
  const confirmRef = useRef();
  const ConfirmMultipleRef = useRef();

  return (
    <ModalContext.Provider
      value={{
        alert: alertRef,
        confirm: confirmRef,
        confirmMultiple: ConfirmMultipleRef
      }}
    >
      {children}
      <Alert innerRef={alertRef} />
      <Confirm innerRef={confirmRef} />
      <ConfirmMultiple innerRef={ConfirmMultipleRef} />
    </ModalContext.Provider>
  );
}

ModalProvider.propTypes = {
  children: PropTypes.node
};

export default ModalProvider;
