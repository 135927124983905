import PropTypes from 'prop-types';
import _ from 'lodash';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import React, { useMemo } from 'react';
import Button from '../../Buttons/Button';
import SubmitButton from '../../Buttons/SubmitButton';
import StepperWizard from '../../Wizard/components/StepperWizard';
import classesModule from './WizardTemplate.module.scss';

/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function WizardTemplate({ children, title, components: componentsProp = {} }) {
  const { translate } = useDecorator();
  const components = useMemo(() => {
    const defaultButtonsProps = {
      next: {
        children: translate('CORE.WIZARD_TEMPLATE_BUTTON_SUBMIT_TEXT_NEXT')
      },
      prev: {
        children: translate('CORE.WIZARD_TEMPLATE_BUTTON_SUBMIT_TEXT_PREV')
      }
    };

    return _.merge(defaultButtonsProps, componentsProp);
  }, [translate, componentsProp]);
  return (
    <div>
      <div className={classesModule.header}>
        <span>{title && <h1>{title}</h1>}</span>
        <span>
          <StepperWizard />
        </span>
      </div>
      {!!components.header && <div>{components.header}</div>}
      <div className={classesModule.children}>{children}</div>
      <div className={classesModule.footer}>
        {!!components.left && (
          <Button
            variant="outlined"
            className={classesModule.btnSeparator}
            {...components.left}
          />
        )}
        {!!components.prev && (
          <Button
            variant="outlined"
            className={classesModule.btnSeparator}
            {...components.prev}
          />
        )}
        <SubmitButton {...components.next} />
      </div>
    </div>
  );
}

WizardTemplate.propTypes = {
  children: PropTypes.any,
  components: PropTypes.object,
  title: PropTypes.any
};
export default WizardTemplate;
