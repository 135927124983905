import _ from 'lodash';
import useLazyQuery from 'modules/core/hooks/useLazyQuery/useLazyQuery';
import { useCallback } from 'react';
import queries from './gql/getJuzgados';
/**
 * Hook creado para el llamado a Graphql de servicios de juzgados
 * @returns
 */
function useGetJuzgadosTable() {
  /** Servicios para obtener los juzgado para la visa de la tabla */
  const [queryGetJuzgados] = useLazyQuery({
    query: queries.GET_JUZGADOS_TO_TABLE,
    options: { fetchPolicy: 'network-only' }
  });
  const getJuzgadosTable = useCallback(async () => {
    let aux = {
      rows: [],
      count: 0
    };
    // SI EXISTE TRAEMOS LOS RESULTADOS DE LA QUERY
    if (queryGetJuzgados) {
      const result = await queryGetJuzgados();
      aux = _.get(result, 'data.getJuzgados', []);
      // retorno un objeto que contiene el arreglo de los juzagos disponibles y la cantidad
      return {
        rows: aux,
        count: aux.length
      };
    }
    // si no existe retornamos los mismo pero vacio xd
    return {
      rows: aux,
      count: aux.length
    };
  }, [queryGetJuzgados]);
  return {
    getJuzgadosTable
  };
}

export default useGetJuzgadosTable;
