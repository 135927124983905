import PropTypes from 'prop-types';
import React, {
  useMemo,
  useRef,
  useImperativeHandle,
  useCallback,
  useEffect,
  useState
} from 'react';
import ModalTemplate from 'modules/core/components/Templates/ModalTemplate';
import _ from 'lodash';
import Modal from '../Modal';

let resolve = null;
// import classesModule from './Alert.module.scss';
/**
 * menu de alerta utilizando el modal
 * @param {*} param0
 * @returns
 */
function ConfirmMultiple({
  onClose,
  innerRef,
  fullWidth = true,
  children,
  maxWidth = 'xs',
  templateProps,
  template: Template = ModalTemplate,
  title: _title = 'title',
  actionButton: _actionButton,
  ...props
}) {
  /** Contenido a mostrarse dentro del alert */
  const [content, setContent] = useState(children);
  const [title, setTitle] = useState(_title);
  const [stateButton, setStateButton] = useState(null);
  const [actionButton, setActionButton] = useState(_actionButton);
  /** Referencia al componente DOM del modal */
  const modalRef = useRef();
  /** funcion que contiene los argumentos o cunfuraciones para modificar los estaods */
  const argumentConfig = useCallback(
    (_config) => {
      if (_config) {
        if (_config.title) {
          setTitle(_config.title);
        } else {
          setTitle(_title);
        }
        if (_config.actionButton) {
          setActionButton(_config.actionButton);
        } else {
          setActionButton(_actionButton);
        }
      }
    },
    [_actionButton, _title]
  );

  /**   Funcion que se usa para abrir el modal, pero retorna una promesa */
  const handleOpen = useCallback(
    (message, _config = {}) =>
      new Promise((_resolve) => {
        setContent(message);
        setStateButton(null);
        argumentConfig(_config);
        modalRef.current.open();
        resolve = _resolve;
      }),
    [modalRef, argumentConfig]
  );

  /**  Funcion que se ejecuta en el boton de cerrar, o se publica para cerrar el modal */
  const handleCloseButton = useCallback(() => {
    modalRef.current.close();
  }, [modalRef]);
  /** funcion de estado */
  const handleStateButton = (state) => {
    setStateButton(state);
  };
  /** Funcion que se ejecuta despues de cerrar el modal, para devolver la promesa con un valor  */
  const handleClose = useCallback(() => {
    resolve(stateButton);
    if (onClose) {
      onClose();
    }
  }, [onClose, stateButton]);
  /** Configuraicon de botones que se van a usar en el Modal */
  const actionbuttons = useMemo(
    () =>
      _.map(actionButton, (buttonProp) => ({
        onClick: () => handleStateButton(buttonProp.value),
        children: buttonProp.children
      })),
    [actionButton]
  );
  /** Estados y eventos que se publican */
  useImperativeHandle(
    innerRef,
    () => ({
      isOpen: modalRef.current.open,
      open: handleOpen,
      close: handleCloseButton
    }),
    [handleOpen, handleCloseButton]
  );
  useEffect(() => {
    if (stateButton !== null) {
      handleCloseButton();
    }
  }, [stateButton, handleCloseButton]);

  return (
    <Modal
      innerRef={modalRef}
      templateProps={{ ...templateProps, actionbuttons, title }}
      template={Template}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={handleClose}
      {...props}
    >
      {content}
    </Modal>
  );
}

ConfirmMultiple.propTypes = {
  actionButton: PropTypes.any,
  btnText: PropTypes.string,
  btnTextNo: PropTypes.string,
  btnTextYes: PropTypes.string,
  buttons: PropTypes.object,
  children: PropTypes.node,
  defaultConfig: PropTypes.object,
  fullWidth: PropTypes.bool,
  innerRef: PropTypes.object,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'md']),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  template: PropTypes.element,
  templateProps: PropTypes.object,
  title: PropTypes.string,
  variant: PropTypes.string
};
export default ConfirmMultiple;
