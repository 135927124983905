import PropTypes from 'prop-types';
import React from 'react';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import * as Yup from 'yup';
import Form, { ControllerInput } from 'modules/core/components/Forms/Form';
import { TextInput } from 'modules/core/components/Forms/Inputs';
import SectionTitleForm from 'modules/core/components/Templates/SectionTitleForm';
/**
 *
 *Componente para la recuperacion de password
 * @returns
 */
function NewPasswordForm({ onSubmit, template, templateProps }) {
  const { translate } = useDecorator();
  const schema = Yup.object().shape({
    user: Yup.string()
      .email(translate('AUTH.ERROR_EMAIL'))
      .required(translate('AUTH.VALIDATION_USER_USER_REQUIRED'))
      .nullable(translate('AUTH.VALIDATION_USER_USER_REQUIRED'))
  });
  return (
    <Form
      templateProps={{
        submitButtonProps: {
          children: translate('AUTH.PASSWORDRESET_FORM_ADD_BUTTON')
        },
        ...templateProps
      }}
      schema={schema}
      onSubmit={onSubmit}
      template={template}
    >
      <SectionTitleForm>
        <ControllerInput
          render={TextInput}
          name="user"
          label={translate('AUTH.PASSWORDRESET_FORM_ADD_LABEL_USER')}
          placeholder={translate(
            'AUTH.PASSWORDRESET_FORM_ADD_PLACEHOLDER_USER'
          )}
        />
      </SectionTitleForm>
    </Form>
  );
}

NewPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  template: PropTypes.any,
  templateProps: PropTypes.object
};
export default NewPasswordForm;
