import useSettings from 'modules/core/contexts/SettingsContext/useSettings';
import useNavigate from 'modules/core/hooks/useNavigate';
import React, { useEffect } from 'react';
import useSession from '../../../core/hooks/useSession';
/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function RedirectView() {
  const { session } = useSession();
  const { paths } = useSettings();
  const { go } = useNavigate();

  /**
   * TODO: Componente
   * @param {*} param0
   * @returns
   */
  useEffect(() => {
    if (session && session.token) {
      go(paths.SISCO.sisco_dashboard);
    } else {
      go(paths.AUTH.login);
    }
  }, [go, paths, session]);
  return <> </>;
}

export default RedirectView;
