const container = {
  sx: {
    height: '7.5rem',
    width: '21.875rem',
    bgcolor: 'rgb(98 188 232)',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

const CardActions = {
  sx: {
    justifyContent: 'flex-end'
  }
};
const CardMedia = {
  sx: {
    objectFit: 'contain'
  }
};

const Description = {
  sx: {
    fontWeight: '600'
  }
};

export default {
  CardActions,
  CardMedia,
  Description,
  container
};
