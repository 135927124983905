/* eslint-disable jsx-a11y/anchor-is-valid */
import paths from 'modules/auth/views/paths';
import Form, { ControllerInput } from 'modules/core/components/Forms/Form';
import { TextInput } from 'modules/core/components/Forms/Inputs';
import PasswordInput from 'modules/core/components/Forms/Inputs/PasswordInput';
import Link from 'modules/core/components/Link';
import SectionTitleForm from 'modules/core/components/Templates/SectionTitleForm';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useGoogleRecaptchaV3 from 'modules/core/hooks/useGoogleRecaptchaV3';
import useNavigate from 'modules/core/hooks/useNavigate';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import * as Yup from 'yup';
/**
 *
 * Formulario de login
 * @returns
 */
function LoginForm({ onSubmit, template, templateProps }) {
  const { translate } = useDecorator();
  const schema = Yup.object().shape({
    email: Yup.string()
      .nullable(translate('AUTH.VALIDATION_LOGIN_USER_NAME_REQUIRED'))
      .required(translate('AUTH.VALIDATION_LOGIN_USER_NAME_REQUIRED')),
    password: Yup.string()
      .nullable(translate('AUTH.VALIDATION_LOGIN_PASSWORD_REQUIRED'))
      .required(translate('AUTH.VALIDATION_LOGIN_PASSWORD_REQUIRED'))
  });
  const { handleSubmit } = useGoogleRecaptchaV3(
    process.env.REACT_APP_RECAPTCHA_V3_PUBLIC_KEY,
    onSubmit,
    `bottom: 90px!important;`
  );
  const { go } = useNavigate();
  const handleResetPass = useCallback(() => {
    go(paths.recover);
  }, [go]);
  return (
    <Form
      templateProps={{
        submitButtonProps: {
          children: translate('AUTH.LOGIN_FORM_ADD_BUTTON')
        },
        ...templateProps
      }}
      schema={schema}
      onSubmit={handleSubmit}
      template={template}
    >
      <SectionTitleForm>
        <ControllerInput
          render={TextInput}
          defaultValue=""
          name="email"
          label={translate('AUTH.LOGIN_FORM_ADD_LABEL_USERNAME')}
          placeholder={translate('AUTH.LOGIN_FORM_ADD_PLACEHOLDER_USERNAME')}
        />
        <ControllerInput
          render={PasswordInput}
          name="password"
          label={translate('AUTH.LOGIN_FORM_ADD_LABEL_PASSWORD')}
          placeholder={translate('AUTH.LOGIN_FORM_ADD_PLACEHOLDER_PASSWORD')}
        />

        <Link
          href="#"
          sx={{ textAlign: 'right', mt: 2, mb: 2, width: '100%' }}
          onClick={handleResetPass}
        >
          Recuperar contraseña
        </Link>
      </SectionTitleForm>
    </Form>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  template: PropTypes.any,
  templateProps: PropTypes.object
};
export default LoginForm;
