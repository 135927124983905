import pathsSisco from 'modules/sisco/views/paths';
import pathsAuth from 'modules/auth/views/paths';
import pathsCore from 'modules/core/views/paths';
import pathsPublic from 'modules/public/views/paths';
import pathsAdmin from 'modules/admin/views/paths';

const paths = {
  CORE: pathsCore,
  SISCO: pathsSisco,
  AUTH: pathsAuth,
  PUBLIC: pathsPublic,
  ADMIN: pathsAdmin
};

export default paths;
