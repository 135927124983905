import React, { useState } from 'react';
import { FormControl } from '@mui/material';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperError from '../../Form/components/FormHelperError';
/**
 * Componente de password
 * @returns
 */
function PasswordInput({
  children,
  errorMessage,
  variant,
  value,
  size,
  label,
  helperText = null,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false);
  /** funcion para mostrar la clave */
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  // TODO: cambiar icono por los propios
  return (
    <FormControl sx={{ width: '100%' }} error={!!errorMessage}>
      <TextField
        variant={variant}
        value={value}
        label={label}
        size={size}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
        {...props}
      />
      <FormHelperError errorMessage={errorMessage} helperText={helperText} />
    </FormControl>
  );
}

PasswordInput.propTypes = {
  children: PropTypes.node, //* Variant style component
  value: PropTypes.any,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'string']),
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  helperText: PropTypes.string
};
PasswordInput.defaultProps = {
  children: null,
  variant: process.env.REACT_APP_STYLED_DEFAULT_INPUT_VARIANT || 'outlined',
  size: process.env.REACT_APP_STYLED_DEFAULT_INPUT_SIZE || 'small',
  errorMessage: null,
  label: null,
  helperText: null
};
export default PasswordInput;
