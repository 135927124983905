/* eslint-disable react/jsx-no-useless-fragment */
import useSecurity from 'modules/core/hooks/useSecurity';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Redirect from './Redirect';
/**
 * Manager de routes, agrega todos los componentes
 */
function RouterManger({ routers }) {
  const loading = useSelector((store) => _.get(store, 'session.loading'));
  const { hasAccess: hasAccessSecurity } = useSecurity();

  /** Funcion que valida si tiene accessos */
  const hasAccess = useCallback(
    (route) => hasAccessSecurity(_.get(route, 'access', {})),
    [hasAccessSecurity]
  );

  return (
    <BrowserRouter>
      <Routes>
        {routers.map((route) => (
          <Route
            key={`${route.path}`}
            path={`${process.env.REACT_APP_PREPENDED_PATH || ''}${route.path}`}
            element={
              <>
                {/* TODO: crear componente de loading para la pagina */}
                {loading ? (
                  <></>
                ) : (
                  <>
                    {!hasAccess(route) ? (
                      <Redirect to="/" path={route.path} />
                    ) : (
                      <>
                        {React.createElement(
                          route.Template,
                          { ...route.templateProps },
                          React.createElement(route.content, {})
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            }
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

RouterManger.propTypes = {
  routers: PropTypes.any
};
export default RouterManger;
