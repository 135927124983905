import PropTypes from 'prop-types';
import React from 'react';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import * as Yup from 'yup';
import Form, { ControllerInput } from 'modules/core/components/Forms/Form';
import { TextInput } from 'modules/core/components/Forms/Inputs';
import SectionTitleForm from 'modules/core/components/Templates/SectionTitleForm';
import TypeDocumentsSelectInput from 'modules/sisco/components/Forms/Inputs/TipoLicenciasSelectInput';
import SexRadioInput from 'modules/sisco/components/Forms/Inputs/SexRadioInput';
/**
 * Componente para la recuperacion de password
 * @returns
 */
function ConsultScoringForm({ onSubmit, template, templateProps }) {
  const { translate } = useDecorator();
  const schema = Yup.object().shape({
    tipoDocumento: Yup.string()
      .required(
        translate('PUBLIC.VALIDATION_CONSULTSCORING_TIPO_DOCUMENTO_REQUIRED')
      )
      .nullable(
        translate('PUBLIC.VALIDATION_CONSULTSCORING_TIPO_DOCUMENTO_REQUIRED')
      ),
    sexo: Yup.string()
      .required(translate('PUBLIC.VALIDATION_CONSULTSCORING_SEXO_REQUIRED'))
      .nullable(translate('PUBLIC.VALIDATION_CONSULTSCORING_SEXO_REQUIRED')),
    documento: Yup.string()
      .required(
        translate('PUBLIC.VALIDATION_CONSULTSCORING_DOCUMENTO_REQUIRED')
      )
      .max(20, translate('PUBLIC.VALIDATION_MAX_CARACTERES_DNI'))
      .nullable(
        translate('PUBLIC.VALIDATION_CONSULTSCORING_DOCUMENTO_REQUIRED')
      )
      .matches(
        /[0-9]+/,
        translate('PUBLIC.VALIDATION_CONSULTSCORING_DOCUMENTO_INVALID_REQUIRED')
      )
  });
  return (
    <Form
      templateProps={{
        submitButtonProps: {
          children: translate('PUBLIC.CONSULTSCORING_FORM_ADD_BUTTON')
        },
        ...templateProps
      }}
      schema={schema}
      onSubmit={onSubmit}
      template={template}
    >
      <SectionTitleForm>
        <ControllerInput
          render={TypeDocumentsSelectInput}
          defaultValue=""
          name="tipoDocumento"
        />
        <ControllerInput
          render={TextInput}
          defaultValue=""
          name="documento"
          label={translate('PUBLIC.CONSULTSCORING_FORM_ADD_LABEL_NUMBER')}
          inputProps={{ maxLength: 20 }}
          placeholder={translate(
            'PUBLIC.CONSULTSCORING_FORM_ADD_PLACEHOLDER_NUMBER'
          )}
        />
        <ControllerInput render={SexRadioInput} defaultValue="" name="sexo" />
      </SectionTitleForm>
    </Form>
  );
}

ConsultScoringForm.propTypes = {
  onSubmit: PropTypes.func,
  template: PropTypes.any,
  templateProps: PropTypes.object
};
export default ConsultScoringForm;
