import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import Stack from '@mui/material/Stack';
import StarIcon from '@mui/icons-material/Star';
import Typography from 'modules/core/components/Typographies/Typography';
import Tooltip from 'modules/core/components/Helpers/Tooltip';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import TextHightLight from '../../../../../core/components/TextHightLight';
import classesModule from './ContentMotivosAutoComplete.module.scss';

/**
 * componente para la estructura de la lista
 * @param {*} param0
 * @returns
 */
function ContentMotivosAutoComplete({ propsRender, option, inputValue }) {
  const { translate } = useDecorator();
  return (
    <li {...propsRender}>
      <div className={classesModule.RenderAutocomplete}>
        <TextHightLight
          text={_.get(option, 'value.nombre')}
          highlightText={inputValue}
          variant="caption"
        />
        <Stack
          justifyContent="start"
          alignItems="center"
          direction="row"
          spacing={2}
        >
          {_.get(option, 'value.usoFrecuente') && (
            <Tooltip title={translate('ADMIN.TOOLTIP_FAVORITO')}>
              <StarIcon color="primary" />
            </Tooltip>
          )}
          <Typography>
            [
            <TextHightLight
              text={_.get(option, 'value.idExterno')}
              highlightText={inputValue}
              variant="subtitle1"
            />
            ]
          </Typography>

          <Typography variant="subtitle1">
            Puntos: {_.get(option, 'value.puntos')}
          </Typography>
        </Stack>
        <TextHightLight
          text={_.get(option, 'value.descripcion')}
          highlightText={inputValue}
          variant="subtitle1"
        />
      </div>
    </li>
  );
  //
}

ContentMotivosAutoComplete.propTypes = {
  inputValue: PropTypes.any,
  option: PropTypes.any,
  propsRender: PropTypes.any
};
export default ContentMotivosAutoComplete;
