import { gql } from '@apollo/client';

const getJuzgadosToTable = gql`
  query {
    getJuzgados {
      id
      nombre
      provincia
      localidad
      domicilio
      idExterno
    }
  }
`;
const getListJuzgados = gql`
  query {
    getJuzgados {
      id
      nombre
      localidad
      provincia
      domicilio
    }
  }
`;
const getJuzgadoById = gql`
  query getJuzgado($id: Int!) {
    getJuzgado(id: $id) {
      id
      nombre
      descripcion
      idExterno
    }
  }
`;
export default {
  GET_JUZGADOS_TO_TABLE: getJuzgadosToTable,
  GET_JUZGADO_BY_ID: getJuzgadoById,
  GET_JUZGADOS_TO_LIST: getListJuzgados
};
