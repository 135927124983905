import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { ThemeProvider } from '@emotion/react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import _ from 'lodash';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import DecoratorProvider from 'modules/core/contexts/DecoratorContext/DecoratorProvider';
import LoggerProvider from 'modules/core/contexts/LoggerContext/LoggerProvider';
import ModalProvider from 'modules/core/contexts/ModalContext/ModalProvider';
import SnackbarProvider from 'modules/core/contexts/SnakbarContext/SnakbarProvider';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import 'moment/locale/es';
/**
 * Componente que engloba toda la configuración que va a utlizar la App
 * @param {*} param0
 * @returns
 */
function Application({ theme, translations, children }) {
  const token = useSelector((store) => _.get(store, 'session.token'));

  const client = useMemo(() => {
    if (token) {
      const httpLink = createHttpLink({
        uri: `${process.env.REACT_APP_API_URI}/graphql`
      });
      /** Auth Link */
      const authLink = setContext((_a, { headers }) => ({
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : ''
        }
      }));
      return new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
      });
    }
    return null;
  }, [token]);

  useEffect(
    () => () => {
      if (client) client.stop();
    },
    [client]
  );

  const App = (
    <MUIThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <LoggerProvider>
          <SnackbarProvider>
            <DecoratorProvider
              translations={translations}
              defaultLang="es"
              lang="es"
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <ModalProvider>{children}</ModalProvider>
              </LocalizationProvider>
            </DecoratorProvider>
          </SnackbarProvider>
        </LoggerProvider>
      </ThemeProvider>
    </MUIThemeProvider>
  );
  return client ? <ApolloProvider client={client}>{App}</ApolloProvider> : App;
}
Application.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.any,
  translations: PropTypes.any
};
export default Application;
