import PropTypes from 'prop-types';
import React from 'react';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import * as Yup from 'yup';
import Form, { ControllerInput } from 'modules/core/components/Forms/Form';
import { TextInput } from 'modules/core/components/Forms/Inputs';
import SectionTitleForm from 'modules/core/components/Templates/SectionTitleForm';
import JuzgadoAutocompleteInput from 'modules/admin/components/forms/JuzgadoAutocompleteInput';
/**
 *
 * Formulario para ediat un usuario
 * @returns
 */
function UserFormEdit({ onSubmit, template, templateProps, defaultValues }) {
  const { translate } = useDecorator();
  const schema = Yup.object().shape({
    email: Yup.string()
      .email(translate('ADMIN.VALIDATION_ERROR_EMAIL'))
      .required(translate('ADMIN.VALIDATION_USER_EMAIL_REQUIRED')),
    juzgadoId: Yup.number()
      .typeError(translate('ADMIN.VALIDATION_MOTIVOS_JUZGADO_ID_REQUIRED'))
      .required(translate('ADMIN.VALIDATION_MOTIVOS_JUZGADO_ID_REQUIRED')),
    nombre: Yup.string()
      .nullable()
      .required(translate('ADMIN.VALIDATION_USER_NAME_REQUIRED')),
    apellido: Yup.string()
      .nullable(translate('ADMIN.VALIDATION_USER_LAST_NAME_REQUIRED'))
      .required(translate('ADMIN.VALIDATION_USER_LAST_NAME_REQUIRED'))
  });
  return (
    <Form
      templateProps={{
        submitButtonProps: {
          children: translate('ADMIN.USER_FORM_ADD_BUTTON', defaultValues)
        },
        ...templateProps
      }}
      schema={schema}
      onSubmit={onSubmit}
      template={template}
      defaultValues={defaultValues}
    >
      <SectionTitleForm>
        <ControllerInput
          render={TextInput}
          defaultValue=""
          name="nombre"
          label={translate('ADMIN.USER_FORM_ADD_LABEL_NAME')}
          placeholder={translate('ADMIN.USER_FORM_ADD_PLACEHOLDER_NAME')}
        />
        <ControllerInput
          render={TextInput}
          defaultValue=""
          name="apellido"
          label={translate('ADMIN.USER_FORM_ADD_LABEL_LAST_NAME')}
          placeholder={translate('ADMIN.USER_FORM_ADD_PLACEHOLDER_LAST_NAME')}
        />
        <ControllerInput
          render={TextInput}
          defaultValue=""
          name="email"
          label={translate('ADMIN.USER_FORM_ADD_LABEL_USER')}
          placeholder={translate('ADMIN.USER_FORM_ADD_PLACEHOLDER_USER')}
        />
        <ControllerInput
          render={JuzgadoAutocompleteInput}
          name="juzgadoId"
          label={translate('ADMIN.USER_FORM_ADD_LABEL_COURTS')}
        />
      </SectionTitleForm>
    </Form>
  );
}

UserFormEdit.propTypes = {
  defaultValues: PropTypes.any,
  onSubmit: PropTypes.func,
  template: PropTypes.any,
  templateProps: PropTypes.object
};
export default UserFormEdit;
