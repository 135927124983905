import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid';
import React from 'react';
import classesModule from './Toolbar.module.scss';
/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function Toolbar() {
  return (
    <div className={` ${classesModule.Toolbar} `}>
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport csvOptions={{ delimiter: ';' }} />
      </GridToolbarContainer>
    </div>
  );
}
export default Toolbar;
