import translations from 'config/langs';
import store from 'config/store';
import theme from 'config/styles/theme';
import _ from 'lodash';
import AppManager from 'modules/core/managers/AppManager';
import RouterManger from 'modules/core/managers/RouterManager';
import SessionManager from 'modules/sisco/manager/SessionManager';
import React, { Component } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import views from './config/views';
import paths from './config/views/paths';
/**
 * Componente principal del sitio de Reat
 */
class App extends Component {
  /**
   *Atrapa el error de react
   */
  componentDidCatch(e) {
    throw e;
    // if (_.get(window, 'env.loggerConfig.redirectError', false)) {
    //   window.location = 'auth/error';
    // }
  }

  /**
   * Render
   * @returns
   */
  render() {
    const routers = [];
    _.forEach(views, (module) => {
      _.forEach(module, (view) => {
        routers.push(view);
      });
    });

    return (
      <HelmetProvider>
        <AppManager
          translations={translations}
          theme={theme}
          store={store}
          paths={paths}
          requestGetErrorFunction={(request) => {
            if (_.get(request, 'errorCode')) {
              return _.get(request, 'msg');
            }
            return null;
          }}
        >
          <SessionManager />
          <RouterManger routers={routers} />
        </AppManager>
      </HelmetProvider>
    );
  }
}

export default App;
