import PropTypes from 'prop-types';
import React from 'react';
import Link from '@mui/material/Link';
import Style from './Link.stlye';
/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function LinkMaterial({ children, ...props }) {
  return (
    <Link {...Style.Link} {...props}>
      {children}
    </Link>
  );
}

LinkMaterial.propTypes = {
  children: PropTypes.any
};
export default LinkMaterial;
