import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import React, { useImperativeHandle } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import SimpleFormTemplate from '../../Templates/SimpleFormTemplate';
import DevtoolForm from './components/devTools/DevtoolForm/index';
import classesModule from './Form.module.scss';

/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function Form({
  template: Template = SimpleFormTemplate,
  templateProps = { xs: 12 },
  config = {},
  className = '',
  children,
  defaultValues = {},
  onSubmit,
  schema = {},
  innerRef
}) {
  const methods = useForm({
    defaultValues: { ...defaultValues },
    resolver: yupResolver(schema),
    mode: _.get(config, 'mode', 'onSubmit'),
    criteriaMode: _.get(config, 'criteriaMode', 'firstErrorDetected'),
    reValidateMode: _.get(config, 'reValidateMode', 'onChange'),
    shouldUnregister: _.get(config, 'shouldUnregister', true),
    shouldFocusError: _.get(config, 'shouldFocusError', true),
    context: _.get(config, 'context', {}),
    ..._.get(config, 'options', {})
  });
  useImperativeHandle(
    innerRef,
    () => ({
      ...methods
    }),
    [methods]
  );

  /**
   * TODO: Componente
   * @param {*} param0
   * @returns
   */
  const handleSubmit = async (values) => {
    let result = null;
    if (onSubmit) {
      result = await onSubmit(values);
    }
    return result;
  };
  return (
    <div className={` ${classesModule.Form} `}>
      <FormProvider {...methods}>
        <form
          autoComplete="off"
          className={` ${className}`}
          onSubmit={methods.handleSubmit(handleSubmit)}
          noValidate
        >
          <Template item {...templateProps}>
            {children}
          </Template>
        </form>
        <DevtoolForm />
      </FormProvider>
    </div>
  );
}

Form.propTypes = {
  children: PropTypes.node,
  className: PropTypes.object,
  config: PropTypes.object,
  defaultValues: PropTypes.object,
  innerRef: PropTypes.any,
  onSubmit: PropTypes.func,
  schema: PropTypes.object,
  template: PropTypes.elementType,
  templateProps: PropTypes.object
};

export default Form;
