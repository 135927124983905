import _ from 'lodash';
import useMutation from 'modules/core/hooks/useMutation/useMutation';
import { useCallback } from 'react';
import mutations from './gql/mutationUsers';
/**
 * Hook creado para el edit a Graphql de servicios de usuarios
 * @returns
 */
function useEditUser() {
  const [mutationEdit] = useMutation({
    mutation: mutations.EDIT_USUARIO
  });
  const [mutationResetPassword] = useMutation({
    mutation: mutations.RESET_PASSWORD
  });
  const editUser = useCallback(
    async (params) => {
      if (mutationEdit) {
        const result = await mutationEdit({ variables: params });
        const aux = _.get(result, 'data.editUsuario', null);
        return aux;
      }
      return null;
    },
    [mutationEdit]
  );
  const resetPassword = useCallback(
    async (params) => {
      if (mutationResetPassword) {
        const result = await mutationResetPassword({ variables: params });
        const aux = _.get(result, 'data.resetPassword', null);
        return aux;
      }
      return null;
    },
    [mutationResetPassword]
  );
  return {
    editUser,
    resetPassword
  };
}

export default useEditUser;
