import _ from 'lodash';
import { useEffect } from 'react';
import {
  useNavigate as useNavigateRouter,
  useParams,
  useLocation
} from 'react-router-dom';
/**
 *
 * @returns Hook utilizado para la navegacion en el sitio
 */
function useNavigate() {
  const params = useParams();
  const location = useLocation();

  /**
   * Genera una url remplazando los params dentro del path
   * @param {string} path
   * @param {*} values
   * @returns
   */
  const generateUrl = (path, values) => {
    let url = path;
    _.forEach(values, (value, key) => {
      const paramKey = key === '_id' ? 'id' : key;
      url = url.replace(new RegExp(`((:${paramKey}(\\?)*$))`, 'g'), value);
      url = url.replace(
        new RegExp(`((:${paramKey}(\\?)*/))`, 'g'),
        `${value}/`
      );
    });

    // eslint-disable-next-line prefer-regex-literals
    url = url.replace(new RegExp('(:(\\w)+\\?(\\/)*)', 'g'), '');

    return `${process.env.REACT_APP_PREPENDED_PATH || ''}${url}`;
  };

  const navigate = useNavigateRouter();
  /**
   * Haste un redirect a la uneva pagina
   * @param {string} path
   * @param {*} params
   */
  const go = (path, values) => {
    const url = generateUrl(path, values);
    navigate(url);
  };

  return { go, params, generateUrl, location };
}
export default useNavigate;
