import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import * as Yup from 'yup';
import Form, { ControllerInput } from 'modules/core/components/Forms/Form';
import { TextInput } from 'modules/core/components/Forms/Inputs';
import SectionTitleForm from 'modules/core/components/Templates/SectionTitleForm';
// import MotivoSelectInput from 'modules/admin/components/forms/MotivoSelectInput';
import MotivoAutocompleteInput from 'modules/admin/components/forms/MotivoAutocompleteInput';
import DateInput from 'modules/core/components/Forms/Inputs/DateInput';
import TextInputMultiple from 'modules/core/components/Forms/Inputs/TextInputMultiple/TextInputMultiple';
import useNavigate from 'modules/core/hooks/useNavigate';
import _ from 'lodash';
import moment from 'moment';
/**
 *
 * Formulario de scoring
 * @returns
 */
function ScoringForm({
  onSubmit,
  template,
  templateProps,
  filterMotivos,
  person
}) {
  const [disabled, setDisabled] = useState(true);
  const { translate } = useDecorator();
  const { params } = useNavigate();
  const innerRefForm = useRef();
  /** funcion de handle */
  const schema = Yup.object().shape({
    motivoId: Yup.number()
      .typeError(translate('ADMIN.VALIDATION_SCORING_REASON_REQUIRED'))
      .required(translate('ADMIN.VALIDATION_SCORING_REASON_REQUIRED')),
    fechaInfraccion: Yup.date()
      .max(
        moment(),
        translate('ADMIN.VALIDATION_SCORING_DATE_INFRACTION_BEFORE')
      )
      .required(translate('ADMIN.VALIDATION_FECHA_REASON_REQUIRED'))
      .typeError(translate('ADMIN.VALIDATION_FECHA_ERROR_REASON_REQUIRED')),
    expediente: Yup.string()
      .nullable(translate('ADMIN.VALIDATION_EXPEDINETE__REQUIRED'))
      .required(translate('ADMIN.VALIDATION_EXPEDINETE__REQUIRED')),
    puntos: Yup.number()
      .required(translate('ADMIN.VALIDATION_SCORING'))
      .nullable(translate('ADMIN.VALIDATION_SCORING'))
      .typeError(translate('ADMIN.VALIDATION_ERROR_SCORING'))
  });
  const handleChangeMotivoId = useCallback(
    (values, obj) => {
      const puntos = _.get(obj, 'puntos');
      innerRefForm.current.setValue('puntos', puntos);
      setDisabled(!!puntos);
    },
    [innerRefForm]
  );
  /** funcion para retornar un objeto el cual nos siver para la traduccion */
  const labelFecha = (param) => {
    if (param?.type === 'penalize') {
      return { penalize: true };
    }
    if (param?.type === 'recover') {
      return { recover: true };
    }
    return null;
  };

  return (
    <Form
      templateProps={{
        submitButtonProps: {
          children: translate('ADMIN.SCORING_FORM_ADD_BUTTON')
        },
        ...templateProps
      }}
      schema={schema}
      onSubmit={onSubmit}
      template={template}
      innerRef={innerRefForm}
    >
      <SectionTitleForm>
        <ControllerInput
          templateProps={{
            xs: 10
          }}
          render={MotivoAutocompleteInput}
          filter={filterMotivos === 'penalize' ? 'negative' : 'positive'}
          name="motivoId"
          label={translate(
            filterMotivos === 'penalize'
              ? 'ADMIN.SCORING_FORM_ADD_LABEL_REASON_NEGATIVE'
              : 'ADMIN.SCORING_FORM_ADD_LABEL_REASON_POSITIVE'
          )}
          onChange={handleChangeMotivoId}
          person={person}
        />
        <ControllerInput
          templateProps={{
            xs: 2
          }}
          render={TextInput}
          disabled={disabled}
          name="puntos"
          type="number"
          label={translate('ADMIN.SCORING_FORM_ADD_LABEL_PUNTOS')}
          placeholder={translate('ADMIN.SCORING_FORM_ADD_PLACEHOLDER_PUNTOS')}
        />
        <ControllerInput
          templateProps={{
            xs: 6
          }}
          render={TextInput}
          name="expediente"
          label={translate('ADMIN.SCORING_FORM_ADD_LABEL_EXPEDIENTE')}
          placeholder={translate(
            'ADMIN.SCORING_FORM_ADD_PLACEHOLDER_EXPEDIENTE'
          )}
        />
        <ControllerInput
          templateProps={{
            xs: 6
          }}
          defaultValue={null}
          render={DateInput}
          maxDate={moment()}
          name="fechaInfraccion"
          label={translate(
            'ADMIN.SCORING_FORM_ADD_LABEL_DESCRIPTION_FECHA',
            labelFecha(params)
          )}
        />
        <ControllerInput
          render={TextInputMultiple}
          name="descripcion"
          label={translate('ADMIN.SCORING_FORM_ADD_LABEL_DESCRIPTION')}
          placeholder={translate(
            'ADMIN.SCORING_FORM_ADD_PLACEHOLDER_DESCRIPTION'
          )}
        />
      </SectionTitleForm>
    </Form>
  );
}

ScoringForm.propTypes = {
  filterMotivos: PropTypes.any,
  onSubmit: PropTypes.func,
  template: PropTypes.any,
  templateProps: PropTypes.object,
  person: PropTypes.object
};
export default ScoringForm;
