import MotivosForm from 'modules/admin/forms/MotivosForm';
import {
  useAddMotivo,
  useGetMotivoById,
  useGetMotivosTable,
  useDeleteMotivo,
  useEditMotivo
} from 'modules/admin/services/useMotivosServices';
import DataGrid from 'modules/core/components/DataGrid';
import Modal from 'modules/core/components/Modals/Modal';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useLogger from 'modules/core/contexts/LoggerContext/useLogger';
import React, { useCallback, useRef, useState } from 'react';
import Botonera from './components/Botonera';
import useDataGridAttributes from './hooks/useDataGridAttributes';
import classesModule from './MotivosView.module.scss';
/**
 * Página de motivos
 * @param {*} param0
 * @returns
 */
function MotivosView() {
  /** Configuraciones y Helpers */
  const { translate } = useDecorator();
  const { error: errorLogger } = useLogger();
  const { columns, defaultFilters, defaultOrder, defaultPageSize } =
    useDataGridAttributes();
  /** Servicios */
  const { getMotivosTable } = useGetMotivosTable();
  const { addMotivo } = useAddMotivo();
  const { getMotivo } = useGetMotivoById();
  const { deleteMotivo } = useDeleteMotivo();
  const { editMotivo } = useEditMotivo();

  /** Estados */
  const [selectedRows, setSelectedRows] = useState([]);
  const [elementToEdit, setElementToEdit] = useState(null);
  /** Referencias */
  const modalAddRef = useRef();
  const tableRef = useRef();
  /** Funciones handlers */
  const handleSelect = useCallback(
    (newSelectedRows) => {
      setSelectedRows(newSelectedRows);
    },
    [setSelectedRows]
  );
  const handleRefresh = useCallback(() => {
    handleSelect([]);
    tableRef.current.refresh();
  }, [handleSelect, tableRef]);

  const handleAdd = useCallback(() => {
    setElementToEdit(null);
    modalAddRef.current.open();
  }, [modalAddRef]);

  const handleEdit = useCallback(
    async (id) => {
      try {
        const motivo = await getMotivo({ id });
        setElementToEdit(motivo);
        modalAddRef.current.open();
      } catch (e) {
        errorLogger(e);
      }
    },
    [modalAddRef, getMotivo, errorLogger]
  );

  const handleDelete = useCallback(
    async (ids) => {
      try {
        await deleteMotivo({ ids });
        handleRefresh();
      } catch (e) {
        errorLogger(e);
      }
    },
    [handleRefresh, deleteMotivo, errorLogger]
  );

  const handleSubmit = useCallback(
    async (values) => {
      try {
        if (elementToEdit) {
          await editMotivo({ id: elementToEdit.id, ...values });
        } else {
          await addMotivo(values);
        }
        tableRef.current.refresh();
        modalAddRef.current.close();
      } catch (e) {
        errorLogger(e);
      }
    },
    [addMotivo, editMotivo, elementToEdit, errorLogger]
  );

  return (
    <div className={`${classesModule.main}`}>
      <Botonera
        selectedRows={selectedRows}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onRefresh={handleRefresh}
      />

      <DataGrid
        columns={columns}
        service={getMotivosTable}
        defaultFilters={defaultFilters}
        defaultOrder={defaultOrder}
        defaultPageSize={defaultPageSize}
        onSelectionModelChange={handleSelect}
        innerRef={tableRef}
        mode="client"
      />
      <Modal
        innerRef={modalAddRef}
        maxWidth="sm"
        templateProps={{
          title: translate('ADMIN.MOTIVOS_FORM_ADD_EDIT_TITLE', elementToEdit)
        }}
      >
        <MotivosForm onSubmit={handleSubmit} defaultValues={elementToEdit} />
      </Modal>
    </div>
  );
}
export default MotivosView;
