import moduleCore from 'modules/core/langs/es';
import moduleAuth from 'modules/auth/langs/es';
import moduleSisco from 'modules/sisco/langs/es';
import modulePublic from 'modules/public/langs/es';
import moduleAdmin from 'modules/admin/langs/es';

export default {
  CORE: moduleCore,
  SISCO: moduleSisco,
  AUTH: moduleAuth,
  PUBLIC: modulePublic,
  ADMIN: moduleAdmin
};
