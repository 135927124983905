/* eslint-disable react/no-danger */
import * as React from 'react';
import MaterialIcon, {
  TYPES
} from 'modules/core/components/Icons/MaterialIcon';

import PropTypes from 'prop-types';
import { Grid, Stack } from '@mui/material';
import Typography from '../Typographies/Typograpy';
import classesModule from './classes.module.scss';

/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function HelpMessage({ title, children, icon = 'email', img, className }) {
  return (
    <Grid
      container
      className={`${className}`}
      sx={{
        minWidth: '200px',
        minHeight: '100px',
        width: 'auto',
        height: 'auto',
        background: '#f3faff',
        borderColor: '#0072bb',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderRadius: '10px',
        padding: '15px'
      }}
    >
      <Grid item xs={3} alignContent="flex-start">
        {img && <img src={img} alt="email" className={classesModule.Img} />}
        {!img && (
          <MaterialIcon
            icon={icon}
            size="500%"
            type={TYPES.FILLED}
            materialColor="#0072bb"
          />
        )}
      </Grid>
      <Grid item xs={9}>
        <Stack spacing={1} direction="column">
          <Typography variant="h1" className={classesModule.Title}>
            {title}
          </Typography>
          <Typography variant="body1" className={classesModule.Body}>
            {children}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
HelpMessage.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  img: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.any
};

export default HelpMessage;
