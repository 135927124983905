import React, { useEffect, useState } from 'react';
import Icon from '@mui/material/Icon';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useSettings from 'modules/core/contexts/SettingsContext/useSettings';
import { TYPES } from './MaterialIcon.constants';

/**
 * Componente de react que muestras los diferentes tipografias
 * @param {*} param0
 * @returns
 */
function MaterialIcon({
  icon,
  size,
  iconColor,
  materialColor,
  type = TYPES.FILLED
}) {
  const [useCustoms, setUseCustoms] = useState(null);
  const { iconsPrefix } = useSettings();

  useEffect(() => {
    if (icon.includes(iconsPrefix)) {
      setUseCustoms(true);
    } else {
      setUseCustoms(false);
    }
  }, [icon, iconsPrefix]);

  return (
    useCustoms !== null &&
    (useCustoms ? (
      <span className={icon} style={{ fontSize: size, color: iconColor }} />
    ) : (
      <Icon
        className={classNames({
          'material-icons-outlined': type === TYPES.OUTLINED,
          'material-icons': type === TYPES.FILLED
        })}
        sx={{ fontSize: size, color: materialColor }}
      >
        {icon}
      </Icon>
    ))
  );
}

MaterialIcon.propTypes = {
  icon: PropTypes.string, //*  Size
  iconColor: PropTypes.string,
  materialColor: PropTypes.string,
  size: PropTypes.string, //*  color
  type: PropTypes.oneOf([TYPES.FILLED, TYPES.OUTLINED])
};

MaterialIcon.defaultProps = {
  icon: 'add_circle',
  size: '2rem',
  iconColor: '#000000',
  type: TYPES.OUTLINED
};

export default MaterialIcon;
