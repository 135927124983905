import { gql } from '@apollo/client';

const getMyUsuario = gql`
  query {
    getMyUsuario {
      nombre
      apellido
      email
      role
      juzgado {
        nombre
        domicilio
      }
    }
  }
`;

export default {
  GET_MY_USER: getMyUsuario
};
