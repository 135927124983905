/* eslint-disable jsx-a11y/anchor-is-valid */
import paths from 'modules/auth/views/paths';
import Button from 'modules/core/components/Buttons/Button';
import Form, { ControllerInput } from 'modules/core/components/Forms/Form';
import { TextInput } from 'modules/core/components/Forms/Inputs';
import SectionTitleForm from 'modules/core/components/Templates/SectionTitleForm';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useGoogleRecaptchaV3 from 'modules/core/hooks/useGoogleRecaptchaV3';
import useNavigate from 'modules/core/hooks/useNavigate';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import * as Yup from 'yup';
/**
 *
 * Formulario de login
 * @returns
 */
function ResetPassEmailForm({
  onSubmit,
  template,
  templateProps,
  isSubmited = false
}) {
  const { translate } = useDecorator();
  const schema = Yup.object().shape({
    email: Yup.string()
      .nullable(translate('AUTH.VALIDATION_LOGIN_USER_NAME_REQUIRED'))
      .required(translate('AUTH.VALIDATION_LOGIN_USER_NAME_REQUIRED'))
  });
  const { handleSubmit } = useGoogleRecaptchaV3(
    process.env.REACT_APP_RECAPTCHA_V3_PUBLIC_KEY,
    onSubmit,
    `bottom: 90px!important;`
  );
  const { go } = useNavigate();
  const handleCancel = useCallback(() => {
    go(paths.login);
  }, [go]);
  return (
    <>
      {isSubmited && (
        <div className="alert alert-success" role="alert">
          {translate('AUTH.RECOVER_PASS_EMAIL_SENT')}
        </div>
      )}
      {!isSubmited && (
        <Form
          templateProps={{
            submitButtonProps: {
              children: translate('AUTH.RECOVER_SUBMIT_BUTTON')
            },
            buttons: {
              cancel: (
                <Button color="secondary" onClick={handleCancel}>
                  {translate('AUTH.RECOVER_CANCEL_BUTTON')}
                </Button>
              )
            },
            ...templateProps
          }}
          schema={schema}
          onSubmit={handleSubmit}
          template={template}
        >
          <SectionTitleForm>
            <ControllerInput
              render={TextInput}
              defaultValue=""
              name="email"
              label={translate('AUTH.LOGIN_FORM_ADD_LABEL_USERNAME')}
              placeholder={translate(
                'AUTH.LOGIN_FORM_ADD_PLACEHOLDER_USERNAME'
              )}
            />
          </SectionTitleForm>
        </Form>
      )}
    </>
  );
}

ResetPassEmailForm.propTypes = {
  onSubmit: PropTypes.func,
  template: PropTypes.any,
  templateProps: PropTypes.object,
  isSubmited: PropTypes.bool
};
export default ResetPassEmailForm;
