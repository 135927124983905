import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormHelperError from '../../Form/components/FormHelperError';
/**
 * Componente para select icon
 * @param {*} param0
 * @returns
 */
function SelectInput({
  label,
  value,
  list: _list = [],
  errorMessage,
  size,
  variant,
  helperText = null,
  service,
  filters,
  onChange,
  ...props
}) {
  const [list, setList] = useState(_list);
  const handleChange = useCallback(
    (e) => {
      // Busca el elemento en la lista
      const obj = _.find(list, (element) => element.id === e.target.value);
      if (onChange) {
        // Devuelve como segundo parametro todo el objeto
        onChange(e, _.get(obj, 'value'));
      }
    },
    [list]
  );

  const getData = useCallback(async () => {
    if (service) {
      const resultList = await service(filters);
      setList(resultList);
    }
  }, [service, filters]);
  useEffect(() => {
    if (getData) getData();
  }, [getData]);
  return (
    <FormControl
      sx={{ width: '100%' }}
      error={!!errorMessage}
      size={size}
      variant={variant}
    >
      <InputLabel>{label}</InputLabel>
      <Select value={value} label={label} onChange={handleChange} {...props}>
        {_.map(list, (element) => (
          <MenuItem value={element.id} key={element.id}>
            <em>{element.description}</em>
          </MenuItem>
        ))}
      </Select>
      <FormHelperError errorMessage={errorMessage} helperText={helperText} />
    </FormControl>
  );
}
SelectInput.propTypes = {
  errorMessage: PropTypes.string,
  filters: PropTypes.any,
  helperText: PropTypes.string,
  label: PropTypes.string,
  list: PropTypes.array,
  service: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'string']),
  value: PropTypes.any,
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  onChange: PropTypes.func
};

SelectInput.defaultProps = {
  errorMessage: null,
  label: null,
  variant: process.env.REACT_APP_STYLED_DEFAULT_INPUT_VARIANT || 'outlined',
  size: process.env.REACT_APP_STYLED_DEFAULT_INPUT_SIZE || 'small',
  helperText: null
};

export default SelectInput;
