import { gql } from '@apollo/client';

const addUsuario = gql`
  mutation addUsuario(
    $nombre: String!
    $apellido: String!
    $email: String!
    $juzgadoId: Int!
    $password: String!
  ) {
    addUsuario(
      nombre: $nombre
      apellido: $apellido
      email: $email
      juzgadoId: $juzgadoId
      password: $password
    ) {
      id
      nombre
      apellido
      email
      juzgadoId
    }
  }
`;
const editUsuario = gql`
  mutation editUsuario(
    $id: Int!
    $nombre: String!
    $apellido: String!
    $email: String!
    $juzgadoId: Int!
  ) {
    editUsuario(
      id: $id
      nombre: $nombre
      apellido: $apellido
      email: $email
      juzgadoId: $juzgadoId
    ) {
      id
      nombre
      apellido
      email
      juzgadoId
    }
  }
`;
const resetPassword = gql`
  mutation resetPassword($id: Int!, $newPassword: String!) {
    resetPassword(id: $id, newPassword: $newPassword) {
      id
    }
  }
`;
const deleteUsuario = gql`
  mutation deleteUsuarios($ids: [Int]!) {
    deleteUsuarios(ids: $ids)
  }
`;
export default {
  ADD_USUARIO: addUsuario,
  DELETE_USUARIO: deleteUsuario,
  EDIT_USUARIO: editUsuario,
  RESET_PASSWORD: resetPassword
};
