/* eslint-disable jsx-a11y/anchor-is-valid */
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import classes from './Footer.module.scss';
import HelpMessage from 'modules/core/components/HelpMessage';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import email from 'assets/images/mail-03.svg';
import primeroGente from 'assets/images/logo_primerolagente.svg';
/**
 * Footer de la aplicación
 * @returns Material Footer
 * //TODO ver al de la imagen
 */
function Footer() {
  const { translate } = useDecorator();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0}>
        <Box sx={{ flexGrow: 1 }} className={classes.Footer1}>
          <div className={classes.center}>
            <div
              className={classes.Contact}
              dangerouslySetInnerHTML={{
                __html: translate('ADMIN.FOOTER_CONTACT')
              }}
            ></div>
            <div className={classes.Alert}>
              <HelpMessage
                img={email}
                title={translate('ADMIN.ALERT_INFO_MAIL')}
              >
                {translate('ADMIN.ALERT_INFO_MAIL_MESSAGE')}

                <a
                  href={`mailto:${translate(
                    'ADMIN.ALERT_INFO_MAIL_MESSAGE_EMAIL'
                  )}`}
                >
                  {translate('ADMIN.ALERT_INFO_MAIL_MESSAGE_EMAIL')}
                </a>
              </HelpMessage>
            </div>
          </div>
        </Box>
        <Toolbar>
          <Box
            sx={{ flexGrow: 1, display: { sm: 'block' } }}
            className={classes.BlueFooter}
          >
            <div className={classes.center}>
              <img src={primeroGente} className={classes.logo} />
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Footer;
