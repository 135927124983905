import _ from 'lodash';
import useLazyQuery from 'modules/core/hooks/useLazyQuery/useLazyQuery';
import { useCallback } from 'react';
import queries from './gql/getUsers';
/**
 * Hook creado para el llamado a Graphql de obtener un usuario por ud
 * @returns
 */
function useGetUserById() {
  const [queryGetUser] = useLazyQuery({
    query: queries.GET_USER_BY_ID,
    options: { fetchPolicy: 'network-only' }
  });
  const getUser = useCallback(
    async (values) => {
      if (queryGetUser) {
        const result = await queryGetUser({ variables: values });
        return _.get(result, 'data.getUsuario', null);
      }
      return null;
    },
    [queryGetUser]
  );
  return {
    getUser
  };
}

export default useGetUserById;
