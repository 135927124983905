import PropTypes from 'prop-types';
import React from 'react';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import * as Yup from 'yup';
import Form, { ControllerInput } from 'modules/core/components/Forms/Form';
import PasswordInput from 'modules/core/components/Forms/Inputs/PasswordInput';
import SectionTitleForm from 'modules/core/components/Templates/SectionTitleForm';
/**
 *
 * Formulario de cambio de contraseña
 * @returns
 */
function ChangePasswordForm({
  onSubmit,
  template,
  templateProps,
  defaultValues
}) {
  const { translate } = useDecorator();
  const schema = Yup.object().shape({
    oldPassword: Yup.string()
      .required(translate('AUTH.VALIDATION_NEWPASSWORD_PASSWORD_REQUIRED'))
      .nullable(translate('AUTH.VALIDATION_NEWPASSWORD_PASSWORD_REQUIRED')),
    newPassword: Yup.string()
      .required(translate('AUTH.VALIDATION_NEWPASSWORD_NEWPASSWORD_REQUIRED'))
      .nullable(translate('AUTH.VALIDATION_NEWPASSWORD_NEWPASSWORD_REQUIRED')),
    repeatNewpassword: Yup.string()
      .oneOf(
        [Yup.ref('newPassword'), null],
        translate('AUTH.VALIDATION_NEWPASSWORD_REPEATNEWPASSWORD')
      )
      .nullable(translate('AUTH.VALIDATION_NEWPASSWORD_REPEATNEWPASSWORD'))
  });
  return (
    <Form
      templateProps={{
        submitButtonProps: {
          children: translate('AUTH.NEWPASSWORD_FORM_ADD_BUTTON', defaultValues)
        },
        ...templateProps
      }}
      schema={schema}
      onSubmit={onSubmit}
      template={template}
      defaultValues={defaultValues}
    >
      <SectionTitleForm>
        <ControllerInput
          render={PasswordInput}
          name="oldPassword"
          label={translate('AUTH.NEWPASSWORD_FORM_ADD_LABEL_PASSWORD')}
          placeholder={translate(
            'AUTH.NEWPASSWORD_FORM_ADD_PLACEHOLDER_PASSWORD'
          )}
        />
        <ControllerInput
          render={PasswordInput}
          name="newPassword"
          label={translate('AUTH.NEWPASSWORD_FORM_ADD_LABEL_NEWPASSWORD')}
          placeholder={translate(
            'AUTH.NEWPASSWORD_FORM_ADD_PLACEHOLDER_NEWPASSWORD'
          )}
        />
        <ControllerInput
          render={PasswordInput}
          name="repeatNewpassword"
          label={translate('AUTH.NEWPASSWORD_FORM_ADD_LABEL_REPEATNEWPASSWORD')}
          placeholder={translate(
            'AUTH.NEWPASSWORD_FORM_ADD_PLACEHOLDER_REPEATNEWPASSWORD'
          )}
        />
      </SectionTitleForm>
    </Form>
  );
}

ChangePasswordForm.propTypes = {
  defaultValues: PropTypes.any,
  onSubmit: PropTypes.func,
  template: PropTypes.any,
  templateProps: PropTypes.object
};
export default ChangePasswordForm;
