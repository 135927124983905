import { Stack } from '@mui/material';
import AlertTitle from 'modules/core/components/Alerts/AlertTitle';
import Button from 'modules/core/components/Buttons/Button';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useMachine from 'modules/core/contexts/MachineContext/useMachine';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import classModule from './ErrorDniMessageStep.module.scss';
import Styles from './ErrorDniMessageStep.style';
/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function ErrorDniMessageStep({ type }) {
  const { translate } = useDecorator();
  const { next, prev, context } = useMachine();
  // TODO: Ver si esta funcion se puede unir con la que es igual en el breadcrum
  const params = useMemo(() => {
    if (type === 'penalize') {
      return { penalize: true };
    }
    if (type === 'recover') {
      return { recover: true };
    }
    return null;
  }, [type]);
  const goToHome = useCallback(() => {
    next();
  }, [next]);
  const goToRestart = useCallback(() => {
    prev();
  }, [prev]);
  return (
    <div className={classModule.ErrorDniMessageStep}>
      <AlertTitle severity="error" {...Styles.Alert}>
        {translate('ADMIN.SCORING_ERROR_DNI', context)}
      </AlertTitle>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="flex-end"
        {...Styles.Botonera}
      >
        <Button color="secondary" onClick={goToRestart} {...Styles.Buttons}>
          {translate('ADMIN.SCORING_ADD_NEW', params)}
        </Button>
        <Button onClick={goToHome} {...Styles.Buttons}>
          {translate('ADMIN.SCORING_GO_DASHBOARD')}
        </Button>
      </Stack>
    </div>
  );
}

ErrorDniMessageStep.propTypes = {
  type: PropTypes.string
};

export default ErrorDniMessageStep;
