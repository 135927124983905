/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Lottie from 'react-lottie';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import classesModule from './ErrorStep.module.scss';
import Typography from '../../../Typographies/Typography';
import imgError from '../../../../assets/error404.json';

/**
 * Componente vista para cuando no se encuentra un paso
 * @param {*} param0
 * @returns
 */
function ErrorStep() {
  const { translate } = useDecorator();

  return (
    <div className={`${classesModule.ErrorStep}`}>
      <Lottie
        width="50%"
        height="50%"
        options={{
          loop: true,
          autoplay: true,
          animationData: imgError
        }}
      />
      <Typography variant="h3">{translate('CORE.MESSAGE_STEP')}</Typography>
    </div>
  );
}

export default ErrorStep;
