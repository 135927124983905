import _ from 'lodash';
import RadioInput from 'modules/core/components/Forms/Inputs/RadioInput';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import { SEX } from 'modules/sisco/constants';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
/**
 * Componente que busca y trae los juzgados
 * @param {*} param0
 * @returns
 */
function SexSelectInput({ children, label, helperText = null, ...props }) {
  const { translate } = useDecorator();
  const defaultLabel = useMemo(
    () => (translate ? translate('SISCO.LABEL_SEX') : ''),
    [translate]
  );

  const list = useMemo(() => {
    if (translate) {
      return _.map(SEX, (sex) => ({
        id: sex,
        description: translate(`SISCO.SEX_LABEL_${sex}`)
      }));
    }
    return [];
  }, [translate]);

  return (
    <RadioInput
      label={label || defaultLabel}
      {...props}
      list={list}
      helperText={helperText}
    />
  );
}

SexSelectInput.propTypes = {
  children: PropTypes.node,
  label: PropTypes.any,
  helperText: PropTypes.string
};
export default SexSelectInput;
