import _ from 'lodash';
import useMutation from 'modules/core/hooks/useMutation/useMutation';
import { useCallback } from 'react';
import mutations from './gql/mutationUsers';
/**
 * Hook creado para el llamado a Graphql de servicios de usuarios
 * @returns
 */
function useAddUser() {
  const [mutationAdd] = useMutation({
    mutation: mutations.ADD_USUARIO
  });
  const addUser = useCallback(
    async (params) => {
      if (mutationAdd) {
        const result = await mutationAdd({ variables: params });
        const aux = _.get(result, 'data.addUsuario', null);
        return aux;
      }
      return null;
    },
    [mutationAdd]
  );

  return {
    addUser
  };
}

export default useAddUser;
