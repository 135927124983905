import HelpMessage from 'modules/core/components/HelpMessage';
import WizardTemplate from 'modules/core/components/Templates/WizardTemplate';
import Typography from 'modules/core/components/Typographies/Typography';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useMachine from 'modules/core/contexts/MachineContext/useMachine';
import useGoogleRecaptchaV3 from 'modules/core/hooks/useGoogleRecaptchaV3';
import ConsultScoringForm from 'modules/public/forms/ConsultScoringForm';
import React, { useCallback, useMemo } from 'react';
import classModule from './classes.module.scss';

/**
 * Step para consultar los datos de la persona a ver el scoring
 * @param {*} param0
 * @returns
 */
function SelectPersonStep() {
  const { next } = useMachine();

  const { translate } = useDecorator();

  const callServer = useCallback(
    async (params) => {
      next(params);
    },
    [next]
  );
  // TODO: ver como mejorar el bottom de GoogleCaptchaV3

  const { handleSubmit } = useGoogleRecaptchaV3(
    process.env.REACT_APP_RECAPTCHA_V3_PUBLIC_KEY,
    callServer,
    `bottom: 90px!important;`
  );
  const components = useMemo(() => {
    if (translate) {
      return {
        next: {
          children: translate('PUBLIC.WIZARD_SCORING_BUTTON_STEP1_NEXT')
        },
        prev: null
      };
    }
    return {};
  }, [translate]);

  return (
    <>
      <div className={classModule.Alert}>
        <HelpMessage icon="info" title={translate('PUBLIC.ALERT_INFO')}>
          {translate('PUBLIC.ALERT_INFO_MESSAGE')}
        </HelpMessage>
      </div>
      <Typography>{translate('PUBLIC.INTROTEXT_SCORING')}</Typography>
      <ConsultScoringForm
        template={WizardTemplate}
        onSubmit={handleSubmit}
        templateProps={{ components }}
      />
    </>
  );
}
export default SelectPersonStep;
