import PropTypes from 'prop-types';
import DataGrid from 'modules/core/components/DataGrid';
import React from 'react';
import useDataGridAttributes from './hooks/useDataGridAttributes';
import classesModule from './ConsultScoringUser.module.scss';
/**
 * Página de scoring
 * @param {*} param0
 * @returns
 */
function ConsultScoringUser({ list }) {
  const { columns, defaultFilters, defaultOrder, defaultPageSize } =
    useDataGridAttributes();
  return (
    <div className={`${classesModule.main}`}>
      <DataGrid
        checkboxSelection={false}
        columns={columns}
        list={list}
        defaultFilters={defaultFilters}
        defaultOrder={defaultOrder}
        defaultPageSize={defaultPageSize}
        mode="client"
      />
    </div>
  );
}

ConsultScoringUser.propTypes = {
  list: PropTypes.any
};
export default ConsultScoringUser;
