import React from 'react';
import ModalContext from '.';
/**
 * Contexto para el uso de modales
 * @returns
 */
function useModal() {
  const { alert, confirm, confirmMultiple } = React.useContext(ModalContext);
  return { alert, confirm, confirmMultiple };
}

export default useModal;
