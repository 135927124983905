import _ from 'lodash';
import MaterialButtonIcon from 'modules/core/components/Buttons/MaterialButtonIcon';
import MaterialMenuButtonIcon from 'modules/core/components/MaterialMenuButtonIcon/MaterialMenuButtonIcon';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useModal from 'modules/core/contexts/ModalContext/useModal';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

/**
 * Botonera creada para la pantalla de Listado de motivos
 * @param {*} param0
 * @returns
 */
function Botonera({ selectedRows = [], onDelete, onAdd, onEdit, onRefresh }) {
  const { translate } = useDecorator();
  const { confirm } = useModal();
  /**
   * Función para ejecutar cuando se quiere refrescar la tabla
   */
  const handleRefresh = useCallback(() => {
    if (onRefresh) onRefresh();
  }, [onRefresh]);
  /**
   * Función para ejecutar cuando se quiere agregar un elemento
   */
  const handleAddElement = useCallback(() => {
    if (onAdd) onAdd();
  }, [onAdd]);
  /**
   * Función para ejecutar cuando se quiere editar un elemento
   */
  const handleEditElement = useCallback(() => {
    if (onEdit) onEdit(selectedRows[0]);
  }, [onEdit, selectedRows]);
  /**
   * Función para ejecutar cuando se quiere borrar varios elementos
   */
  const handleDeleteElements = useCallback(async () => {
    const resp = await confirm.current.open(
      translate('ADMIN.MOTIVOS_CONFIRM_MESSAGE_DELETE_MOTIVOS'),
      {
        title: translate('ADMIN.MOTIVOS_CONFIRM_TITLE_DELETE_MOTIVOS')
      }
    );
    if (resp && onDelete) {
      onDelete(selectedRows);
    }
  }, [onDelete, confirm, translate, selectedRows]);

  return (
    <MaterialMenuButtonIcon tooltip="Título de prueba">
      <MaterialButtonIcon
        icon="add"
        tooltip={translate('ADMIN.TOOLTIP_BUTTON_ADD_MOTIVOS')}
        onClick={handleAddElement}
      />
      <MaterialButtonIcon
        disabled={_.isEmpty(selectedRows) || selectedRows.length > 1}
        icon="edit_note"
        onClick={handleEditElement}
        tooltip={translate('ADMIN.TOOLTIP_BUTTON_EDIT_MOTIVOS')}
      />
      <MaterialButtonIcon
        icon="refresh"
        onClick={handleRefresh}
        tooltip={translate('ADMIN.TOOLTIP_BUTTON_REFRESH_MOTIVOS')}
      />
      <MaterialButtonIcon
        color="#d50000"
        disabled={_.isEmpty(selectedRows)}
        icon="delete"
        onClick={handleDeleteElements}
        tooltip={translate('ADMIN.TOOLTIP_BUTTON_DELETE_MOTIVOS')}
      />
    </MaterialMenuButtonIcon>
  );
}

Botonera.propTypes = {
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onRefresh: PropTypes.func,
  selectedRows: PropTypes.array
};
export default Botonera;

// ADMIN.MOTIVOS_CONFIRM_MESSAGE_DELETE_MOTIVOS
// ADMIN.MOTIVOS_CONFIRM_TITLE_DELETE_MOTIVOS
// ADMIN.TOOLTIP_BUTTON_ADD_MOTIVOS
// ADMIN.TOOLTIP_BUTTON_EDIT_MOTIVOS
// ADMIN.TOOLTIP_BUTTON_DELETE_MOTIVOS
