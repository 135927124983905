import _ from 'lodash';
import useMutation from 'modules/core/hooks/useMutation/useMutation';
import { useCallback } from 'react';
import mutations from './gql/mutationMotivos';
/**
 * Hook creado para el llamado a Graphql de servicios de motivos
 * @returns
 */
function useAddMotivo() {
  const [mutationAdd] = useMutation({
    mutation: mutations.ADD_MOTIVO
  });

  const addMotivo = useCallback(
    async (params) => {
      if (mutationAdd) {
        const result = await mutationAdd({ variables: params });
        const aux = _.get(result, 'data.addMotivo', null);
        return aux;
      }
      return null;
    },
    [mutationAdd]
  );
  return {
    addMotivo
  };
}

export default useAddMotivo;
