import React from 'react';
import SettingsContext from '.';

/**
 * Hooks para manejar settigns globales del proyecto configuradas en el provider
 * @returns
 */
function useSettings() {
  const {
    paths,
    requestGetErrorFunction,
    requestGetDataFunction,
    hasAccess,
    hasRoleAccess,
    hasSessionAccess,
    iconsPrefix
  } = React.useContext(SettingsContext);
  return {
    paths,
    requestGetErrorFunction,
    requestGetDataFunction,
    hasAccess,
    hasRoleAccess,
    hasSessionAccess,
    iconsPrefix
  };
}

export default useSettings;
