import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import Session from './Session';

/**
 * Componente que setea los datos del usuario
 * @param {*} param0
 * @returns
 */
function SessionManager() {
  const token = useSelector((store) => _.get(store, 'session.token'));
  const user = useSelector((store) => _.get(store, 'session.user', null));
  if (token && !user) {
    return <Session />;
  }
  return null;
}

export default SessionManager;
