import _ from 'lodash';
import useSettings from 'modules/core/contexts/SettingsContext/useSettings';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useNavigate from 'modules/core/hooks/useNavigate';
import React from 'react';
import Access from 'modules/core/components/Security/Access';
import { ROLES } from 'modules/sisco/constants';
import Stack from '@mui/material/Stack';
import LinkSisco from 'modules/sisco/components/LinkSisco/LinkSisco';
import Typography from 'modules/core/components/Typographies/Typography';
import falta from '../../assets/images/motivos.png';
import jury from '../../assets/images/juzgados.png';

import scoringRecover from '../../assets/images/recuperar_puntos.png';
import scoringPenalize from '../../assets/images/descontar_puntos.png';
import history from '../../assets/images/consulta_scoring.png';
import usersAdmin from '../../assets/images/usuarios_1.svg';
import scoringList from '../../../../assets/images/scorelista.svg';
import Card from './components/Card';
import classesModule from './DashboardView.module.scss';
import pdf from '../../assets/pdfs/Mi_Scoring._Manual_del_usuario.pdf';
/**
 * Dashboard principal de la aplicacion
 * @param {*} param0
 * @returns
 */
function DashboardView() {
  const { translate } = useDecorator();
  const { go } = useNavigate();
  const { paths } = useSettings();
  return (
    <div className={` ${classesModule.DashboardView} `}>
      <div className={` ${classesModule.DashboardViewGrid} `}>
        <Access access={{ roles: [ROLES.ADMIN] }}>
          <Card
            description={translate('ADMIN.INTROTEXT_JUZGADOS_LIST_VIEW')}
            img={jury}
            onClick={() => go(_.get(paths, 'ADMIN.juzgados'))}
          />
        </Access>
        <Access access={{ roles: [ROLES.ADMIN] }}>
          <Card
            description={translate('ADMIN.INTROTEXT_MOTIVOS_LIST_VIEW')}
            img={falta}
            onClick={() => go(_.get(paths, 'ADMIN.motivos'))}
          />
        </Access>

        <Card
          description={translate('ADMIN.INTROTEXT_SCORING_LIST_PENALIZE_VIEW')}
          img={scoringPenalize}
          onClick={() =>
            go(_.get(paths, 'ADMIN.scoring'), { type: 'penalize' })
          }
        />

        <Card
          description={translate('ADMIN.INTROTEXT_SCORING_LIST_REOVER_VIEW')}
          img={scoringRecover}
          onClick={() => go(_.get(paths, 'ADMIN.scoring'), { type: 'recover' })}
        />

        <Card
          description={translate('AUTH.INTROTEXT_LIST_SCORING_VIEW')}
          img={history}
          onClick={() => go(_.get(paths, 'AUTH.consultAuthScoring'))}
        />

        <Access access={{ roles: [ROLES.ADMIN] }}>
          <Card
            description={translate('ADMIN.INTROTEXT_USERS_LIST_VIEW')}
            img={usersAdmin}
            onClick={() => go(_.get(paths, 'ADMIN.user'))}
          />
        </Access>
        <Access access={{ roles: [ROLES.ADMIN] }}>
          <Card
            description={translate('ADMIN.INTROTEXT_PERSONLIST_WITH_FILTERS')}
            img={scoringList}
            onClick={() => go(_.get(paths, 'ADMIN.personList'))}
          />
        </Access>
      </div>

      <Stack
        direction="row-reverse"
        spacing={2}
        className={classesModule.containerLink}
      >
        <LinkSisco
          href={pdf}
          target="manual scoring"
          rel="noopener noreferrer"
          download="Mi_Scoring._Manual_del_usuario.pdf"
        >
          <Typography>
            {translate('ADMIN.LINK_DASHBOARD_MANUAL_USUER')}
          </Typography>
        </LinkSisco>
      </Stack>
    </div>
  );
}
export default DashboardView;
