import { FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import React from 'react';
import PropTypes from 'prop-types';
import FormHelperError from '../../Form/components/FormHelperError';

/**
 * Compontente que hace los TextInputs
 * @param {*} param0
 * @returns
 */
function TextInput({
  children,
  errorMessage,
  variant = 'outlined',
  value,
  size,
  label,
  helperText = null,
  ...props
}) {
  return (
    <FormControl sx={{ width: '100%' }} error={!!errorMessage}>
      <TextField
        variant={variant}
        label={label}
        size={size}
        {...props}
        value={value}
      />
      <FormHelperError errorMessage={errorMessage} helperText={helperText} />
    </FormControl>
  );
}
TextInput.propTypes = {
  children: PropTypes.node, //* Variant style component
  value: PropTypes.any,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'string']),
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard'])
};

TextInput.defaultProps = {
  children: null,
  variant: process.env.REACT_APP_STYLED_DEFAULT_INPUT_VARIANT || 'outlined',
  size: process.env.REACT_APP_STYLED_DEFAULT_INPUT_SIZE || 'small',
  errorMessage: null,
  label: null,
  helperText: null
};
export default TextInput;
