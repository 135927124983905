import { gql } from '@apollo/client';

const getPersona = gql`
  query getPersona(
    $id: Int
    $tipoDocumento: TipDocumento
    $documento: String
    $sexo: Sexo
  ) {
    getPersona(
      id: $id
      tipoDocumento: $tipoDocumento
      documento: $documento
      sexo: $sexo
    ) {
      id
      tipoDocumento
      documento
      sexo
      nombre
      direccion
      telefono
      tipoLicencia
      email
      __typename
    }
  }
`;
export default {
  GET_PERSONA: getPersona
};
