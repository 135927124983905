import _ from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useSettings from '../contexts/SettingsContext/useSettings';
import useSessionStore from '../store/session/useSessionStore';
// import { sessionLogin, sessionLogout } from '../store/session/actions';
import useNavigate from './useNavigate';

/**
 * Hook para el manejo de session
 * @returns
 */
const useSession = () => {
  // const dispatch = useDispatch();
  const { paths } = useSettings();
  const session = useSelector((store) => _.get(store, 'session'));
  const { hasRoleAccess: hasRoleAccessCustom } = useSettings();
  const { token, user } = session;
  const {
    setLoading,
    setToken,
    setMeData: setMeDataStore,
    setAccess: setAccessStore,
    logout: logoutStore
  } = useSessionStore();
  const { go } = useNavigate();
  /**
   * Función que hace un llamado para la renovación del token
   */
  const renewToken = () => {
    // sessionLogin(dispatch,props)
  };
  /**
   * Hace el login si le doy el token
   * @param {string} token
   * @param {string} nextPageKey
   */
  const loginToken = async (tokenParam, nextPageKey, actionBeforeRedirect) => {
    await setToken(tokenParam);
    if (actionBeforeRedirect) {
      await actionBeforeRedirect();
    }
    const newPage = _.get(paths, `${nextPageKey}`, '/');
    go(newPage);
  };
  /**
   * Login
   */
  const login = async (tokenParam) => {
    setLoading(true);
    await setToken(tokenParam);
  };
  /**
   *Logout
   */
  const logout = async () => {
    logoutStore();
    go('/');
  };
  /**
   * Setea los datos de getMe en la session
   * @param {*} params
   */
  const setMeData = async (params) => {
    setMeDataStore(params);
  };
  /**
   * Setea los accessos en la session
   * @param {*} params
   */
  const setAccess = async (params) => {
    setAccessStore(params);
  };
  /**
   * Funcion para detectar si tiene o no acceso a algo por medio de los roles
   */
  const hasRoleAccess = useCallback(
    (needRoles) => {
      if (hasRoleAccessCustom) {
        return hasRoleAccessCustom(needRoles, session);
      }
      /** Funcion para chequear si los elementos de un array estand dentro de otro array */
      const checker = (arr, target) => target.every((v) => arr.includes(v));
      const roles = _.get(session, 'access.roles', []);
      return checker(roles, needRoles);
    },
    [session, hasRoleAccessCustom]
  );
  const hasSessionAccess = useCallback(
    (needSession) => {
      if (needSession === null) {
        return true;
      }
      const hasSession = session && session.token;
      if (needSession && !hasSession) return false;
      if (!needSession && hasSession) return false;
      return true;
    },
    [session]
  );
  const hasAccess = useCallback(
    (route) => {
      const needRoles = _.get(route, 'access.roles', []);
      const needSession = _.get(route, 'access.session', null);
      const sessionAccess = hasSessionAccess(needSession);
      if (!sessionAccess) return false;
      const roleAccess = hasRoleAccess(needRoles);
      if (!roleAccess) return false;
      return true;
    },
    [hasRoleAccess, hasSessionAccess]
  );

  return {
    login,
    logout,
    loginToken,
    renewToken,
    setMeData,
    setAccess,
    session,
    hasRoleAccess,
    hasSessionAccess,
    hasAccess,
    token,
    user
  };
};
export default useSession;
