import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormHelperError from '../../Form/components/FormHelperError';
import { AUTO } from './constants';
/**
 * Componente de radio
 * @param {*} param0
 * @returns
 */
function RadioInput({
  errorMessage,
  label,
  row = false,
  list: _list = [],
  service,
  filters,
  helperText = null,
  size,
  onChange,
  name,
  auto = 'none',
  value,
  ...props
}) {
  const [list, setList] = useState(_list);
  const { setValue } = useFormContext();
  const getData = useCallback(async () => {
    if (service) {
      const resultList = await service(filters);
      setList(resultList);
    }
  }, [service, filters]);
  useEffect(() => {
    if (!value && auto !== 'none' && !_.isEmpty(list) && list.length > 0) {
      if (onChange) {
        onChange(null, _.get(list[0], 'value'));
      }
      setValue(name, list[0].id);
    }
  }, [list, name, value, setValue, auto, onChange]);

  useEffect(() => {
    if (getData) getData();
  }, [getData]);
  const handleChange = useCallback(
    (e) => {
      const obj = _.find(
        list,
        (element) => element.id.toString() === e.target.value.toString()
      );
      if (onChange) {
        // Devuelve como segundo parametro todo el objeto
        onChange(e, _.get(obj, 'value'));
      }
    },
    [list, onChange]
  );
  return (
    <FormControl sx={{ width: '100%' }} error={!!errorMessage}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        {...props}
        value={value}
        name={name}
        onChange={handleChange}
        row={row}
      >
        {_.map(list, (element) => (
          <FormControlLabel
            key={element.id}
            value={element.id}
            control={<Radio size={size} />}
            label={element.description}
          />
        ))}
      </RadioGroup>
      <FormHelperError errorMessage={errorMessage} helperText={helperText} />
    </FormControl>
  );
}
RadioInput.propTypes = {
  /** Auto */
  auto: PropTypes.oneOf(Object.values(AUTO)),
  errorMessage: PropTypes.string, //* Etiqueta
  filters: PropTypes.any,
  helperText: PropTypes.string,
  label: PropTypes.string, //* orientacion de la lista
  list: PropTypes.array,
  name: PropTypes.any,
  onChange: PropTypes.func,
  row: PropTypes.bool,
  service: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'string']),
  value: PropTypes.any
};

RadioInput.defaultProps = {
  errorMessage: null,
  label: null,
  row: true,
  helperText: null,
  size: process.env.REACT_APP_STYLED_DEFAULT_INPUT_SIZE || 'small'
};

export default RadioInput;
export { AUTO };
