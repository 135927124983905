import React from 'react';
import IcoMoon from 'react-icomoon';
import PropTypes from 'prop-types';

const iconSet = require('./icons/selection.json');

/**
 * Componente de iconos
 * @param {*} param0
 * @returns
 */
function Icon({ icon, icon: _icon, size, ...props }) {
  return (
    <IcoMoon
      iconSet={iconSet}
      disableFill
      icon={_icon}
      size={size}
      {...props}
    />
  );
}
Icon.propTypes = {
  /**  Icon */
  icon: PropTypes.string,
  /**  Size */
  size: PropTypes.string
};
Icon.defaultProps = {
  icon: 'plus',
  size: '1.2rem'
};

export default Icon;
