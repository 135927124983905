import React from 'react';
import LoggerContext from '.';

/**
 * Hook de log
 * @returns
 */
function useLogger() {
  const { error, warning, log } = React.useContext(LoggerContext);
  return { error, warning, log };
}

export default useLogger;
