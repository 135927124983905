import React, { useMemo } from 'react';
import useMachine from 'modules/core/contexts/MachineContext/useMachine';
import _ from 'lodash';
import ErrorStep from '../ErrorStep';

/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function Step() {
  const { steps, state } = useMachine();

  /**
   * Calculo el Step actual
   */
  const { StepRender, renderProps } = useMemo(
    () => ({
      StepRender: _.get(steps, `${state.value}.render`, ErrorStep),
      renderProps: _.get(steps, `${state.value}.renderProps`, {})
    }),
    [steps, state.value]
  );
  return <StepRender {...renderProps} />;
}
export default Step;
