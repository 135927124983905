import { gql } from '@apollo/client';

const deleteScoring = gql`
  mutation deleteScoring($ids: [Int]!) {
    deleteScoring(ids: $ids)
  }
`;

export default {
  DELETE_SCORING: deleteScoring
};
