import PropTypes from 'prop-types';
import React from 'react';
import MaterialTypo from '@mui/material/Typography';

/**
 * Componente de react que muestras los diferentes tipografias
 * @param {*} param0
 * @returns
 */
function Typography({
  children,
  variant,
  component,
  className,
  color,
  ...props
}) {
  return (
    <MaterialTypo
      variant={variant}
      component={component}
      className={className}
      color={color}
      {...props}
    >
      {children}
    </MaterialTypo>
  );
}

Typography.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'button',
    'caption',
    'overline'
  ]),
  component: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'span',
    'p',
    'div',
    'label'
  ]),
  className: PropTypes.any
};

Typography.defaultProps = {
  variant: 'body1',
  component: 'span'
};

export default Typography;
