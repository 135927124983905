import RecoverForm from 'modules/auth/forms/RecoverForm';
import { useCallback, useState } from 'react';

import useAuthServices from '../../services/useAuthServices';
import classesModule from './RecoverView.module.scss';
/**
 * Página de Login
 * @param {*} param0
 * @returns
 */
function RecoverView() {
  const { recover: recoverService } = useAuthServices();

  const [isSubmited, setIsSubmited] = useState(false);
  const handleSubmit = useCallback(
    async (values) => {
      await recoverService(values);
      setIsSubmited(true);
    },
    [recoverService]
  );
  return (
    <div className={`${classesModule.main}`}>
      <RecoverForm onSubmit={handleSubmit} isSubmited={isSubmited} />
    </div>
  );
}
export default RecoverView;
