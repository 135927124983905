import { esES } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import materialAppStyles from './materialAppStyles.scss';

const {
  appColorPrimary,
  appColorSecondary,
  appColorBackground,
  appDataPaperBackground,
  appDatagridStrip,
  appDatagridStripHover
} = materialAppStyles;

// TODO: Ver por que necesite poner el important en el h1
const theme = createTheme({
  shape: {
    borderRadius: 5
  },
  palette: {
    primary: {
      main: appColorPrimary
    },
    secondary: {
      main: appColorSecondary
    },
    background: {
      default: appColorBackground
    },
    paper: {
      default: appDataPaperBackground
    }
  },
  typography: {
    fontFamily: ['"Encode Sans"', 'Montserrat', 'Material Icons'].join(','),
    body1: {
      fontSize: '1.1rem',
      fontWeight: 400
    },
    button: {
      fontWeight: 600
    },
    h1: {
      fontWeight: 600,
      fontSize: '1.5rem!important',
      marginBottom: '1rem'
    },
    h2: {
      fontWeight: 600
    },
    h3: {
      fontWeight: 600
    },
    subtitle1: {
      fontSize: '0.8rem!important'
    },
    subtitle2: {
      fontSize: '0.6rem!important'
    }
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          boxShadow: '0',
          border: '0',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main'
          },
          '& .MuiDataGrid-row:nth-of-type(odd)': {
            backgroundColor: appDatagridStrip
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: `${appDatagridStripHover} !important`
          }
        },
        filterFormValueInput: {
          width: '300px'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            padding: '0.625rem'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          // fontWeight: 500,
          padding: '0.25rem'
        },
        notchedOutline: {
          borderWidth: '0.125rem'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 500,
          padding: '0.25rem'
        },
        outlined: {
          '&.MuiInputLabel-shrink': {
            transform: 'translate(0.8125rem, -0.75rem) scale(0.77)'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 400,
          em: {
            fontStyle: 'normal !important'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          em: {
            fontStyle: 'normal !important'
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 600,
          fontFamily: ['"Encode Sans"', 'Montserrat', 'Material Icons'].join(
            ','
          )
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          fontSize: '1rem',
          fontWeight: 600
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          textTransform: 'none',
          fontSize: '1rem',
          minWidth: '6.25rem',
          minHeight: '2rem'
          // boxShadow: 'none',
          // '&:hover': {
          //   boxShadow: 'none'
          // }
        },
        outlined: {
          textTransform: 'none',
          fontSize: '1rem',
          fontWeight: 'bold',
          minWidth: '6.25rem',
          minHeight: '2rem',
          borderWidth: '0.125rem',
          borderColor: appColorPrimary,
          '&:hover': {
            borderWidth: '0.125rem'
          },
          '&:disabled': {
            borderWidth: '0.125rem'
          }
        },
        text: {
          textTransform: 'none',
          fontSize: '1rem',
          fontWeight: 'bold',
          minWidth: '6.25rem',
          minHeight: '2rem'
        },
        string: {
          textTransform: 'none',
          fontSize: '1rem',
          fontWeight: 'bold',
          minWidth: '6.25rem',
          minHeight: '2rem'
        }
      }
    }
  },
  esES
});

export default theme;
