import UserForm from 'modules/admin/forms/UserForm';
import UserFormEdit from 'modules/admin/forms/UserFormEdit';
import UserFormReset from 'modules/admin/forms/UserFormReset';
import {
  useAddUser,
  useGetUserById,
  useGetUsersTable,
  useDeleteUser,
  useEditUser
} from 'modules/admin/services/useUserServices';
import DataGrid from 'modules/core/components/DataGrid';
import Modal from 'modules/core/components/Modals/Modal';
import useDecorator from 'modules/core/contexts/DecoratorContext/useDecorator';
import useLogger from 'modules/core/contexts/LoggerContext/useLogger';
import React, { useCallback, useRef, useState } from 'react';
import Botonera from './components/Botonera';
import useDataGridAttributes from './hooks/useDataGridAttributes';
import classesModule from './UserView.module.scss';
/**
 * Página de motivos
 * @param {*} param0
 * @returns
 */
function UserView() {
  /** Configuraciones y Helpers */
  const { translate } = useDecorator();
  const { error: errorLogger } = useLogger();
  const { columns, defaultFilters, defaultOrder, defaultPageSize } =
    useDataGridAttributes();
  /** Servicios */
  const { getUsersTable } = useGetUsersTable();
  const { addUser } = useAddUser();
  const { getUser } = useGetUserById();
  const { deleteUser } = useDeleteUser();
  const { editUser, resetPassword } = useEditUser();

  /** Estados */
  const [selectedRows, setSelectedRows] = useState([]);
  const [elementToEdit, setElementToEdit] = useState(null);
  /** Referencias */
  const modalAddRef = useRef();
  const modalResetRef = useRef();
  const tableRef = useRef();
  /** Funciones handlers */
  const handleSelect = useCallback(
    (newSelectedRows) => {
      setSelectedRows(newSelectedRows);
    },
    [setSelectedRows]
  );
  const handleRefresh = useCallback(() => {
    handleSelect([]);
    tableRef.current.refresh();
  }, [handleSelect, tableRef]);
  const handleAdd = useCallback(() => {
    setElementToEdit(null);
    modalAddRef.current.open();
  }, [modalAddRef]);

  const handleResetPassword = useCallback(
    (id) => {
      try {
        setElementToEdit({ id });
        modalResetRef.current.open();
      } catch (e) {
        errorLogger(e);
      }
    },
    [errorLogger, modalResetRef]
  );
  const handleResetSubmit = useCallback(
    async (values) => {
      try {
        await resetPassword({ id: elementToEdit?.id, ...values });
        setElementToEdit(null);
        modalResetRef.current.close();
      } catch (e) {
        errorLogger(e);
      }
    },
    [elementToEdit?.id, errorLogger, resetPassword]
  );
  const handleEdit = useCallback(
    async (id) => {
      try {
        const user = await getUser({ id });
        setElementToEdit(user);
        modalAddRef.current.open();
      } catch (e) {
        errorLogger(e);
      }
    },
    [modalAddRef, getUser, errorLogger]
  );

  const handleDelete = useCallback(
    async (ids) => {
      try {
        await deleteUser({ ids });
        handleRefresh();
      } catch (e) {
        errorLogger(e);
      }
    },
    [handleRefresh, deleteUser, errorLogger]
  );

  const handleSubmit = useCallback(
    async (values) => {
      try {
        if (elementToEdit) {
          await editUser({ id: elementToEdit.id, ...values });
        } else {
          await addUser(values);
        }
        tableRef.current.refresh();
        modalAddRef.current.close();
      } catch (e) {
        errorLogger(e);
      }
    },
    [addUser, editUser, elementToEdit, errorLogger]
  );

  return (
    <div className={`${classesModule.main}`}>
      <Botonera
        selectedRows={selectedRows}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onRefresh={handleRefresh}
        onResetPassword={handleResetPassword}
      />

      <DataGrid
        columns={columns}
        service={getUsersTable}
        defaultFilters={defaultFilters}
        defaultOrder={defaultOrder}
        defaultPageSize={defaultPageSize}
        onSelectionModelChange={handleSelect}
        innerRef={tableRef}
        mode="client"
      />
      <Modal
        innerRef={modalAddRef}
        maxWidth="sm"
        templateProps={{
          title: translate('ADMIN.USERS_FORM_ADD_EDIT_TITLE', elementToEdit)
        }}
      >
        {elementToEdit ? (
          <UserFormEdit onSubmit={handleSubmit} defaultValues={elementToEdit} />
        ) : (
          <UserForm onSubmit={handleSubmit} defaultValues={elementToEdit} />
        )}
      </Modal>

      <Modal
        innerRef={modalResetRef}
        maxWidth="sm"
        templateProps={{
          title: translate('ADMIN.USERS_FORM_ADD_EDIT_TITLE', elementToEdit)
        }}
      >
        <UserFormReset
          onSubmit={handleResetSubmit}
          defaultValues={elementToEdit}
        />
      </Modal>
    </div>
  );
}
export default UserView;
