import React from 'react';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import classesModule from './MaterialMenuButtonIcon.module.scss';
import Typography from '../Typographies/Typography';

/**
 * Material Menu Button Icon
 * @param {*} param0
 * @returns
 */
function MaterialMenuButtonIcon({ children, title }) {
  return (
    <div className={classesModule.contenedor}>
      <Typography variant="h1" className={classesModule.title}>
        {title}
      </Typography>
      <div className={classesModule.border} />
      <div className={classesModule.btnsContainer}>
        <div>
          <Stack direction="row" spacing={1}>
            {children}
          </Stack>
        </div>
      </div>
    </div>
  );
}

MaterialMenuButtonIcon.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string
};

export default MaterialMenuButtonIcon;
