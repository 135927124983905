import PropTypes from 'prop-types';
import React, { useCallback, useImperativeHandle, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import ModalTemplate from '../../Templates/ModalTemplate';

/**
 *Es un componente que solo activa un modal
 * @param {*} param0
 * @returns
 */
function Modal({
  templateProps,
  children,
  template: Template = ModalTemplate,
  fullWidth = true,
  innerRef,
  maxWidth = 'md',
  onClose,
  open: _open = false,
  ...props
}) {
  const [open, setOpen] = useState(_open);
  const handleClose = useCallback(() => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  }, [setOpen]);
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  useImperativeHandle(
    innerRef,
    () => ({
      isOpen: open,
      open: handleOpen,
      close: handleClose
    }),
    [open, handleClose, handleOpen]
  );
  return (
    <Dialog open={open} fullWidth={fullWidth} maxWidth={maxWidth} {...props}>
      <Template {...templateProps} onClose={handleClose}>
        {children}
      </Template>
    </Dialog>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  content: PropTypes.string,
  fullWidth: PropTypes.bool,
  icon: PropTypes.string,
  innerRef: PropTypes.any,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'md']),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  template: PropTypes.any,
  templateProps: PropTypes.object,
  title: PropTypes.string
};
export default Modal;
