import _ from 'lodash';
import useMutation from 'modules/core/hooks/useMutation/useMutation';
import { useCallback } from 'react';
import mutations from './gql/mutationMotivos';
/**
 * Hook creado para el edit a Graphql de servicios de motivo
 * @returns
 */
function useEditMotivo() {
  const [mutationEdit] = useMutation({
    mutation: mutations.EDIT_MOTIVO
  });
  const editMotivo = useCallback(
    async (params) => {
      if (mutationEdit) {
        const result = await mutationEdit({ variables: params });
        const aux = _.get(result, 'data.editMotivo', null);
        return aux;
      }
      return null;
    },
    [mutationEdit]
  );
  return {
    editMotivo
  };
}

export default useEditMotivo;
