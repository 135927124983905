export default {
  title_form_login: 'Ingresar a SISco',
  VALIDATION_CONSULTSCORING_TIPO_DOCUMENTO_REQUIRED:
    'El tipo de documento es requerido',
  VALIDATION_CONSULTSCORING_DOCUMENTO_REQUIRED:
    'El número de documento es requerido',
  VALIDATION_MAX_CARACTERES_DNI: 'A excedido la cantidad de caracteres valido',
  VALIDATION_CONSULTSCORING_SEXO_REQUIRED: 'El sexo es requerido',
  CONSULTSCORING_FORM_ADD_LABEL_NUMBER: 'Número de documento',
  CONSULTSCORING_FORM_ADD_PLACEHOLDER_NUMBER:
    'Ingresar el número de documento sin puntos ni guiones',
  SCORING_OPEN_DATAILS: 'Ver detalle',
  SCORING_CLOSE_DATAILS: 'Ocultar detalle',
  TITLE_SCORING: 'Consulta de puntos',
  INTROTEXT_SCORING: 'Ingresá tus datos',
  BUTTON_PUBLIC_SCOIRNG_OTHER: 'Realizar otra consulta',
  INTROTEXT_RESULT_SCORING: 'Los datos obtenidos son los siguientes',
  META_TITLE_SCORING: 'Mi Scoring | Agencia Nacional de Seguridad Vial',
  META_TITLE_RESULT_SCORING: 'Resultado de scoring nacional',
  TITLE_RESULT_SCORING: 'Resultado del scoring nacional',
  WIZARD_SCORING_BUTTON_STEP1_NEXT: 'Consultar',
  SCORING_DOCUMENT_TITLE: 'Puntaje de Licencia Nacional de Conducir',
  SCORING_DOCUMENT_NAME_AND_LASTNAME: 'Nombre y Apellido',
  SCORING_DOCUMENT_TYPE_DOCUMENT: 'Tipo de documento',
  SCORING_DOCUMENT_DOCUMENT_NUMBER: 'Número',
  SCORING_DOCUMENT_POINTS: 'Puntos restantes',
  SCORING_DOCUMENT_STATUS: 'Estado de licencia',
  VALIDATION_CONSULTSCORING_DOCUMENTO_INVALID_REQUIRED: 'Documento invalido',
  SCORING_ERROR_DNI: 'El documento ingresado no posee licencia nacional',
  SCORING_LIST_OTHER_SEARCH: 'Volver a consultar',
  ALT_IMAGE_SEGURIDAD_VIAL: 'Seguridad Vial',
  ALERT_INFO: 'Tené en cuenta',
  ALERT_INFO_MESSAGE:
    'Para licencias emitidas por jurisdicciones con sistema de scoring local se deberá también consultar en dichas jurisdicciones.',
  BUTTON_OPEN_URL_RECUPERAR_PUNTOS:
    'https://www.argentina.gob.ar/seguridadvial/recuperar-puntos'
};
