import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import Button from 'modules/core/components/Buttons/Button';
import ButtonIcon from 'modules/core/components/Buttons/ButtonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '../../Typographies/Typography';
import classes from './ModalTemplate.module.scss';
/**
 * template para el modal
 * @param {*} param0
 * @returns
 */
function ModalTemplate({
  children,
  title = 'default title',
  onClose,
  actionbuttons,
  ...props
}) {
  return (
    <>
      <DialogTitle {...props} className={classes.ContainerTitle}>
        <Stack direction="row" justifyContent="start" alignItems="center">
          <Typography variant="h5" color="primary.contrastText">
            {title}
          </Typography>
          {onClose && (
            <ButtonIcon
              icon="b-cerrar"
              onClick={onClose}
              variant="outlined"
              sx={{
                position: 'absolute',
                right: 8,
                top: 8
              }}
            />
          )}
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>{children}</Typography>
      </DialogContent>
      {actionbuttons && (
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={2}
          >
            {actionbuttons.map((actionButton, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Button key={index} {...actionButton}>
                {actionButton.children}
              </Button>
            ))}
          </Stack>
        </DialogActions>
      )}
    </>
  );
}

ModalTemplate.propTypes = {
  actionbuttons: PropTypes.any,
  button: PropTypes.any,
  children: PropTypes.node,
  icon: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string
};
export default ModalTemplate;
