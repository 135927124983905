import * as React from 'react';
import Alert from '@mui/material/Alert';

import PropTypes from 'prop-types';

/**
 * TODO: Componente
 * @param {*} param0
 * @returns
 */
function AlertTitle({ severity = 'warning', children, ...props }) {
  return (
    <Alert severity={severity} {...props}>
      {children}
    </Alert>
  );
}
AlertTitle.propTypes = {
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  children: PropTypes.string
};
AlertTitle.defaultProps = {
  severity: 'warning',
  children: ''
};

export default AlertTitle;
